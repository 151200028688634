import React from "react";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Collapse from "@mui/material/Collapse";

import AssetRowNew from "../AssetRowNew";

const styles = {
  nestedCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  nestedTable: {
    mb: 2
  }
};

const SCANNER_STATUSES = ["approved", "checked_out", "checked_in"];

export default function RequestRowAssets({ inventoryRequest, assetsOpened, reFetch }) {
  const inventoryRequestItems = inventoryRequest.inventoryItems;
  const withScanner =
    inventoryRequestItems.every(item => item.status !== "reserved") &&
    inventoryRequestItems.find(item => SCANNER_STATUSES.includes(item.status));
  const withReview = inventoryRequestItems.find(item => item.status === "reserved");

  return (
    <TableCell sx={styles.nestedCell} colSpan={9}>
      <Collapse in={assetsOpened} timeout="auto" unmountOnExit>
        <Table sx={styles.nestedTable} aria-label="Inventory requests">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Item ID</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Dimensions</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryRequestItems.map(inventoryItem => (
              <AssetRowNew
                key={`inventoryItem${inventoryItem.id}`}
                inventoryRequest={inventoryRequest}
                withScanner={withScanner}
                withReview={withReview}
                inventoryItem={inventoryItem}
                reFetch={reFetch}
              />
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
}
