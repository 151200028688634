import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import ConfirmDeleteModal from "../../images/ConfirmDeleteModal";
import { useCapture } from "../../../contexts/capture";
import { getAssetItemImage } from "./AssetItemImage";
import AssetItemImage from "./AssetItemImage";
import AssetItemDescription from "./AssetItemDescription";

const styles = {
  root: ({ selected }) => ({
    display: "flex",
    justifyContent: "flex-start",
    border: "1px solid",
    borderColor: "transparent",
    borderBottomColor: "#DDDBDA",
    boxSizing: "border-box",
    background: selected ? "rgba(25, 118, 210, 0.04)" : "white",
    height: 58,
    padding: 0,
    textAlign: "left",
    maxWidth: "100%",
    overflow: "hidden",
    borderRadius: 0,
    textTransform: "none",
  }),
};

export default function AssetItem({
  currentAsset,
  setCurrentAsset,
  onRemove,
  onOpenImageModal,
  asset,
  onSelectImage,
  bulkProps,
}) {
  const { order } = useCapture();
  const [deleteAsset, setDeleteAsset] = useState(false);
  const selected = currentAsset?.id === asset.id;
  const Checkbox = bulkProps.component;

  const handleDelete = async () => {
    try {
      await onRemove({
        variables: {
          id: asset.id,
          projectId: asset.projectId,
        },
      });
    } catch (error) {
      console.error("Failed to delete asset:", error);
    }
  };

  const handleToggleConfirmDeleteModal = () => setDeleteAsset(prev => !prev);

  const handleImageClick = e => {
    e.stopPropagation();
    const image = getAssetItemImage(asset?.images);
    setCurrentAsset(asset);
    onSelectImage(image.id);
    onOpenImageModal(asset.id);
  };

  return (
    <>
      <Box>
        <Button
          id={`assetItem-${asset.id}`}
          fullWidth
          sx={styles.root({ selected })}
          variant="text"
          onClick={() => setCurrentAsset(asset)}
          aria-selected={selected}
          role="option"
        >
          <Checkbox entity={asset} />
          <AssetItemImage ml={2} images={asset.images} onClick={handleImageClick} />
          <AssetItemDescription asset={asset} order={order} />
        </Button>
      </Box>
      {deleteAsset && (
        <ConfirmDeleteModal
          title="Delete Asset"
          descr="Are you sure you want to delete asset?"
          onClose={handleToggleConfirmDeleteModal}
          onDelete={handleDelete}
        />
      )}
    </>
  );
}
