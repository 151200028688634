import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Footer from "./Footer";
import Selected from "./Selected";
import AssetQuantityField from "../../../../views/inventory/requests/AssetQuantityField";

const styles = {
  button: {
    minWidth: 100,
  },
};

export default function Step2({ state, onNext, onBack, onChange }) {
  const [selectedOption, setSelectedOption] = useState(state.multipleBoxes);
  const [boxesCount, setBoxesCount] = useState(state.boxesCount || 1);

  const handleOptionSelect = option => {
    if (option === "yes") {
      onChange({ multipleBoxes: option });
    } else {
      onNext({ boxesCount: 1, multipleBoxes: option });
    }
    setSelectedOption(option);
  };

  const handleNext = () => {
    onNext({ boxesCount, multipleBoxes: selectedOption });
  };

  const isNextDisabled = !selectedOption || (selectedOption === "yes" && boxesCount <= 0);

  return (
    <>
      <DialogContent>
        <Box textAlign="center" mb={3} minHeight={300} display="flex" alignItems="center">
          <Box width="100%">
            <Typography color="textPrimary" mb={4}>
              Are there multiple boxes or units on each pallet?
            </Typography>
            <Box display="flex" justifyContent="center" gap={1}>
              <Button
                variant={selectedOption === "no" ? "contained" : "outlined"}
                onClick={() => handleOptionSelect("no")}
                sx={styles.button}
              >
                No
              </Button>
              <Button
                variant={selectedOption === "yes" ? "contained" : "outlined"}
                onClick={() => handleOptionSelect("yes")}
                sx={styles.button}
              >
                Yes
              </Button>
            </Box>
            {selectedOption === "yes" && (
              <Box mt={4}>
                <AssetQuantityField
                  quantity={boxesCount}
                  onChange={setBoxesCount}
                  size="small"
                  quantityProps={{
                    label: "# of boxes/units per pallet",
                    style: { minWidth: 180 },
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <Footer step={1}>
        <Selected palletCount={state.palletCount} boxesCount={state.boxesCount} />
        <Box display="flex" gap={1}>
          <Button variant="outlined" onClick={onBack} sx={styles.button}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={isNextDisabled}
            sx={styles.button}
          >
            Next
          </Button>
        </Box>
      </Footer>
    </>
  );
}
