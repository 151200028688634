import React from "react";
import { FastField } from "formik";
import FormControl from "@material-ui/core/FormControl";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";

export default function ButtonGroupField({ options = [], ...props }) {
  const theme = useTheme();
  const errorColor = theme.palette.error.main;
  const errorDark = theme.palette.error.dark;

  const styles = {
    buttonGroup: {
      "& .MuiButton-root": {
        color: errorColor,
        borderColor: errorColor,
      },
    },
    button: {
      textTransform: "none",
    },
    buttonError: {
      backgroundColor: errorColor,
      color: "white",
      "&:hover": {
        backgroundColor: errorDark,
      },
    },
    helperText: {
      color: errorColor,
    },
  };

  return (
    <FormControl fullWidth variant="outlined" error={props.error}>
      <FastField {...props}>
        {({ field, form: { touched, errors, setFieldValue } }) => {
          const hasError = touched[field.name] && errors[field.name];

          return (
            <>
              <ButtonGroup
                sx={hasError ? styles.buttonGroup : {}}
                variant="outlined"
                onClick={value => {
                  setFieldValue(field.name, value.target.value);
                }}
              >
                {options.map(([value, label]) => (
                  <Button
                    key={value}
                    value={value}
                    variant={field.value === value ? "contained" : "outlined"}
                    sx={{
                      ...styles.button,
                      ...(hasError && field.value === value && styles.buttonError),
                    }}
                  >
                    {label}
                  </Button>
                ))}
              </ButtonGroup>
              {hasError && (
                <FormHelperText sx={styles.helperText}>{errors[field.name]}</FormHelperText>
              )}
            </>
          );
        }}
      </FastField>
    </FormControl>
  );
}
