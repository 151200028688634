import React from "react";
import orderBy from "lodash/orderBy";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Label from "../../../components/Label";
import { getImage } from "../../../utils/images";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: "ondemand",
};

const styles = {
  image: {
    height: 200,
    backgroundSize: "contain",
    backgroundColor: "#f4f6f8",
  },
  assetIdLabel: {
    position: "absolute",
    top: "5px",
    left: "5px",
  },
};

export default function AssetCardImage({ display, asset, onClickImage }) {
  const handleClickImage = image => onClickImage(asset, image);
  const images = orderBy(asset.images, ["default", "id"]).reverse();

  return images.length >= 1 ? (
    <Box position="relative">
      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <CardMedia
            key={`cardImg-${index}`}
            onClick={() => handleClickImage(image)}
            sx={styles.image}
            image={image.url ? getImage(image.url, "width", 200) : "stub"}
            loading="lazy"
          />
        ))}
      </Slider>
      {display.sc_asset_id_overlay && (
        <Box sx={styles.assetIdLabel}>
          <Label color="contrast">{asset.assetId}</Label>
        </Box>
      )}
      {display.sc_item_id_overlay && (
        <Box sx={styles.assetIdLabel}>
          <Label color="contrast">{asset.itemId}</Label>
        </Box>
      )}
    </Box>
  ) : (
    <Box sx={styles.image} />
  );
}
