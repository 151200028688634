import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import PhotoCamera from "@mui/icons-material/PhotoCamera"; 
import TextField from "@mui/material/TextField";
import Image from "../../../../components/capture/ReceivingWizard/Image";
import Input from "../../../../components/form/Input";

export default function OSAndDDetailsForm({ osd, onUpload, onRemoveImage }) {
  return (
    <>
      <Paper>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography pb={1} fontWeight={500}>
                OS&D Images
              </Typography>
              <Image images={osd?.attachments || []} onUpload={onUpload} onRemove={onRemoveImage} />
            </Grid>
            <Grid item xs={12}>
              <Box my={1} display="flex" justifyContent="center">
                <input
                  type="file"
                  accept="image/*"
                  id="item-button"
                  capture="environment"
                  style={{ display: "none" }}
                  onChange={e => onUpload(e.target.files)}
                />
                <label htmlFor="item-button" style={{ width: 400, margin: 0 }}>
                  <Button
                    size="large"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    component="span"
                  >
                    <PhotoCamera />
                  </Button>
                </label>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper sx={{ mt: 2 }}>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography pb={1} fontWeight={500}>
                OS&D Info
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Item ID"
                value={osd?.shipmentItem?.asset?.itemId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Description"
                value={osd?.shipmentItem?.asset?.description}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Quantity Received"
                value={osd?.shipmentItem?.asset?.availableQuantity}
              />
            </Grid>
            <Grid item xs={12}>
              <Input type="number" name="osdQuantity" label="Impacted Quantity" />
            </Grid>
            <Grid item xs={12}>
              <Input multiline rows={4} name="osdNotes" label="OS&D Notes" />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}
