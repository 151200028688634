import React, {useCallback, useState} from "react";
import {useParams} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import {Minus as MinusIcon, Plus as PlusIcon} from "react-feather";

import useMutation from "../../../hooks/useMutation";
import {ADD_INVENTORY_ITEM_MUTATION} from "../../../constants/graphql/mutations";
import useQueryParams from "../../../hooks/useQuery";
import useNotification from "../../../hooks/notification";
import {useOrders} from "../../../contexts/OrdersProvider";
import {useStyles} from "./AddToCart";

export default function AddToMyOrders({ asset, defaultValues, size = "medium" }) {
  const classes = useStyles({ size });
  const query = useQueryParams();
  const { projectId } = useParams();
  const { refetch } = useOrders();
  const { notifySuccess } = useNotification();
  const [quantity, setQuantity] = useState(0);
  const [error, setError] = useState(null);
  const inventoryRequestId = query.get("inventoryRequestId");
  const [addInventoryItem, { loading }] = useMutation(ADD_INVENTORY_ITEM_MUTATION);
  const notAvailable = asset.availableQuantity <= 0;

  const updateQuantity = useCallback(
    value => {
      if (value >= 0 && value <= asset.availableQuantity) {
        setQuantity(value);
      }
    },
    [asset.availableQuantity, setQuantity]
  );

  const handleMinus = useCallback(() => updateQuantity(quantity - 1), [quantity, updateQuantity]);

  const handlePlus = useCallback(() => updateQuantity(quantity + 1), [quantity, updateQuantity]);

  const handleUpdate = useCallback(
    event => updateQuantity(event.target.value ? parseInt(event.target.value, 10) : 0),
    [quantity, updateQuantity]
  );

  const handleAddInventoryItem = () => {
    if (quantity === 0) return;

    const variables = {
      quantity,
      projectId,
      assetId: asset.id,
      inventoryRequestId,
      ...defaultValues,
    };

    return addInventoryItem({
      variables,
      onSuccess: () => {
        setError(null);
        setQuantity(0);
        refetch();

        notifySuccess("Asset successfully added");
      },
      onFailure: errors => {
        setError(errors.quantity || errors.asset || errors.submit);
      },
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Button
            disabled={notAvailable || loading}
            className={classes.quantityButtonMinus}
            variant="outlined"
            size="small"
            onClick={handleMinus}
          >
            <SvgIcon fontSize="small">
              <MinusIcon />
            </SvgIcon>
          </Button>
          <TextField
            disabled={notAvailable || loading}
            className={classes.quantityInput}
            color="secondary"
            variant="outlined"
            fullWidth
            type="number"
            size="small"
            inputProps={{ min: 0, step: 1 }}
            value={quantity === 0 ? "" : quantity}
            onChange={handleUpdate}
          />
          <Button
            disabled={notAvailable || loading}
            className={classes.quantityButtonPlus}
            variant="outlined"
            size="small"
            onClick={handlePlus}
          >
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          </Button>
        </Box>
        <Button
          disabled={notAvailable}
          size="small"
          variant="outlined"
          onClick={handleAddInventoryItem}
        >
          Add
        </Button>
      </Box>
      {error && (
        <Box mt={1}>
          <FormHelperText htmlFor="barcode" error={!!error}>
            {error}
          </FormHelperText>
        </Box>
      )}
    </>
  );
}
