import React from "react";

import ASSETS_TABLE_PRO from "../../../../constants/tablePro/assets";
import InventoryTransactionMultipleModal from "../../../modals/InventoryTransactionMultipleModal/index";
import useTablePro from "../../extensions/useTablePro";

export default function InventoryTransactionField({ field, setValue, onChange, onClose, entity }) {
  const { fields } = useTablePro({ key: "assetsTableSettings", default: ASSETS_TABLE_PRO });

  const quantityOrderedVisible = fields.find(v => v.id === "quantityOrdered")?.visible;
  const quantityReceivedVisible = fields.find(v => v.id === "quantityReceived")?.visible;

  const onSuccess = ({ asset }) => {
    const newValue = asset[field.id];
    setValue(newValue);
    onChange(entity.id, { [field.id]: newValue }, { skipMutation: true });
    onClose();
  };

  return (
    <InventoryTransactionMultipleModal
      title="Inventory Placement"
      open={true}
      onClose={onClose}
      onSuccess={onSuccess}
      asset={entity}
      code={entity.assetId}
      quantityOrdered={quantityOrderedVisible}
      quantityReceived={quantityReceivedVisible}
    />
  );
}
