import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddNewLocationFields from "./AddNewLocationFields";
import ConfirmQuantitiesModal from "./ConfirmQuantitiesModal";

const validationSchema = Yup.object().shape({
  numberOfLocations: Yup.number().required("Required"),
});

export default function BulkAddNewLocationsModal({ onSubmit, onClose }) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleToggleConfirmation = () => {
    setShowConfirmation(v => !v);
  };

  return (
    <>
      <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Box position="relative">
            <Typography fontSize={20} fontWeight={500} color="text.primary" align="center">
              Add New Location
            </Typography>
            <IconButton
              onClick={onClose}
              size="small"
              sx={{ position: "absolute", right: 0, top: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Formik
          initialValues={{ numberOfLocations: 0, totalUnits: 1 }}
          validationSchema={validationSchema}
        >
          <Form>
            <AddNewLocationFields
              onConfirm={handleToggleConfirmation}
              onSubmit={onSubmit}
              onClose={onClose}
            />
            {showConfirmation && (
              <ConfirmQuantitiesModal
                onSubmit={onSubmit}
                onCloseAddLocation={onClose}
                onClose={() => handleToggleConfirmation}
              />
            )}
          </Form>
        </Formik>
      </Dialog>
    </>
  );
}
