import React from "react";
import Box from "@mui/material/Box";

import { replaceCollection } from "../../../components/TablePro/utils";
import ActionModal from "../../../components/modals/action-modal";
import useMutation from "../../../hooks/useMutation";
import { ASSETS_BULK_UPDATE_MUTATION } from "../../../constants/graphql";
import { useAssetsCollection } from "../../../contexts/assetsCollection";
import { useProjectInventory } from "../../../contexts/projectInventory";
import RackLocationsForm from "./RackLocationsForm";

export default function RackLocationsOrdersModal({ open, toggleModal, entity }) {
  const { setCollection, setLoadingMutation } = useAssetsCollection();
  const [bulkUpdateAssets] = useMutation(ASSETS_BULK_UPDATE_MUTATION);
  const { updateInventoryAsset } = useProjectInventory();

  const handleSave = values => {
    setLoadingMutation(true);
    replaceCollection(setCollection, { [entity.id]: values });
    bulkUpdateAssets({
      variables: { input: { id: entity.id, ...values } },
      onSuccess: ({ assets }) => {
        if (assets) {
          assets.forEach(asset => {
            updateInventoryAsset(asset.id, asset);
          });
        }
        setLoadingMutation(false);
        toggleModal();
      },
    });
  };

  return open ? (
    <ActionModal
      open
      title="Storage Location(s)"
      maxWidth={440}
      onClose={toggleModal}
      disableTransition
    >
      <Box mt={1} />
      <RackLocationsForm
        asset={entity}
        rackLocations={entity.rackLocations || []}
        toggleModal={toggleModal}
        onSubmit={handleSave}
      />
    </ActionModal>
  ) : null;
}
