import useAdmin from "./useAdmin";

export default function useIsOrderDisabled(values, hours) {
  const isAdmin = useAdmin();
  const startDate = new Date(`${values.startDate}T${values.startTime}`);
  const now = new Date();

  if (isAdmin) {
    return undefined;
  }

  return hours && hours !== "0"
    ? now.setHours(now.getHours() + parseInt(hours, 10)) > startDate
    : undefined;
}
