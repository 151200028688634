import React, { useEffect } from "react";
import moment from "moment";
import Box from "@mui/material/Box";

const styles = {
  root: {
    position: "relative",
    width: "100%",
  },
  display: {
    position: "absolute",
    paddingLeft: "14px",
    top: 2,
    left: 0,
    bottom: 2,
    background: "white",
    pointerEvents: "none",
    right: 22,
    display: "flex",
    alignItems: "center",
  },
  input: {},
};

const formatDate = date => moment(date, "yyyy-MM-DDThh:mm").format("MM/DD hh:mm");

function CustomDateTime({ defaultValue, inputRef, ...props }) {
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (props.value && value !== formatDate(props.value)) {
      setValue(formatDate(props.value));
    }
  }, [props.value]);

  const handleChange = event => {
    setValue(formatDate(event.target.value));
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.display}>{value}</Box>
      <input
        sx={styles.input}
        style={{ padding: "10px 14px 10px 0px" }}
        ref={inputRef}
        {...props}
        onChange={handleChange}
        value={value}
      />
    </Box>
  );
}

export default CustomDateTime;
