import React, {useRef} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";

import useAuth from "../../../../hooks/useAuth";
import useNotification from "../../../../hooks/notification";

const styles = {
  input: {
    display: "block",
    opacity: 0,
    height: 0,
  },
};

export default function ShareModal({ onClose, inventoryRequest }) {
  const inputCopyText = useRef();
  const { user } = useAuth();
  const { notifySuccess } = useNotification();
  const shareLink = `${window.location.origin}/inventory_request_pdfs/${inventoryRequest.id}?token=${user.token}`;

  function handleCopy(e) {
    e.preventDefault();
    const link = inputCopyText.current;
    const range = document.createRange();
    range.selectNode(link);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    const successful = document.execCommand("copy");
    if (successful) {
      notifySuccess("Link has been copied!");
    }
  }

  return (
    <Dialog open maxWidth="xs" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box width="40px" />
          <Typography fontSize={22} color="textPrimary">
            Share
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box display="flex" justifyContent="center" mb={2}>
            <Button variant="contained" color="primary" onClick={handleCopy}>
              Copy Link
            </Button>
          </Box>
          <a style={styles.input} href={shareLink} ref={inputCopyText}>
            {shareLink}
          </a>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
