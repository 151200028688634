import React, { useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import useAuth from "../../hooks/useAuth";
import ScanditScanner, { configureScandit } from "./ScanditScanner/ScanditScanner";

const BarCodeInput = ({ onChange, id }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  configureScandit(user.settings.scandid_license);

  const toggleOpen = () => setOpen(v => !v);

  const handleScan = code => {
    setLoading(true);

    onChange(code, {
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: () => {
        setLoading(false);
      },
    });

    setOpen(false);
  };

  if (open) {
    return (
      <ScanditScanner
        barcodeTrigger={user.company.settings.barcode_trigger}
        onDetected={handleScan}
        onClose={toggleOpen}
      />
    );
  }

  return (
    <Box id={id} display="flex" flexDirection="column" height="100%" justifyContent="space-between">
      <Button
        sx={{ height: 40 }}
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
        onClick={toggleOpen}
      >
        <QrCodeScannerIcon sx={{ fontSize: 32 }} />
      </Button>
      {loading && <LinearProgress sx={{ mt: 2 }} />}
    </Box>
  );
};

export default BarCodeInput;
