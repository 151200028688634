import React, { useCallback } from "react";
import { useField } from "formik";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import CheckoutFullTaskField from "./CheckoutFullTaskField";

export const initialLabel = {};

const CheckoutTaskFields = ({ name, isMobile }) => {
  const [field, , helpers] = useField({ name });
  const fields = field.value || [];

  const handleAdd = useCallback(() => {
    helpers.setValue([...field.value, initialLabel]);
  }, [field.value, helpers]);

  return (
    <>
      {fields.map((v, index) => (
        <Box mb={1} key={index} display="flex" alignItems="center">
          <CheckoutFullTaskField
            index={index}
            isMobile={isMobile}
            name={name}
          />
          {fields.length !== 1 && (
            <Box p={0} clone>
              <IconButton
                variant="outlined"
                onClick={() =>
                  helpers.setValue(fields.filter((v, i) => i !== index))
                }
              >
                <DeleteIcon color="action" />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}
      <Box mt={1}>
        <Button variant="outlined" onClick={handleAdd}>
          Add new +
        </Button>
      </Box>
    </>
  );
};

export default CheckoutTaskFields;
