import React from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import useMutation from "../../../../hooks/useMutation";
import { ADD_OVERAGES_SHORTAGES_DAMAGE } from "../../../../constants/graphql/mutations";
import { GET_SHIPMENT_QUERY } from "../../../../constants/graphql/queries";
import Item from "../Items/Item";

export default function SelectImpactedItem({ type, shipment, onClose }) {
  const history = useHistory();
  const [addOveragesShortagesDamage, { loading }] = useMutation(ADD_OVERAGES_SHORTAGES_DAMAGE);

  const handleAdd = shipmentItemId => {
    addOveragesShortagesDamage({
      variables: {
        shipmentItemId,
        osdType: type,
        osdQuantity: 0,
      },
      refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id: shipment.id } }],
    }).then(({ data }) => {
      const ODSId = data.addOveragesShortagesDamage.overagesShortagesDamage.id;
      const pathname = window.location.pathname;
      const params = new URLSearchParams(location.search);
      params.delete("osdType");
      params.delete("openOSDType");
      params.set("osdId", ODSId);
      params.set("newOsd", "true");
      history.push({ pathname, search: params.toString() });
    });
  };

  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Select Impacted Item
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          {loading && <LinearProgress />}
          <Divider />
          {shipment.shipmentItems.map(value => (
            <Item
              key={value.id}
              images={value.asset.images}
              value={value}
              onClick={() => handleAdd(value.id)}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
