import React from "react";
import { useField } from "formik";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Autocomplete from "src/components/form/Autocomplete";
import { getOptions } from "src/utils/form";
import Input from "src/components/form/Input";
import CustomDateTime from "src/components/form/CustomDateTime";

export const initialLabel = {};

const mover = [
  ["Elite", "Elite Solutions"],
  ["Hub", "Hub"],
  ["Employee", "Employee"],
];

const CheckoutTaskField = ({ index, name, isMobile }) => {
  const [checkin, , checkinHelper] = useField({
    name: `${name}.${index}.checkin_status`,
  });
  const [checkout, , checkoutHelper] = useField({
    name: `${name}.${index}.checkout_status`,
  });

  const handleChangeCheckin = () => {
    checkinHelper.setValue(checkin.value === "completed" ? "" : "completed");
  };

  const handleChangeCheckout = () => {
    checkoutHelper.setValue(checkout.value === "completed" ? "" : "completed");
  };

  return isMobile ? (
    <Box my={0.5} display="flex" alignItems="center" flexGrow={1}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center">
            <Autocomplete
              saveCustomOption
              name={`${name}.${index}.checkin_agent`}
              label="Checkin"
              options={getOptions(mover)}
            />
            <Checkbox
              sx={{ padding: 0, paddingLeft: 1 }}
              checked={checkin.value === "completed"}
              onClick={handleChangeCheckin}
              color="primary"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center">
            <Autocomplete
              saveCustomOption
              name={`${name}.${index}.checkout_agent`}
              label="Checkout"
              options={getOptions(mover)}
            />
            <Checkbox
              sx={{ padding: 0, paddingLeft: 1 }}
              checked={checkout.value === "completed"}
              onClick={handleChangeCheckout}
              color="primary"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box my={0.5} display="flex" alignItems="center" flexGrow={1}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Box display="flex" alignItems="center">
            <Autocomplete
              saveCustomOption
              name={`${name}.${index}.checkin_agent`}
              label="Checkin"
              options={getOptions(mover)}
            />
            <Checkbox
              sx={{ padding: 0, paddingLeft: 1 }}
              checked={checkin.value === "completed"}
              onClick={handleChangeCheckin}
              color="primary"
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Input
            type="datetime-local"
            name={`${name}.${index}.checkin_date`}
            label="Checkin Date"
            InputProps={{ inputComponent: CustomDateTime }}
          />
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" alignItems="center">
            <Autocomplete
              saveCustomOption
              name={`${name}.${index}.checkout_agent`}
              label="Checkout"
              options={getOptions(mover)}
            />
            <Checkbox
              sx={{ padding: 0, paddingLeft: 1 }}
              checked={checkout.value === "completed"}
              onClick={handleChangeCheckout}
              color="primary"
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Input
            type="datetime-local"
            name={`${name}.${index}.checkout_date`}
            label="Checkout Date"
            InputProps={{
              inputComponent: CustomDateTime,
            }}
            defaultValue="2017-05-24T22:10"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            type="datetime-local"
            name={`${name}.${index}.requested_by_date`}
            label="Request On"
            InputProps={{
              inputComponent: CustomDateTime,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            name={`${name}.${index}.comments`}
            label="Comments"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckoutTaskField;
