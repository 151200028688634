import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ButtonBase from "@mui/material/ButtonBase";
import AssetImage from "./AssetImage";

export default function Item({ value, images, info, onClick }) {
  const { asset, quantity } = value;
  const { itemId, itemCode, barcode, description } = asset;
  const imageUrl = images[0]?.url;
  const title = [itemId, itemCode, barcode].filter(Boolean).join(" | ");

  return (
    <Box>
      <ButtonBase
        sx={{ width: "100%", justifyContent: "flex-start", textAlign: "left" }}
        onClick={onClick}
      >
        <Box display="flex" my={1.5}>
          <Box mr={3}>
            <AssetImage width={120} height={100} image={imageUrl} />
          </Box>
          <Box>
            <Typography fontWeight={500}>{title}</Typography>
            <Typography mt={1}>{description}</Typography>
            {info ? <Box mt={1}>{info}</Box> : <Typography mt={1}>Quantity: {quantity}</Typography>}
          </Box>
        </Box>
      </ButtonBase>
      <Divider />
    </Box>
  );
}
