import React from "react";
import Button from "@mui/material/Button";

const ButtonComponent = ({ disabled, ...props }) => (
  <Button disabled={disabled} component="span" variant="outlined" {...props}>
    {props.children}
  </Button>
);

export default function UploadFileButton({
  component: Component,
  onUpload,
  disabled,
  name,
  id,
  multiple,
  accept,
  ...props
}) {
  const buttonId = id ? id : `contained-button-file-${name}`;

  const onFileAttach = event => {
    const {
      target: { validity, files },
    } = event;
    if (!validity.valid) return;

    onUpload(multiple ? files : files[0]);
    event.target.value = "";
  };

  const ComponentWrap = Component || ButtonComponent;

  return (
    <ComponentWrap disabled={disabled} {...props}>
      <input
        id={buttonId}
        name={name}
        disabled={disabled}
        onChange={onFileAttach}
        type="file"
        multiple={multiple}
        accept={accept}
        style={{ display: "none" }}
      />
      <label
        style={{ width: "100%", marginBottom: 0, cursor: "pointer", textAlign: "center" }}
        htmlFor={buttonId}
      >
        {props.children}
      </label>
    </ComponentWrap>
  );
}
