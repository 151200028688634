// Cognito_amsAuth_Role
// Cognito_amsUnauth_Role
// Initialize the Amazon Cognito credentials provider
import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink, concat } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import ActionCableProvider from "./ActionCableProvider";
import ActionCableLink from "graphql-ruby-client/dist/subscriptions/ActionCableLink";
import { getMainDefinition } from "apollo-utilities";
import { introspectionQueryResultData } from "../constants/graphql/fragmentTypes";

const csrfToken = () => document.querySelector("meta[name=csrf-token]").getAttribute("content");
const accessToken = () => localStorage.getItem("accessToken");

const currentUrl = window.location.protocol + "//" + window.location.host;

const ActionCable = ActionCableProvider.createConnection(async () => {
  const token = await accessToken();
  return token;
});

const wsPrefix = currentUrl.match(/https/) ? "wss://" : "ws://";
const wsUrl = wsPrefix + currentUrl.split("//")[1];
const wsClient = ActionCable.createConsumer(`${wsUrl}/cable/`);
const wsLink = new ActionCableLink({ cable: wsClient });

const httpLink = createUploadLink({
  uri: `${currentUrl}/graphql`,
  credentials: "same-origin",
});

const authMiddlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: accessToken(),
      "X-CSRF-Token": csrfToken(),
    },
  });
  return forward(operation);
});

const link = ApolloLink.split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    concat(authMiddlewareLink, link),
  ]),
  cache: new InMemoryCache({ fragmentMatcher }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export default ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>;
