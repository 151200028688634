import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import SvgIcon from "@mui/material/SvgIcon";
import TextField from "@mui/material/TextField";
import { Minus as MinusIcon, Plus as PlusIcon } from "react-feather";

export const styles = {
  quantityInput: size => ({
    width: size === "medium" ? "90px" : "70px",
    "& input":
      size === "small"
        ? {
            fontSize: "0.875rem",
            padding: "10px 6px",
            height: "10px",
          }
        : {},
  }),
  quantityButtonMinus: size => ({
    minWidth: size === "medium" ? "40px" : "20px",
    height: size === "medium" ? "40px" : "30px",
    marginRight: "-1px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    "& svg":
      size === "small"
        ? {
            fontSize: "0.875rem",
          }
        : {},
  }),
  quantityButtonPlus: size => ({
    minWidth: size === "medium" ? "40px" : "20px",
    height: size === "medium" ? "40px" : "30px",
    marginLeft: "-1px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "& svg":
      size === "small"
        ? {
            fontSize: "0.875rem",
          }
        : {},
  }),
};

const AssetQuantityField = ({
  disabled,
  quantity,
  onChange,
  size = "medium",
  quantityProps = {},
  displayButtons = true,
}) => {
  const incrementQuqantity = () => {
    const newQuantity = quantity ? parseInt(quantity) + 1 : 1;
    onChange(newQuantity);
  };

  const decrementQuqantity = () => {
    const newQuantity = quantity ? parseInt(quantity) - 1 : -1;
    newQuantity >= 0 && onChange(newQuantity);
  };

  return (
    <FormControl>
      <Box>
        {displayButtons && (
          <Button
            disabled={disabled}
            sx={styles.quantityButtonMinus(size)}
            variant="outlined"
            size="small"
            onClick={decrementQuqantity}
          >
            <SvgIcon fontSize="small">
              <MinusIcon />
            </SvgIcon>
          </Button>
        )}
        <TextField
          disabled={disabled}
          sx={styles.quantityInput(size)}
          variant="outlined"
          fullWidth
          type="number"
          size="small"
          inputProps={{ min: 0, step: 1, onWheel: e => e.target.blur() }}
          onChange={event => onChange(parseInt(event.target.value, 10))}
          value={quantity || ""}
          {...quantityProps}
        />
        {displayButtons && (
          <Button
            disabled={disabled}
            sx={styles.quantityButtonPlus(size)}
            variant="outlined"
            size="small"
            onClick={incrementQuqantity}
          >
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          </Button>
        )}
      </Box>
    </FormControl>
  );
};

export default AssetQuantityField;
