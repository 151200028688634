import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

import {
  ADD_ATTACHMENT_OVERAGES_SHORTAGES_DAMAGE,
  DELETE_OVERAGES_SHORTAGES_DAMAGE,
  REMOVE_ATTACHMENT_OVERAGES_SHORTAGES_DAMAGE,
  UPDATE_OVERAGES_SHORTAGES_DAMAGE,
  UPDATE_ASSET_MUTATION,
} from "../../../../constants/graphql/mutations";
import { GET_SHIPMENT_QUERY } from "../../../../constants/graphql/queries";
import useMutation from "../../../../hooks/useMutation";
import OSAndDDetailsForm from "../../../../components/capture/ReceivingWizard/OSAndD/OSAndDDetailsForm";
import ConfirmDeleteModal from "../../../../components/images/ConfirmDeleteModal";
import useQueryParams from "../../../../hooks/useQueryParams";

const styles = {
  dialogTitle: {
    background: "#f4f6f8", 
    display: "flex", 
    justifyContent: "space-between"
  },
  dialogContent: {
    background: "#f4f6f8"
  },
  stickyBottom: {
    width: "100%",
    position: "sticky",
    bottom: "10px",
    left: 0,
    zIndex: 99,
    padding: "0",
    backgroundColor: "white"
  }
};

const validationSchema = Yup.object().shape({
  osdQuantity: Yup.number()
    .moreThan(0, "Required")
    .required("Required"),
  osdNotes: Yup.string().required("Required"),
});

export default function OSAndDDetails({ osd, osdId, shipment }) {
  const params = useQueryParams();
  const newOsd = params.get("newOsd");
  const [openRemoveOSD, setOpenRemoveOSD] = React.useState(false);
  const history = useHistory();
  const [deleteOveragesShortagesDamage, { loading: removing }] = useMutation(
    DELETE_OVERAGES_SHORTAGES_DAMAGE
  );
  const [updateOveragesShortagesDamage, { loading: saving }] = useMutation(
    UPDATE_OVERAGES_SHORTAGES_DAMAGE
  );
  const [addAttachmentOveragesShortagesDamage, { loading: adding }] = useMutation(
    ADD_ATTACHMENT_OVERAGES_SHORTAGES_DAMAGE
  );
  const [removeAttachmentOveragesShortagesDamage, { loading: removingImage }] = useMutation(
    REMOVE_ATTACHMENT_OVERAGES_SHORTAGES_DAMAGE
  );
  const [updateAsset] = useMutation(UPDATE_ASSET_MUTATION);
  const refetchQueries = [{ query: GET_SHIPMENT_QUERY, variables: { id: shipment.id } }];

  const handleUpload = files => {
    const file = files[0];
    addAttachmentOveragesShortagesDamage({
      variables: { overagesShortagesDamageId: osdId, file },
      refetchQueries,
    });
  };

  const handleClose = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("osdId");
    params.delete("newOsd");
    history.push({ pathname, search: params.toString() });
  };

  const toggleRemoveOSD = () => {
    setOpenRemoveOSD(v => !v);
  };

  const handleRemove = () => {
    deleteOveragesShortagesDamage({
      variables: {
        id: osdId,
      },
      refetchQueries,
    }).then(() => {
      handleClose();
    });
  };

  const handleRemoveImage = data => {
    removeAttachmentOveragesShortagesDamage({
      ...data,
      variables: {
        overagesShortagesDamageId: osdId,
        ...data.variables,
      },
      refetchQueries,
    });
  };

  const handleSave = async (input) => {
    try {
      await updateOveragesShortagesDamage({
        variables: {
          id: osdId,
          osdType: osd.osdType,
          osdQuantity: input.osdQuantity,
          osdNotes: input.osdNotes,
        },
        refetchQueries,
      });

      await updateAsset({
        variables: {
          id: osd.shipmentItem.asset.id,
          input: {
            osdNotes: input.osdNotes
          }
        },
        refetchQueries,
      });

      handleClose();
    } catch (error) {
      console.error('Error saving OSD details:', error);
    }
  };

  const handleCloseModal = () => {
    if (newOsd) {
      toggleRemoveOSD();
    } else {
      handleClose();
    }
  };

  return (
    <Dialog open fullWidth maxWidth="xs" onClose={handleCloseModal}>
      <DialogTitle sx={styles.dialogTitle}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          OS&D Details
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        {(adding || removingImage) && <LinearProgress />}
        <Formik
          key={osd?.id}
          initialValues={{
            osdQuantity: osd?.osdQuantity,
            osdNotes: osd?.osdNotes || "",
          }}
          onSubmit={handleSave}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box mb={4}>
                <OSAndDDetailsForm
                  osd={osd}
                  onUpload={handleUpload}
                  onRemoveImage={handleRemoveImage}
                />
              </Box>
              <Box sx={styles.stickyBottom}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      disabled={removing}
                      startIcon={removing && <CircularProgress size={22} />}
                      onClick={toggleRemoveOSD}
                    >
                      Delete
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      startIcon={isSubmitting && <CircularProgress size={22} />}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
      {openRemoveOSD && (
        <ConfirmDeleteModal
          title="Delete OS&D"
          descr="Are you sure want to delete the selected OS&D?"
          onClose={toggleRemoveOSD}
          onDelete={handleRemove}
        />
      )}
    </Dialog>
  );
}
