import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import ProjectFilter from "../../../layouts/ShoppingLayout/ProjectFilter";
import useAuth from "../../../hooks/useAuth";
import AddInventoryItems from "./AddInventoryItems";
import { CLIENTS_QUERY } from "../../../constants/graphql";

export default function InventoryItemModal({ onClose }) {
  const { user } = useAuth();
  const { settings } = user.company;
  /*
  const isVA = user.company.name === "va";
  const { data: { clients = [] } = {} } = useQuery(CLIENTS_QUERY, {
    skip: !isVA,
  });
  const launchData = clients.flatMap(v => v.projects).find(v => v.name === "Abbott Ave Whse");
  const launchDataId = launchData ? launchData.id : null;
  const [projectId, setProjectId] = useState(launchDataId);
  */

  const [projectId, setProjectId] = useState();
  const onSelectProject = id => {
    setProjectId(id);
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="dialog-title"
      disableEnforceFocus
    >
      <DialogTitle id="dialog-title">
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="h2" color="textPrimary" component="span">
              Add More Items
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Typography variant="body1" color="textPrimary">
                  Choose a {settings.filter_field2}
                </Typography>
                <Typography></Typography>
              </Breadcrumbs>
              <Box width="300px">
                <ProjectFilter
                  size="small"
                  projectId={projectId}
                  onSelectProject={onSelectProject}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <AddInventoryItems projectId={projectId} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box display="flex" p={1.5}>
          <Button variant="outlined" onClick={onClose} color="primary">
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
