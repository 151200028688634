import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";

const styles = {
  button: {
    position: "absolute",
    height: 40,
    top: "100%",
    right: 0, 
    zIndex: 2,
    borderRadius: 0,
    color: theme => theme.palette.text.secondary,
    bgcolor: theme => theme.palette.background.default,
    justifyContent: "flex-start",
    textTransform: "none",
    "&:hover": {
      opacity: 1,
      bgcolor: "#fff",
    },
  },
};

const AdvancedSearchButton = ({ onClick, className }) => {
  return (
    <Button
      variant="outlined"
      className={className}
      sx={{ ...styles.button }}
      startIcon={<FilterListIcon />}
      onClick={onClick}
    >
      Advanced Search
    </Button>
  );
};

AdvancedSearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default AdvancedSearchButton;
