import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function SelectFixedLocation({ onClose, onSubmit, allLocations = [] }) {
  const [selectedLocation, setSelectedLocation] = useState([]);

  function handleSubmit() {
    onSubmit(selectedLocation);
  }

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography fontSize={24} fontWeight={500} align="center" color="text.primary">
          Select Fixed Location
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          multiple 
          size="small"
          sx={{ mt: 2 }}
          options={allLocations}
          value={selectedLocation}
          onChange={(e, value) => setSelectedLocation(value)}
          getOptionLabel={option => option.locationName}
          renderInput={params => (
            <TextField {...params} label="Storage Location" variant="outlined" />
          )}
        />
      </DialogContent>
      <DialogActions sx={{ px: 2.5, py: 2 }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" disabled={!selectedLocation}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
