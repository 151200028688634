import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  dialogTitle: {
    textAlign: "center",
    p: 3,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  content: {
    textAlign: "center",
    px: 4,
  },
  actions: {
    justifyContent: "flex-end",
    px: 2.5,
    pb: 2.5,
  },
  button: {
    minWidth: 100,
  },
};

export default function MobileAttentionModal({ open, onClose, onNext }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={styles.dialogTitle}>
        <Typography fontSize={24} fontWeight={500} color="text.primary">
          Attention
        </Typography>
        <IconButton aria-label="close" onClick={onClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={styles.content}>
          You've selected a mobile location. Each mobile location must be linked to a fixed
          location. Please select the corresponding fixed location.
        </Typography>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="contained" color="primary" onClick={onNext} sx={styles.button}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
}
