import React from "react";
import PopupState, {bindMenu} from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";

const TABS = [
  {
    value: "floor",
    label: "Floor Plan View",
  },
  {
    value: "table",
    label: "Table View",
  },
];

export default function Views({ view, changeView }) {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <React.Fragment>
          <Menu {...bindMenu(popupState)}>
            {TABS.map(option => (
              <MenuItem
                key={option.value}
                data-id="select-item"
                selected={view === option.value}
                onClick={() => {
                  popupState.close();
                  changeView(option.value);
                }}
              >
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
