import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useQuery } from "@apollo/react-hooks";
import { Delete as RemoveIcon } from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";

import { serverStrftime } from "../../../utils/dates";
import { INVENTORY_REQUEST_ATTACHMENT_MUTATION } from "../../../constants/graphql/queries";
import useMutation from "../../../hooks/useMutation";
import {
  ADD_INVENTORY_REQUEST_ATTACHMENT_MUTATION,
  REMOVE_INVENTORY_REQUEST_ATTACHMENT_MUTATION,
} from "../../../constants/graphql/mutations";

export default function Attachments({ inventoryRequestId }) {
  const { data: { inventoryRequestAttachments = [] } = {}, refetch, loading } = useQuery(
    INVENTORY_REQUEST_ATTACHMENT_MUTATION,
    {
      variables: { inventoryRequestId },
    }
  );
  const [inventoryRequestAddAttachment, { loading: saving }] = useMutation(
    ADD_INVENTORY_REQUEST_ATTACHMENT_MUTATION
  );
  const [inventoryRequestRemoveAttachment, { loading: removing }] = useMutation(
    REMOVE_INVENTORY_REQUEST_ATTACHMENT_MUTATION
  );

  const onDrop = useCallback(
    acceptedFiles => {
      Promise.all(
        acceptedFiles.map(file =>
          inventoryRequestAddAttachment({
            variables: { inventoryRequestId, file },
          })
        )
      ).then(() => refetch());
    },
    [inventoryRequestId, inventoryRequestAddAttachment, refetch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleRemove = useCallback(
    id => {
      inventoryRequestRemoveAttachment({
        variables: { inventoryRequestId, id },
      }).then(() => refetch());
    },
    [inventoryRequestRemoveAttachment, inventoryRequestId, refetch]
  );

  return (
    <Box>
      <Box height={16} pt={0.75}>
        {(loading || saving || removing) && <LinearProgress />}
      </Box>
      {inventoryRequestAttachments.length > 0 && (
        <Box mb={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryRequestAttachments.map((attachment, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Link target="_blank" href={attachment.url}>
                        {attachment.fileName || "No name"}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{serverStrftime(attachment.createdAt)}</TableCell>
                    <TableCell align="right">
                      <IconButton size="small" onClick={() => handleRemove(attachment.id)}>
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <div {...getRootProps()}>
        <Button fullWidth variant="outlined" style={{ textTransform: "none" }}>
          <Box p={2} display="flex" justifyContent="center">
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>Add Attachments</Typography>
            )}
          </Box>
        </Button>
      </div>
    </Box>
  );
}
