import React from "react";
import { useFormikContext } from "formik";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AssetQuantityField from "src/views/inventory/requests/AssetQuantityField";

export default function AddNewLocationFields({ onConfirm, onSubmit, onClose }) {
  const { values, setFieldValue } = useFormikContext();
  const isSubmitDisabled = values.numberOfLocations < 1 || !values.totalUnits;

  const handleNotApplicable = () => {
    onSubmit({ ...values, totalUnits: values.numberOfLocations });
    onClose();
  };

  return (
    <>
      <DialogContent>
        <Typography gutterBottom align="center">
          How many new locations would you like to add?
        </Typography>
        <Box sx={{ mt: 2 }} display="flex" justifyContent="center">
          <AssetQuantityField
            quantity={values.numberOfLocations}
            onChange={value => setFieldValue("numberOfLocations", value)}
            quantityProps={{
              label: "# of New Locations",
              style: { minWidth: 160 },
              autoFocus: true,
              InputLabelProps: {
                shrink: true,
              },
            }}
          />
        </Box>
        {values.numberOfLocations > 0 && (
          <>
            <Typography sx={{ mt: 4 }} gutterBottom align="center">
              How many total units did you receive of this item?
            </Typography>
            <Box sx={{ mt: 2 }} display="flex" justifyContent="center">
              <AssetQuantityField
                quantity={values.totalUnits}
                onChange={value => setFieldValue("totalUnits", value)}
                quantityProps={{
                  label: "Total Units Received",
                  style: { minWidth: 160 },
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }} display="flex" justifyContent="center">
              <Button variant="text" color="primary" onClick={handleNotApplicable}>
                Not applicable
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitDisabled} variant="contained" color="primary" onClick={onConfirm}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
}
