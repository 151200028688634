import React, { useState, useCallback, useMemo, useRef } from "react";
import { useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { getInitialLocation } from "./utils";
import useTempLocation from "./hooks/useTempLocation";
import useTempLocationEnabled from "./hooks/useTempLocationEnabled";
import BulkActionsButton from "./BulkActionsButton";
import LocationRow from "./LocationRow";

export default function RackLocations({
  multiSelect,
  locations,
  warehouseLocations,
  loadingWarehouseLocations,
  openPalletBreakdown,
}) {
  const valuesRef = useRef();
  const isTempLocationEnabled = useTempLocationEnabled();
  const tempLocation = useTempLocation(warehouseLocations);
  const [openRemoveLocation, setOpenRemoveLocation] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const { values, setFieldValue } = useFormikContext();
  valuesRef.current = values;

  const handleAddLocation = useCallback(() => {
    const newLocations = [
      ...values.locations,
      getInitialLocation(isTempLocationEnabled, tempLocation),
    ];
    setFieldValue("locations", newLocations);
  }, [values.locations, isTempLocationEnabled, tempLocation, setFieldValue]);

  const handleCheckboxChange = useCallback(index => {
    setSelectedLocations(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      }
      return [...prev, index];
    });
  }, []);

  const handleDeleteLocations = useCallback(
    indexes => {
      const locations = valuesRef.current.locations;
      const subQty = indexes.reduce((acc, index) => {
        return acc + (locations[index].sub_qty || 0);
      }, 0);
      setFieldValue("deletedQuantity", valuesRef.current.deletedQuantity + subQty);
      const newLocations = locations.filter((_, index) => !indexes.includes(index));
      setFieldValue("locations", newLocations);
    },
    [setFieldValue]
  );

  const handleUpdateLocations = useCallback(
    (selectedLocations, newLocation) => {
      const newLocations = values.locations.map((location, index) => {
        if (selectedLocations.includes(index)) {
          return {
            ...location,
            ...newLocation,
          };
        }
        return location;
      });
      setFieldValue("locations", newLocations);
    },
    [values.locations, setFieldValue]
  );

  const clearSelectedLocations = useCallback(() => {
    setSelectedLocations([]);
  }, []);

  const memoizedBulkActions = useMemo(
    () =>
      multiSelect && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <BulkActionsButton
            selectedLocations={selectedLocations}
            onDeleteLocations={handleDeleteLocations}
            onUpdateLocations={handleUpdateLocations}
            clearSelectedLocations={clearSelectedLocations}
            warehouseLocations={warehouseLocations}
            openPalletBreakdown={openPalletBreakdown}
            isTempLocationEnabled={isTempLocationEnabled}
            tempLocation={tempLocation}
          />
        </Grid>
      ),
    [
      multiSelect,
      selectedLocations,
      handleDeleteLocations,
      handleUpdateLocations,
      clearSelectedLocations,
      warehouseLocations,
      openPalletBreakdown,
      isTempLocationEnabled,
      tempLocation,
    ]
  );

  return (
    <Grid container spacing={1}>
      {memoizedBulkActions}
      {locations.length > 0 &&
        locations.map((location, index) => (
          <LocationRow
            key={`location-row-${location.rack_location}-${index}`}
            location={location}
            index={index}
            number={index + 1}
            multiSelect={multiSelect}
            selectedLocations={selectedLocations}
            setFieldValue={setFieldValue}
            onCheckboxChange={handleCheckboxChange}
            openRemoveLocation={openRemoveLocation}
            setOpenRemoveLocation={setOpenRemoveLocation}
            onDeleteLocations={handleDeleteLocations}
            warehouseLocations={warehouseLocations}
            loadingWarehouseLocations={loadingWarehouseLocations}
          />
        ))}
      <Grid item xs={12}>
        <Button variant="outlined" color="primary" type="button" onClick={handleAddLocation}>
          Add Another Location
        </Button>
      </Grid>
    </Grid>
  );
}
