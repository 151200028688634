import React, { useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useQuery } from "@apollo/react-hooks";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { ClientsProvider } from "src/contexts/clients";
import { INVENTORY_REQUEST_QUERY } from "src/constants/graphql/queries";
import ManualLocationItem from "src/views/inventory/requests/orders/ManualLocationItem";
import ManualLocationKit from "./manual-location-kits/ManualLocationKit";
import useMutation from "../../../../hooks/useMutation";
import { UPDATE_INVENTORY_REQUEST_MUTATION } from "src/constants/graphql/mutations";
import Status from "../../header/Status";
import Header from "../../header/Header";
import { isVaSubdomain } from "src/utils/inventoryRequests";

export default function ManualLocationCheckout({ inventoryRequestId, onClose }) {
  const { loading, data: { inventoryRequest = {} } = {} } = useQuery(INVENTORY_REQUEST_QUERY, {
    variables: { id: inventoryRequestId },
    fetchPolicy: "network-only",
  });
  const [saveInventoryRequest] = useMutation(UPDATE_INVENTORY_REQUEST_MUTATION);
  const [openKitModal, setOpenKitModal] = useState(false);
  const [kitRackLocations, setKitRackLocations] = useState([]);
  const [selectedKitAssetId, setSelectedKitAssetId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const isVa = isVaSubdomain();

  const inventoryItems = inventoryRequest.inventoryItems || [];

  const sortedInventoryItems = inventoryItems.sort((a, b) => {
    const aIsKit = a.asset?.type === "Kit";
    const bIsKit = b.asset?.type === "Kit";

    if (aIsKit && !bIsKit) return 1;
    if (!aIsKit && bIsKit) return -1;
    if (aIsKit && bIsKit) return 0;

    const aLocation = a.rackLocations[0]?.rack_location[0] || "";
    const bLocation = b.rackLocations[0]?.rack_location[0] || "";
    return aLocation.localeCompare(bLocation);
  });

  const handlePickKit = (kitAssetId, rackLocations, item) => {
    setSelectedKitAssetId(kitAssetId);
    setKitRackLocations(rackLocations);
    setSelectedItem(item);
    setOpenKitModal(true);
  };

  const changeStatus = useCallback(
    key => checked => {
      const input = {
        vaStatus: {
          ...inventoryRequest?.vaStatus,
          [key]: checked,
        },
      };
      saveInventoryRequest({
        variables: { id: inventoryRequest.id, input },
      });
    },
    [inventoryRequest]
  );

  return (
    <>
      <Dialog open fullWidth maxWidth="md" onClose={onClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box width={40} />
            <Typography variant="h2" color="textPrimary">
              {isVa ? "Task Order Picking" : "Order Picking"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ minHeight: 200 }}>
          <DialogContentText>
            {!loading && (
              <Box mb={2}>
                <Header item={inventoryRequest} />
              </Box>
            )}
            <Box mb={3}>
              <Status
                loading={loading}
                inventoryRequest={inventoryRequest}
                changeStatus={changeStatus}
              />
            </Box>
            {loading ? (
              <Box width="100%" display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : sortedInventoryItems.length === 0 ? (
              <Box pt={7} display="flex" justifyContent="center">
                No items to pick
              </Box>
            ) : (
              <ClientsProvider>
                <Table>
                  <TableHead>
                    <TableCell align="center">Image</TableCell>
                    <TableCell>Item ID</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity Requested</TableCell>
                    <TableCell>Pick Location(s)</TableCell>
                    <TableCell align="right" width={160}>
                      Pick Status
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {sortedInventoryItems.map(({ asset, rackLocations, ...item }, index) => (
                      <ManualLocationItem
                        key={index}
                        asset={asset}
                        item={item}
                        rackLocations={rackLocations}
                        inventoryRequest={inventoryRequest}
                        onPickKit={(kitAssetId, rackLocations) =>
                          handlePickKit(kitAssetId, rackLocations, item)
                        }
                      />
                    ))}
                  </TableBody>
                </Table>
              </ClientsProvider>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {openKitModal && (
        <ManualLocationKit
          inventoryRequestId={inventoryRequestId}
          assetId={selectedKitAssetId}
          rackLocations={kitRackLocations}
          item={selectedItem}
          onClose={() => setOpenKitModal(false)}
        />
      )}
    </>
  );
}
