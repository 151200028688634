import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  DialogActions,
  Box
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ArrowBack = () => "<";

const styles = {
  dialog: {
    "& .MuiDialog-paper": {
      "@media (min-width: 600px)": {
        maxWidth: props => props.maxWidth
      }
    }
  },
  titleRoot: {
    p: 0,
    bgcolor: "white",
    boxShadow: "0 1px 2px 0 rgba(27, 37, 64, 0.12)",
    zIndex: 2
  },
  contentRoot: {
    overflowY: "auto",
    p: 0,
    display: "flex",
    flexDirection: "column"
  },
  scrollbar: {
    p: "12px",
    "@media (min-width: 600px)": {
      p: "16px"
    }
  }
};

const FlexModal = ({
  maxWidth,
  onBack,
  onClose,
  showClose,
  title,
  actions,
  id = "modal",
  bodyClassName,
  disableTransition,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      {...rest}
      maxWidth={false}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      sx={styles.dialog}
      fullScreen={fullScreen}
      classes={{
        paperFullScreen: "paperFullScreen",
        paperScrollPaper: "w-100",
        scrollPaper: "p-0"
      }}
      aria-labelledby={id}
      transitionDuration={disableTransition ? 0 : undefined}
    >
      <DialogTitle sx={styles.titleRoot} id={id}>
        <Grid container alignItems="center" wrap="nowrap">
          <Box sx={{ minHeight: "64px" }} />
          <Grid item sx={{ p: "8px" }}>
            {onBack && (
              <IconButton onClick={() => onBack()}>
                <ArrowBack titleAccess="Back" />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography color="text.secondary" variant="h2" className={onBack ? "pl-3" : ""}>
              {title}
            </Typography>
          </Grid>
          <Grid item sx={{ p: "8px" }}>
            {showClose && onClose && (
              <IconButton onClick={() => onClose()}>
                <CloseIcon titleAccess="Close" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={styles.contentRoot}>
        <Box className={bodyClassName} sx={styles.scrollbar}>
          {children}
        </Box>
      </DialogContent>
      {actions && (
        <DialogActions
          sx={{
            p: "12px",
            bgcolor: "white",
            borderTop: "1px solid rgba(220, 224, 230, 0.32)"
          }}
          className="m-0 p-sm-3"
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

FlexModal.defaultProps = {
  open: true,
  showClose: true,
  disableTransition: false
};

export default FlexModal;
