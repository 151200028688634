import React, {useState} from "react";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import AdvancedSearchButton from "src/components/AdvancedSearch/AdvancedSearchButton";
import AdvancedSearch from "src/components/AdvancedSearch";

const styles = {
  root: {
    position: "relative"
  },
  input: {
    minWidth: 400,
    padding: theme => `${theme.spacing(0.5)} ${theme.spacing(2)}`
  },
  advancedSearchButton: {
    mt: 0.5
  }
};

export default function TableSearch({ placeholder, onChange, value: initialValue, disabled }) {
  const [value, setValue] = useState(initialValue);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [showAdvancedSearchButton, setShowAdvancedSearchButton] = useState(false);

  const handleInputKeyup = event => {
    event.persist();

    if (event.keyCode === 13 && event.target.value) {
      setValue(event.target.value);
    }
  };

  const handleInputChange = event => {
    event.persist();
    setValue(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    onChange(value);
  };

  const handleAdvancedSearchSubmit = value => {
    setValue(value);
    onChange(value);
    setShowAdvancedSearch(false);
  };

  const handleClear = () => {
    setValue("");
    onChange("");
  };

  return (
    <Box sx={styles.root}>
      <Paper>
        <form onSubmit={handleSubmit}>
          <Input
            sx={styles.input}
            name="tableSearch"
            inputProps={{
              "data-skip-navigation-effect": true,
            }}
            disableUnderline
            fullWidth
            onChange={handleInputChange}
            onKeyUp={handleInputKeyup}
            placeholder={placeholder || "Enter a keyword"}
            value={value || ""}
            disabled={disabled}
            endAdornment={
              <InputAdornment>
                {value && (
                  <IconButton onClick={handleClear}>
                    <CloseIcon />
                  </IconButton>
                )}
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            onFocus={() => {
              // setShowAdvancedSearchButton(true);
            }}
            // a small delay before the button is hidden, ensuring that the click event has time to process first
            onBlur={() => setTimeout(() => setShowAdvancedSearchButton(false), 200)}
          />
        </form>
        {showAdvancedSearchButton && (
          <AdvancedSearchButton
            sx={styles.advancedSearchButton}
            onClick={() => {
              setShowAdvancedSearch(true);
            }}
          />
        )}
        {showAdvancedSearch && (
          <AdvancedSearch
            initialValue={value}
            onSearch={handleAdvancedSearchSubmit}
            onCancel={() => setShowAdvancedSearch(false)}
          />
        )}
      </Paper>
    </Box>
  );
}
