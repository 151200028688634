import { useHistory, useParams } from "react-router-dom";
import useMutation from "src/hooks/useMutation";
import { CREATE_ASSET_MUTATION } from "src/constants/graphql";
import { ADD_SHIPMENT_ITEMS_MUTATION } from "src/constants/graphql/mutations";
import { GET_SHIPMENT_QUERY } from "src/constants/graphql/queries";

export default function useShipmentActions(shipmentId) {
  const history = useHistory();
  const { projectId } = useParams();
  const [createAsset, { loading: createAssetLoading }] = useMutation(CREATE_ASSET_MUTATION);
  const [addShipmentItem, { loading: addShipmentItemLoading }] = useMutation(
    ADD_SHIPMENT_ITEMS_MUTATION,
    {
      refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id: shipmentId } }],
    }
  );

  const handleCreateShipment = async (input = {}) => {
    const data = await createAsset({
      variables: { projectId, input },
    });

    const item = await addShipmentItem({
      variables: {
        assetId: data.data.createAsset.asset.id,
        shipmentId,
        quantity: 0,
      },
    });

    return { ...data, shipmentItem: item.data.addShipmentItems.shipmentItem };
  };

  const createShipment = input => {
    handleCreateShipment(input).then(data => {
      const pathname = window.location.pathname;
      const params = new URLSearchParams(location.search);
      params.set("assetId", data.data.createAsset.asset.id);
      params.set("shipmentItemId", data.shipmentItem.id);
      params.set("addShipmentItem", "true");
      params.delete("itemLookup");
      history.push({ pathname, search: params.toString() });
    });
  };

  return { createShipment, createAssetLoading, addShipmentItemLoading };
}
