import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

const labels = {
  Kgs: "Kilograms (kgs)",
  Lbs: "Pounds (lbs)",
};

export default function ConfirmUnitWeightModal({ onClose, onConfirm, weightUnit }) {
  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth disablePortal>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width={34} />
          <Typography fontSize={20} fontWeight={500} color="text.primary" align="center">
            Confirm Weight Units Selection
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography>
          You selected <b>{labels[weightUnit]}</b> as the weight unit for this shipment. Are you
          sure this is correct?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onConfirm} type="submit" variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
