import React from "react";
import moment from "moment";
import orderBy from "lodash/orderBy";
import {useQuery} from "@apollo/react-hooks";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";

import {ASSET_AUDIT_LOGS_QUERY} from "../../constants/graphql/queries";

const DATE_FORMAT = "MM/DD/yyyy hh:mm A";
const serverFormat = "yyyy-MM-DDThh:mm:ss Z";

export default function ReservedInventoryList({ assetId }) {
  const { data: { assetAuditLogs = [] } = {} } = useQuery(ASSET_AUDIT_LOGS_QUERY, {
    variables: { assetId, action: "reserved_inventory" },
    fetchPolicy: "network-only",
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="center">Reserved For</TableCell>
            <TableCell align="center">Order Number</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center">Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(assetAuditLogs, v => v.details.work_order_number, "desc").map((row, index) => (
            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {moment(row.performedAt, serverFormat).format(DATE_FORMAT)}
              </TableCell>
              <TableCell align="center">{row.details.reserved_for}</TableCell>
              <TableCell align="center">{row.details.work_order_number}</TableCell>
              <TableCell align="center">{row.details.quantity}</TableCell>
              <TableCell align="center">{row.details.notes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
