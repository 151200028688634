import React, {useRef, useState} from "react";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import useAuth from "../../../../hooks/useAuth";

const styles = {
  popover: {
    width: 200,
  },
};

const AccountMenu = () => {
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <IconButton id="homeIcon" ref={ref} onClick={handleOpen}>
        <AccountCircleOutlinedIcon />
      </IconButton>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ sx: styles.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {user.name && (
          <MenuItem disabled={true}>
            <Typography variant="h6" color="inherit">
              {user.name}
            </Typography>
          </MenuItem>
        )}
        <MenuItem id="homeIcon-profile" component={RouterLink} to="/profile">
          Profile
        </MenuItem>
        <MenuItem id="homeIcon-logout" onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
