import React from "react";
import { Link as RouterLink } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import snakeCase from "lodash/snakeCase";
import Link from "@material-ui/core/Link";

import { COLORS_OPTIONS } from "../colors";
import BooleanSelectField from "../../components/TablePro/fields/BooleanSelectField";
import DimensionsField from "../../components/TablePro/fields/DimensionsField";
import ArrayField from "../../components/TablePro/fields/ArrayField";
import AttachmentsField from "../../components/TablePro/fields/assets/AttachmentsField";
import Attachments from "../../components/TablePro/fields/assets/Attachments";
import ImageButtonComponent from "../../components/TablePro/components/assets/ImageButton";
import InventoryTransactionField from "../../components/TablePro/fields/assets/InventoryTransactionField";
import ReceivingReportNumberInput from "../../components/TablePro/fields/assets/ReceivingReportNumberInput";
import { warrantyOptions } from "../../views/capture/FormFields";
import labels, {
  benchFloorHoodWallBscOptions,
  exhHeaderOptions,
  localGasTanksOptions,
  nemaConfigOptions,
  phaseOptions,
  plugConnectionOptions,
  solveWasteCollOptions,
  voltsOptions,
} from "../displayFields";
import AutocompleteField from "../../components/TablePro/fields/AutocompleteField";
import manufacturers from "../../constants/manufacturers";
import EditAssetModal from "../../components/TablePro/components/assets/EditAssetModal";
import AssetLinkComponent from "../../components/TablePro/components/assets/AssetLink";
import DropdownView from "../../components/TablePro/components/assets/DropdownView";
import RackLocationsComponent from "../../views/capture/RackLocations/scrubber/RackLocationsComponent";
import RackLocationsInput from "../../views/capture/RackLocations/scrubber/RackLocationsInput";
import ImageInput from "../../components/TablePro/fields/ImageInput";
import ImageComponent from "../../components/TablePro/fields/ImageComponent";
import MultipleDestinationRoomNumberComponent from "../../components/TablePro/fields/assets/MultipleDestinationRoomNumberComponent";
import MultipleDestinationRoomNumberInput from "../../components/TablePro/fields/assets/MultipleDestinationRoomNumberInput";
import dispositions from "../../constants/dispositions";
import CategoryField from "../../components/TablePro/fields/CategoryField";
import SubcategoryField from "../../components/TablePro/fields/SubcategoryField";
import SelectField from "../../components/TablePro/fields/SelectField";
import DateTime from "../../components/TablePro/fields/DateTime";
import { palletTypeOptions } from "../../constants/receiving-wizard";
import ReceivingReportNumberComponent from "../../components/TablePro/components/assets/ReceivingReportNumberComponent";

const label = fieldId => labels[snakeCase(fieldId)];

export const multipleDestinationRoomNumber = {
  attrName: "rackLocations",
  input: MultipleDestinationRoomNumberInput,
  component: MultipleDestinationRoomNumberComponent,
};

export const AssetLink = ({ entity, id, value }) => (
  <Link
    className="unfocus"
    id={`assetLink-${id}-${entity.id}`}
    to={`/assets/${entity.id}`}
    component={RouterLink}
  >
    {value || ""}
  </Link>
);

const Dimension = ({ value }) => {
  if (!value) return null;
  return (
    <span>{value.map(field => [field.width, field.depth, field.height].join("x")).join(", ")}</span>
  );
};

const ColorsField = props => <AutocompleteField {...props} options={COLORS_OPTIONS} />;

const ArrayComponent = ({ value }) => (isEmpty(value) ? "" : value.join(", "));

export default {
  size: "medium",
  limit: 45,
  order: {},
  version: "v10",
  fields: [
    {
      id: "BULK_CHECKBOX",
      label: "BULK_CHECKBOX",
      alwaysVisible: true,
      system: true,
      visible: true,
      isFrozen: true,
      unEditable: true,
      disableOrder: true,
      width: 100,
    },
    {
      id: "photoUpload",
      attrName: "photo",
      label: "Image Upload",
      width: 90,
      alwaysVisible: true,
      visible: true,
      disableOrder: true,
      disableFillDown: true,
      unEditable: true,
      component: ImageButtonComponent,
    },
    {
      id: "client.name",
      label: "Client",
      visible: true,
    },
    {
      id: "project.name",
      label: "Project",
      visible: true,
    },
    {
      id: "itemId",
      label: label("itemId"),
      component: AssetLinkComponent,
      input: EditAssetModal,
      visible: true,
    },
    {
      id: "photoCarousel",
      label: "Image Viewer",
      alwaysVisible: true,
      attrName: "photo",
      visible: true,
      disableOrder: true,
      component: ImageComponent,
      input: ImageInput,
    },
    {
      id: "disposition",
      label: label("disposition"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={dispositions.map(v => [v, v])} />,
    },
    {
      id: "minimumQuantity",
      label: label("minimumQuantity"),
      visible: false,
      inputType: "number",
    },
    {
      id: "existingBuilding",
      label: label("existingBuilding"),
      visible: false,
    },
    {
      id: "quantity",
      label: label("quantity"),
      inputType: "number",
      visible: true,
      width: 140,
    },
    {
      id: "availableQuantity",
      visible: false,
      width: 140,
      disableOrder: true,
      disableFillDown: true,
      input: InventoryTransactionField,
    },
    {
      id: "newBuilding",
      label: label("newBuilding"),
      visible: false,
      isFrozen: true,
    },
    {
      id: "color",
      label: label("color"),
      type: "autocomplete",
      input: ColorsField,
      component: DropdownView,
      visible: true,
    },
    {
      id: "existingFloor",
      label: label("existingFloor"),
      visible: false,
    },
    {
      id: "newFloor",
      label: label("newFloor"),
      visible: false,
    },
    {
      id: "existingRoom",
      label: label("existingRoom"),
      visible: false,
    },
    {
      id: "newRoom",
      label: label("newRoom"),
      visible: false,
    },
    {
      id: "barcode",
      label: label("barcode"),
      component: AssetLinkComponent,
      input: EditAssetModal,
      visible: false,
    },
    {
      id: "description",
      label: label("description"),
      visible: true,
    },
    {
      id: "descriptionComments",
      label: label("descriptionComments"),
      visible: false,
    },
    {
      id: "dimensions",
      label: label("dimensions"),
      visible: true,
      input: DimensionsField,
      component: Dimension,
      disableOrder: true,
      initialValue: [],
    },
    {
      id: "manufacturer",
      label: label("manufacturer"),
      visible: true,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={manufacturers.map(v => [v, v])} />,
    },
    {
      id: "condition",
      label: label("condition"),
      visible: false,
    },
    {
      id: "finishes",
      label: label("finishes"),
      visible: false,
      input: ArrayField,
      component: ArrayComponent,
    },
    {
      id: "originComments",
      label: label("originComments"),
      visible: false,
    },
    {
      id: "destinationComments",
      label: label("destinationComments"),
      visible: false,
    },
    {
      id: "originEmployeeName",
      label: label("originEmployeeName"),
      visible: false,
    },
    {
      id: "destinationEmployeeName",
      label: label("destinationEmployeeName"),
      visible: false,
    },
    {
      id: "destinationQuantity",
      label: label("destinationQuantity"),
      visible: false,
      inputType: "number",
    },
    {
      id: "originRoomNumber",
      label: label("originRoomNumber"),
      visible: false,
    },
    {
      id: "destinationRoomNumber",
      label: label("destinationRoomNumber"),
      visible: false,
    },
    {
      id: "originCubbyNumber",
      label: label("originCubbyNumber"),
      visible: false,
    },
    {
      id: "model",
      label: label("model"),
      visible: true,
    },
    {
      id: "rackLocations",
      label: label("rackLocations"),
      component: RackLocationsComponent,
      input: RackLocationsInput,
      visible: false,
    },
    // MEDICAL FIELDS
    {
      id: "departmentName",
      label: label("departmentName"),
      visible: false,
    },
    {
      id: "labName",
      label: label("labName"),
      visible: false,
    },
    {
      id: "capitalAssetNumber",
      label: label("capitalAssetNumber"),
      visible: false,
    },
    {
      id: "tssNumber",
      label: label("tssNumber"),
      visible: false,
    },
    {
      id: "serialNumber",
      label: label("serialNumber"),
      visible: false,
    },
    {
      id: "vendorRequired",
      label: label("vendorRequired"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "destinationNumber",
      label: label("destinationNumber"),
      visible: false,
    },
    {
      id: "installResponsibility",
      label: label("installResponsibility"),
      visible: false,
    },
    {
      id: "benchFloorHoodWallBsc",
      label: label("benchFloorHoodWallBsc"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={benchFloorHoodWallBscOptions} />,
    },
    {
      id: "siesmicAnchorRequired",
      label: label("siesmicAnchorRequired"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "volts",
      label: label("volts"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={voltsOptions} />,
    },
    {
      id: "ampsWatts",
      label: label("ampsWatts"),
      visible: false,
    },
    {
      id: "btu",
      label: label("btu"),
      visible: false,
    },
    {
      id: "hertz",
      label: label("hertz"),
      visible: false,
    },
    {
      id: "phase",
      label: label("phase"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={phaseOptions} />,
    },
    {
      id: "nemaConfig",
      label: label("nemaConfig"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={nemaConfigOptions} />,
    },
    {
      id: "plugQuantity",
      label: label("plugQuantity"),
      visible: false,
      inputType: "number",
    },
    {
      id: "standbyPower",
      label: label("standbyPower"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "normalPower",
      label: label("normalPower"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "dedicatedPower",
      label: label("dedicatedPower"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "ups",
      label: label("ups"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "standbyPowerSb",
      label: label("standbyPowerSb"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "plugConnection",
      label: label("plugConnection"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={plugConnectionOptions} />,
    },
    {
      id: "hseCo",
      label: label("hseCo"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "localGasTanks",
      label: label("localGasTanks"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={localGasTanksOptions} />,
    },
    {
      id: "hseVac",
      label: label("hseVac"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "localVacPump",
      label: label("localVacPump"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "icw",
      label: label("icw"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "ihw",
      label: label("ihw"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "pcwSupply",
      label: label("pcwSupply"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "pcwReturn",
      label: label("pcwReturn"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "ro",
      label: label("ro"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "di",
      label: label("di"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "floorSink",
      label: label("floorSink"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "floorDrain",
      label: label("floorDrain"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "solveWasteColl",
      label: label("solveWasteColl"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={solveWasteCollOptions} />,
    },
    {
      id: "exhHeader",
      label: label("exhHeader"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={exhHeaderOptions} />,
    },
    {
      id: "vacPumpVentPlumbing",
      label: label("vacPumpVentPlumbing"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "exhCeilingBlastGate",
      label: label("exhCeilingBlastGate"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "exhSnorkelArmPlumbing",
      label: label("exhSnorkelArmPlumbing"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "exhOther",
      label: label("exhOther"),
      visible: false,
    },
    {
      id: "listExhType",
      label: label("listExhType"),
      visible: false,
    },
    {
      id: "exhaust",
      label: label("exhaust"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "vacPumpVentMechanical",
      label: label("vacPumpVentMechanical"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "exhSnorkelArmMechanical",
      label: label("exhSnorkelArmMechanical"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "cfm",
      label: label("cfm"),
      visible: false,
    },
    {
      id: "dataPortQty",
      label: label("dataPortQty"),
      visible: false,
    },
    {
      id: "bms",
      label: label("bms"),
      visible: false,
    },
    {
      id: "ase",
      label: label("ase"),
      visible: false,
      input: BooleanSelectField,
    },
    {
      id: "otherUtility",
      label: label("otherUtility"),
      visible: false,
    },
    {
      id: "moveNotes",
      label: label("moveNotes"),
      visible: false,
    },
    {
      id: "attachments",
      label: label("attachments"),
      visible: false,
      disableFillDown: true,
      input: AttachmentsField,
      component: Attachments,
      disableOrder: true,
    },
    {
      id: "quantityOrdered",
      label: "Expected to Receive Quantity",
      visible: false,
      inputType: "number",
    },
    {
      id: "reservedQuantity",
      label: "Reserved Quantity",
      visible: false,
      input: ({ value }) => value,
    },
    {
      id: "poNumber",
      label: "PO Number",
      visible: false,
      inputType: "text",
    },
    {
      id: "unitCost",
      label: labels.unit_cost,
      visible: false,
      inputType: "number",
    },
    {
      id: "itemCode",
      label: labels.item_code,
      visible: false,
    },
    {
      id: "warranty",
      label: label("warranty"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <AutocompleteField {...props} options={warrantyOptions} />,
    },
    {
      id: "weight",
      label: label("weight"),
      visible: false,
    },
    {
      id: "category",
      label: "Category",
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: CategoryField,
    },
    {
      id: "subcategory",
      label: "Subcategory",
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: SubcategoryField,
    },
    {
      id: "warehouseQuantity",
      label: "Quantity in Warehouse",
      visible: false,
      unEditable: true,
    },
    {
      id: "receivingReportNumber",
      label: "Receiving Report Number",
      visible: false,
      component: ReceivingReportNumberComponent,
      input: ReceivingReportNumberInput, 
    },
    {
      id: "palletType",
      label: label("palletType"),
      visible: false,
      type: "autocomplete",
      component: DropdownView,
      input: props => <SelectField {...props} options={palletTypeOptions} />,
    },
    {
      id: "createdAt",
      label: "Created On",
      visible: true,
      unEditable: true,
      component: DateTime,
    },
    {
      id: "updatedAt",
      label: "Updated On",
      visible: true,
      unEditable: true,
      component: DateTime,
    },
    {
      id: "billingCategory",
      label: label("billing_category"),
      visible: false,
    },
    {
      id: "osdNotes",
      label: label("osd_notes"),
      visible: false,
      unEditable: true,
    },
    {
      id: "generalNotes",
      label: label("general_notes"),
      visible: false,
    },
  ],
};
