import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import ReceivedOnDatePicker from "./ReceivedOnDatePicker";

export default function Header({ title, displayReceivedOn, info, isDraft }) {
  return (
    <Box>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={18} fontWeight={500}>
          {title}
        </Typography>
        <Box display="flex" gap={1}>
          {isDraft && (
            <Chip
              size="medium"
              color="warning"
              variant="outlined"
              label="Draft"
              sx={{
                borderRadius: 1,
                "& .MuiChip-label": {
                  fontWeight: 500,
                  fontSize: 16,
                },
              }}
            />
          )}
          {displayReceivedOn ? <ReceivedOnDatePicker /> : <Typography>{info}</Typography>}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}
