import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Input from "../../form/Input";

export default function CurrentQuantityView({ name }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <Input
      key={isMobile}
      disabled
      name={name}
      label="Current QTY"
      InputLabelProps={
        isMobile
          ? {
              shrink: true,
              style: {
                whiteSpace: "nowrap",
                fontSize: 12,
                color: "#546e7a",
              },
            }
          : { shrink: true }
      }
    />
  );
} 