import React, {useState} from "react";
import {Formik} from "formik";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

import {CaptureProvider} from "../../../../contexts/capture";
import {fetchAssetValues} from "../../../../utils/asset";
import {AssetsProvider} from "../../../../contexts/assets";
import {ClientsProvider} from "../../../../contexts/clients";
import CollectionProvider from "../../../../views/capture/CollectionProvider";
import KitDetailsModal from "../../../../views/shopping/Orders/KitDetailsModal";
import FormPage from "./FormPage";

export default function AssetDetailsModal({ asset, reFetch, onClose }) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(v => !v);
    if (onClose && open) onClose();
  };

  return (
    <>
      <Box
        underline="none"
        sx={{ cursor: "pointer" }}
        color="primary.main"
        className="unfocus"
        onClick={handleToggle}
      >
        {asset.itemId}
      </Box>
      {open && (
        <>
          {asset.type === "Asset" && (
            <Dialog open fullWidth maxWidth="md" onClose={handleToggle}>
              <DialogTitle
                sx={{ background: "#f4f6f8", display: "flex", justifyContent: "space-between" }}
              >
                <Box width="48px" />
                <Typography fontSize={24} fontWeight={500} color="textPrimary">
                  Asset Details
                </Typography>
                <IconButton onClick={handleToggle}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent sx={{ background: "#f4f6f8" }}>
                <Box>
                  <CaptureProvider>
                    <ClientsProvider>
                      <CollectionProvider>
                        <AssetsProvider>
                          <Formik
                            key={asset?.id}
                            enableReinitialize
                            initialValues={fetchAssetValues(asset)}
                            onSubmit={() => {}}
                          >
                            <FormPage
                              type="orders"
                              displayClient={true}
                              displayProject={true}
                              currentAsset={asset}
                              onCloseModal={handleToggle}
                              handeRefetch={reFetch}
                            />
                          </Formik>
                        </AssetsProvider>
                      </CollectionProvider>
                    </ClientsProvider>
                  </CaptureProvider>
                </Box>
              </DialogContent>
            </Dialog>
          )}
          {asset.type === "Kit" && <KitDetailsModal asset={asset} onClose={handleToggle} />}
        </>
      )}
    </>
  );
}
