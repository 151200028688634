import React from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

import StorageLocationAutocomplete from "../../../views/capture/RackLocations/StorageLocationAutocomplete/StorageLocationAutocomplete";
import Input from "../../form/Input";
import RestockSubQuantityField from "./RestockSubQuantityField";
import CurrentQuantityView from "./CurrentQuantityView";
import CurrentQuantityField from "./CurrentQuantityField";
import DeleteLocation from "./DeleteLocation";
import useAuth from "src/hooks/useAuth";

function LocationRow({
  index,
  number,
  multiSelect,
  location,
  setFieldValue,
  selectedLocations,
  onCheckboxChange,
  openRemoveLocation,
  setOpenRemoveLocation,
  onDeleteLocations,
  warehouseLocations,
  loadingWarehouseLocations,
}) {
  const { user } = useAuth();
  const { settings } = user.company;

  return (
    <React.Fragment>
      <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
        {multiSelect && (
          <Checkbox
            checked={selectedLocations.includes(index)}
            onChange={() => onCheckboxChange(index)}
          />
        )}
        <Box width="100%">
          {settings.warehouse_restock_modal ? (
            <StorageLocationAutocomplete
              index={index}
              name="locations"
              value={location}
              loading={loadingWarehouseLocations}
              warehouseLocations={warehouseLocations}
              onChange={values => {
                Object.entries(values).forEach(([key, value]) => {
                  setFieldValue(`locations.${index}.${key}`, value);
                });
              }}
            />
          ) : (
            <Input
              name={`locations.${index}.rack_location[0]`}
              label={`Storage Location ${number}`}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </Box>
      </Grid>
      {settings.simple_inventory_modal ? (
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <CurrentQuantityField name={`locations.${index}.restock_sub_qty`} location={location} />
          <DeleteLocation
            index={index}
            openRemoveLocation={openRemoveLocation}
            setOpenRemoveLocation={setOpenRemoveLocation}
            onDeleteLocations={onDeleteLocations}
          />
        </Grid>
      ) : (
        <>
          {location.sub_qty !== 0 && (
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <CurrentQuantityView name={`locations.${index}.sub_qty`} />
            </Grid>
          )}
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <RestockSubQuantityField
              name={`locations.${index}.restock_sub_qty`}
              label={`Restock Sub QTY ${number}`}
            />
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default React.memo(LocationRow);
