import { useQuery } from "@apollo/react-hooks";
import {
  ALL_WAREHOUSE_LOCATIONS,
  WAREHOUSE_LOCATIONS_BY_CLIENT,
} from "../../constants/graphql/queries";

const formatWarehouseLocation = (item, warehouse, type) => ({
  ...item,
  warehouseId: warehouse.warehouseId,
  warehouseName: warehouse.name,
  type,
});

const formatWarehouseLocations = warehouse => [
  ...warehouse.warehouseLocations.map(item =>
    formatWarehouseLocation(item, warehouse, "WarehouseLocation")
  ),
  ...warehouse.mobileWarehouseLocations.map(item =>
    formatWarehouseLocation(item, warehouse, "MobileWarehouseLocation")
  ),
];

export default function useWarehouseLocations(clientId) {
  const dataByClient = useQuery(WAREHOUSE_LOCATIONS_BY_CLIENT, {
    variables: { id: clientId },
  });
  const { data: { client = {} } = {}, loading: loadingWarehouseLocationsByClient } = dataByClient;
  const warehousesByClient = client?.clientsWarehouses?.map(v => v.warehouse) || [];
  const warehouseLocationsByClient = warehousesByClient.flatMap(formatWarehouseLocations);

  const loadAllWarehouseLocations =
    !loadingWarehouseLocationsByClient && warehousesByClient.length === 0;

  const allData = useQuery(ALL_WAREHOUSE_LOCATIONS, {
    skip: !loadAllWarehouseLocations,
  });
  const { data: { warehouses = [] } = {}, loading: loadingWarehouseLocations } = allData;
  const warehouseLocations = warehouses.flatMap(formatWarehouseLocations);

  const loading = loadingWarehouseLocations || loadingWarehouseLocationsByClient;
  return {
    warehouseLocations: loadAllWarehouseLocations ? warehouseLocations : warehouseLocationsByClient,
    loading,
  };
}
