import React, { useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import ConfirmDeleteModal from "../../components/images/ConfirmDeleteModal";
import useAuth from "src/hooks/useAuth";

export default function CaptureActionsScrubber({
  disabled,
  onSave,
  onDuplicate,
  onRemove,
  onTransfer,
  disableSave,
  onCloseModal,
  handeRefetch,
}) {
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth();
  const { settings } = user.company;

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDelete = () => {
    onRemove().then(() => {
      handeRefetch();
      onCloseModal();
      setShowModal(false);
    });
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex">
          <Box mr={1} flexGrow={1}>
            <Button
              disabled={disabled || !onRemove}
              id="RemoveAsset"
              data-id="remove-asset"
              fullWidth
              variant="outlined"
              onClick={handleOpenModal}
            >
              Delete
            </Button>
          </Box>

          {!settings.receiving_wizard && (
            <>
              <Box mr={1} flexGrow={1}>
                <Button
                  disabled={disabled || !onTransfer}
                  id="TransferAsset"
                  data-id="transfer-asset"
                  fullWidth
                  variant="outlined"
                  onClick={onTransfer}
                >
                  Transfer
                </Button>
              </Box>
              {onDuplicate && (
                <Box mr={1} flexGrow={1}>
                  <Button
                    disabled={disabled || !onDuplicate}
                    id="duplicateAsset"
                    data-id="duplicate-asset"
                    fullWidth
                    variant="outlined"
                    onClick={() =>
                      onDuplicate().then(() => {
                        handeRefetch();
                      })
                    }
                  >
                    Duplicate
                  </Button>
                </Box>
              )}
            </>
          )}
          {onSave && (
            <Box mr={1} flexGrow={1}>
              <Button
                disabled={disabled || !onSave || disableSave}
                id="saveAsset"
                data-id="save-asset"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSave}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </CardContent>

      {showModal && (
        <ConfirmDeleteModal
          title="Delete Asset"
          descr="Are you sure you want to delete the selected asset?"
          onClose={handleCloseModal}
          onDelete={handleDelete}
        />
      )}
    </Card>
  );
}
