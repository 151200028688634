import React from "react";
import { useFormikContext } from "formik";
import moment from "moment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { serverFormat } from "utils/dates";

export default function ReceivedOnDatePicker() {
  const { values, setFieldValue } = useFormikContext();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const handleDateChange = date => {
    setFieldValue("completeTime", moment(date).format("YYYY-MM-DD"));
  };

  if (!values.completeTime) return null;

  return (
    <Box display="flex" alignItems="center">
      {!isMobile && <Typography>Received On:</Typography>}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label={isMobile ? "Received On" : undefined}
          value={values.completeTime ? moment(values.completeTime, serverFormat) : null}
          onChange={handleDateChange}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              sx={{
                width: 145,
                "& .MuiOutlinedInput-notchedOutline": isMobile ? {} : { border: "none" },
              }}
            />
          )}
          inputFormat="MM-DD-YYYY"
        />
      </LocalizationProvider>
    </Box>
  );
}
