import React from "react";
import Box from "@mui/material/Box";
import Progress from "../../../capture/ReceivingWizard/Progress";

export default function Footer({ step, children }) {
  return (
    <Box>
      <Progress step={step} />
      <Box px={2} py={1.5} display="flex" justifyContent="space-between" alignItems="center">
        {children}
      </Box>
    </Box>
  );
}
