import React from "react";
import { Field } from "formik";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import useMediaQuery from "@mui/material/useMediaQuery";
import AssetQuantityField from "../../../views/inventory/requests/AssetQuantityField";

export default function RestockSubQuantityField({ name, label }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <Field name={name} label={label}>
      {({ field, form }) => {
        return isMobile ? (
          <Box display="flex">
            <TextField
              size="small"
              label="Received QTY"
              type="number"
              value={field.value}
              InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
              onChange={e => form.setFieldValue(field.name, parseInt(e.target.value) || 0)}
            />
            <Button
              sx={{ minWidth: 20 }}
              variant="contained"
              color="primary"
              type="button"
              onClick={() => form.setFieldValue(field.name, (field.value || 0) + 1)}
            >
              <AddIcon fontSize="medium" />
            </Button>
          </Box>
        ) : (
          <AssetQuantityField
            size="small"
            quantity={field.value}
            onChange={quantity => form.setFieldValue(field.name, quantity || 0)}
            quantityProps={{
              label: "QTY Received",
              style: { minWidth: 110 },
              InputLabelProps: {
                shrink: true,
              },
            }}
          />
        );
      }}
    </Field>
  );
}
