import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import AssetCard from "./AssetCard";
import Menu from "../../../components/Menu";
import ImageModal from "./ImageModal";
import SkeletonResult from "./SkeletonResult";
import PaginationResults from "./PaginationResults";
import AddAssetsModal from "./AddAssetsModal";
import { useCustomFields } from "../../../contexts/customFields";

const PAGINATION_PAGES = [24, 48, 72, 96];

export default function Results({ assets, loading, pagination, defaultValues, onChange }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const { totalCount = 0 } = pagination;
  const paginationPages = PAGINATION_PAGES;
  const [checked, setChecked] = useState(false);
  const [display] = useCustomFields();

  const selectedAsset = useMemo(
    () => assets.find(asset => asset.images.find(image => image.id === selectedImage)),
    [selectedImage]
  );

  const handlePageChange = (_, value) => {
    onChange({ page: value });
  };

  const handleLimitChange = value => {
    onChange({ limit: value });
  };

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <>
      {assets.length > 0 && (
        <Box mt={6} display="flex" alignItems="center" justifyContent="space-between">
          <FormControlLabel
            sx={{ mb: 0 }}
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="Select Page"
          />
          {checked && (
            <AddAssetsModal
              title="Add Page to Cart"
              assets={assets
                .filter(v => v.availableQuantity > 0)
                .map(v => ({ ...v, quantity: v.availableQuantity }))}
              onClose={() => setChecked(false)}
            />
          )}
          <PaginationResults
            id="topPaginationAssets"
            onChange={handlePageChange}
            disabled={loading}
            pagination={pagination}
          />
          <Box width={100} />
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" mb={2}>
        <Typography variant="h5" color="textPrimary">
          {`${totalCount} assets`}
        </Typography>
        <Box display="flex" alignItems="center">
          <Menu
            data-id="assetsPages"
            options={paginationPages}
            value={pagination.limitValue}
            onSelect={handleLimitChange}
          />
        </Box>
      </Box>
      {loading ? (
        <SkeletonResult />
      ) : (
        <Grid container spacing={2}>
          {assets.map(asset => {
            return (
              <Grid item key={`AssetCard${asset.id}`} md={4} sm={6} xs={12}>
                <AssetCard
                  display={display}
                  defaultValues={defaultValues}
                  onClickImage={(asset, image) => setSelectedImage(image.id)}
                  asset={asset}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {assets.length > 0 && (
        <Box mt={6} display="flex" justifyContent="center">
          <PaginationResults
            id="downPaginationAssets"
            onChange={handlePageChange}
            disabled={loading}
            pagination={pagination}
          />
        </Box>
      )}
      {Boolean(selectedImage) && (
        <ImageModal
          asset={selectedAsset}
          images={selectedAsset?.images || []}
          selectedId={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </>
  );
}
