import React, { useRef, useState, useEffect, useMemo } from "react";
import orderBy from "lodash/orderBy";
import Skeleton from "@mui/material/Skeleton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import useAuth from "../../../../hooks/useAuth";
import ScanditScanner from "../../../../components/barcode/ScanditScanner/ScanditScanner";
import WarehouseModal from "../WarehouseModal";
import PopperComponent from "./PopperComponent";
import PaperComponent from "./PaperComponent";
import MobileAttentionModal from "./MobileAttentionModal";
import SelectFixedLocation from "./SelectFixedLocation";

export default function StorageLocationAutocomplete({
  index,
  value,
  loading,
  disabled,
  warehouseLocations,
  triggerScan,
  setTriggerScan,
  onChange,
}) {
  const { user } = useAuth();
  const autocompleteRef = useRef();
  const [inputValue, setInputValue] = useState("");
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [openScan, setOpenScan] = useState(false);
  const [openFixedLocation, setOpenFixedLocation] = useState(false);
  const [selectedMobileLocation, setSelectedMobileLocation] = useState(null);
  const number = typeof index === "number" ? index + 1 : "";
  const allLocations = orderBy(warehouseLocations, v => v.locationId).map(v => ({
    warehouseId: v.warehouseId,
    warehouseName: v.warehouseName,
    locationName: v.locationName,
    __typename: v.__typename,
  }));
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const selectedValue = useMemo(() => {
    const list = allLocations.filter(
      v => value.warehouse === v.warehouseId && value.rack_location?.includes(v.locationName)
    );
    if (value.mobile_warehouse_location) {
      return list.map(v => ({
        ...v,
        mobile_warehouse_location: value.mobile_warehouse_location,
      }));
    }
    return list;
  }, [allLocations, value.warehouse, value.rack_location, value.mobile_warehouse_location]);

  const fixedLocations = useMemo(
    () =>
      allLocations
        .filter(v => v.__typename === "WarehouseLocation")
        .filter(v => v.warehouseId === selectedMobileLocation?.warehouseId),
    [allLocations, selectedMobileLocation]
  );

  const handleToggle = () => {
    setOpenWarehouse(v => !v);
  };

  const handleToggleScan = () => {
    setOpenScan(v => !v);
  };

  const handleSelect = ({ __typename, ...locationData }) => {
    if (__typename === "MobileWarehouseLocation") {
      setSelectedMobileLocation(locationData);
      return;
    }

    const rackLocation =
      value.rack_location && Array.isArray(value.rack_location) ? value.rack_location : [];
    onChange({
      warehouse: locationData.warehouseId,
      rack_location: rackLocation.concat(locationData.locationName),
    });
  };

  const handleDetectedCode = code => {
    setTimeout(() => {
      autocompleteRef.current.click();
      openPopper();
      setInputValue(code);
      handleToggleScan();
    });
  };

  const handleInputChange = (e, value) => {
    if (e) {
      setInputValue(value);
    }
  };

  const handleChange = (e, value) => {
    const addedItem = value.length > selectedValue.length ? value.at(-1) : null;
    if (addedItem && addedItem.__typename === "MobileWarehouseLocation") {
      return setSelectedMobileLocation(addedItem);
    }

    if (value.length) {
      onChange({
        warehouse: value[0].warehouseId,
        rack_location: value.map(v => v.locationName),
      });
    } else {
      onChange({
        warehouse: "",
        rack_location: [],
      });
    }
  };

  const handleSelectFixedLocation = data => {
    onChange({
      warehouse: data[0].warehouseId,
      rack_location: data.map(v => v.locationName),
      mobile_warehouse_location: selectedMobileLocation.locationName,
    });

    setSelectedMobileLocation(null);
    setOpenFixedLocation(false);
  };

  useEffect(() => {
    if (triggerScan) {
      handleToggleScan();
      setTriggerScan(false);
    }
  }, [triggerScan]);

  return loading ? (
    <Skeleton variant="rounded" width="100%" height={39} />
  ) : (
    <>
      {selectedMobileLocation && (
        <MobileAttentionModal
          open={true}
          onClose={() => setSelectedMobileLocation(null)}
          onNext={() => setOpenFixedLocation(true)}
        />
      )}
      {openFixedLocation && (
        <SelectFixedLocation
          onClose={() => setOpenFixedLocation(false)}
          onSubmit={handleSelectFixedLocation}
          allLocations={fixedLocations}
        />
      )}
      <Autocomplete
        ref={autocompleteRef}
        size="small"
        multiple
        disabled={disabled}
        options={allLocations}
        value={selectedValue}
        inputValue={inputValue}
        open={open}
        onOpen={openPopper}
        onClose={closePopper}
        onInputChange={handleInputChange}
        getOptionLabel={v =>
          v.mobile_warehouse_location
            ? `${v.mobile_warehouse_location} in ${v.locationName}`
            : v.locationName
        }
        getOptionDisabled={v =>
          selectedValue.length > 0 && selectedValue[0].warehouseId !== v.warehouseId
        }
        onChange={handleChange}
        renderInput={params => <TextField {...params} label={`Storage Location ${number}`} />}
        ListboxProps={{ style: { paddingBottom: "300px" }, position: "bottom-start" }}
        PopperComponent={PopperComponent}
        PaperComponent={props => (
          <PaperComponent {...props} onToggle={handleToggle} onToggleScan={handleToggleScan} />
        )}
      />
      {openWarehouse && (
        <WarehouseModal open={openWarehouse} onClose={handleToggle} onSelect={handleSelect} />
      )}
      {openScan && (
        <ScanditScanner
          barcodeTrigger={user.company.settings.barcode_trigger}
          onDetected={handleDetectedCode}
          onClose={handleToggleScan}
        />
      )}
    </>
  );
}
