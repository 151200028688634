import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Input from "../../../components/form/Input";
import useAuth from "../../../hooks/useAuth";
import useWarehouseLocations from "../../../hooks/warehouse/useWarehouseLocations";
import StorageLocationAutocomplete from "./StorageLocationAutocomplete/StorageLocationAutocomplete";

const schema = Yup.object().shape({
  rackLocations: Yup.array().of(
    Yup.object().shape({
      rack_location: Yup.string().required("Required"),
    })
  ),
});

export default function RackLocationsForm({ asset, toggleModal, rackLocations, onSubmit }) {
  const { user } = useAuth();
  const { warehouseLocations, loading: loadingWarehouseLocations } = useWarehouseLocations(
    asset.client.id
  );

  return (
    <Formik initialValues={{ rackLocations }} onSubmit={onSubmit} validationSchema={schema}>
      {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            {values.rackLocations.map((location, index) => (
              <React.Fragment key={index}>
                <Grid item sm={7}>
                  {user.company.settings.warehouse_restock_modal ? (
                    <StorageLocationAutocomplete
                      index={index}
                      name="rackLocations"
                      value={values.rackLocations[index]}
                      loading={loadingWarehouseLocations}
                      warehouseLocations={warehouseLocations}
                      onChange={values => {
                        Object.entries(values).forEach(([key, value]) => {
                          setFieldValue(`rackLocations.${index}.${key}`, value);
                        });
                      }}
                    />
                  ) : (
                    <Input
                      name={`rackLocations[${index}].rack_location[0]`}
                      label={`Storage Location ${index + 1}`}
                    />
                  )}
                </Grid>
                <Grid item sm={5}>
                  <Input
                    disabled
                    name={`rackLocations[${index}].sub_qty`}
                    label={`Sub QTY ${index + 1}`}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          <Box mt={4} />
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button color="primary" variant="outlined" onClick={toggleModal}>
              Close
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}
