import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function WidgetCard({ label, value, icon }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <Box
      display="flex"
      alignItems="center"
      bgcolor="white"
      px={{ xs: 2, ms: 3 }}
      py={{ xs: 1.5, ms: 2 }}
      borderRadius="15px"
      height="100%"
    >
      {!isMobile && icon}
      <Box ml={isMobile ? undefined : 2}>
        <Typography fontSize={{ xs: 16, sm: 18, md: 24 }} fontWeight={600}>
          {value}
        </Typography>
        <Typography fontSize={{ xs: 12, sm: 16, md: 16 }}>{label}</Typography>
      </Box>
    </Box>
  );
}
