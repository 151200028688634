import React from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import Attachments from "./Attachments";

export default function AttachmentModal({inventoryRequestId, onClose}) {
  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h2" color="textPrimary" component="span">
            Attachments
          </Typography>
          <IconButton color="textPrimary" onClick={onClose}>
            <CloseIcon/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Attachments inventoryRequestId={inventoryRequestId}/>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
