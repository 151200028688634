import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { useHistory } from "react-router-dom";
import sumBy from "lodash/sumBy";
import { useFormikContext } from "formik";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "src/components/form/Input";
import useMutation from "src/hooks/useMutation";
import { UPDATE_ASSET_MUTATION } from "src/constants/graphql";
import AssetImage from "../AssetImage";
import TotalWeightError from "./TotalWeightError";
import { getGroupWeight } from "./utils";
import { UPDATE_SHIPMENT_ITEMS_MUTATION } from "src/constants/graphql/mutations";

export default function CalculateItemWeightTable({ shipmentItems, totalWeight, totalQuantity }) {
  const history = useHistory();
  const [error, setError] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const [loading, setLoading] = useState(false);
  const [updateAsset] = useMutation(UPDATE_ASSET_MUTATION);
  const list = shipmentItems.map((v, index) => ({
    ...v,
    unitWeight: values[index].groupWeight / v.quantity,
  }));
  const totalWeightCalculated = Math.round(sumBy(values, "groupWeight"));
  const [updateShipmentItem] = useMutation(UPDATE_SHIPMENT_ITEMS_MUTATION);

  const handleRecalculate = () => {
    shipmentItems.forEach((v, index) =>
      setFieldValue(
        `[${index}].groupWeight`,
        getGroupWeight(totalWeight, totalQuantity, v.quantity)
      )
    );
  };

  const handleNext = async () => {
    const weightDifference = Math.abs(totalWeightCalculated - totalWeight);
    if (weightDifference > 2) {
      setError(true);
      return;
    }

    setLoading(true);
    setError(false);
    try {
      const updateAssetsPromises = list.map(({ asset, unitWeight }) =>
        updateAsset({
          variables: {
            id: asset.id,
            input: {
              weight: unitWeight.toString(),
              dimensions: asset.dimensions,
            },
          },
        })
      );
      const updateShipmentItemsPromises = list.map(({ id, unitWeight, quantity }) =>
        updateShipmentItem({
          variables: { id, weight: unitWeight, quantity },
        })
      );
      await Promise.all([...updateAssetsPromises, ...updateShipmentItemsPromises]);
      const { pathname, search } = window.location;
      const params = new URLSearchParams(search);
      params.set("step", "os&d");
      params.delete("itemWeight");
      history.push({
        pathname,
        search: params.toString(),
      });
    } catch (error) {
      console.error("Failed to update assets or shipment items:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Calculated Unit Weight</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Calculated Group Weight</TableCell>
            </TableRow>
          </TableHead>
          {list.map((v, index) => (
            <TableRow key={v.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                <AssetImage width={70} height={60} image={v.asset.images[0]?.url} />
              </TableCell>
              <TableCell>{v.asset.description}</TableCell>
              <TableCell>{v.quantity}</TableCell>
              <TableCell>{v.unitWeight.toFixed(2)} lbs</TableCell>
              <TableCell sx={{ minWidth: 140 }}>
                <Input
                  type="number"
                  step="0.01"
                  name={`[${index}].groupWeight`}
                  label="Group Weight"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ mr: "3px" }} position="start">
                        lbs
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
      <Box mt={2} textAlign="right" fontWeight={500} color="red">
        Total Weight: {totalWeightCalculated} lbs
      </Box>
      <Box mt={3} display="flex" justifyContent="space-between">
        <Button variant="outlined" onClick={handleRecalculate}>
          Recalculate
        </Button>
        <Button
          disabled={loading}
          startIcon={loading && <CircularProgress size={22} />}
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={handleNext}
        >
          Validate
        </Button>
      </Box>
      {error && <TotalWeightError totalWeight={totalWeight} onClose={() => setError(false)} />}
    </>
  );
}
