import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomerFieldsModal from "src/components/project/CustomerFieldsModal";
import Checkbox from "src/components/form/Checkbox";
import useAuth from "src/hooks/useAuth";
import { useStorageEnabled } from "src/components/project/StorageEnabledProvider";

const styles = {
  button: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    textTransform: "none",
    color: "text.primary",
  },
  checkbox: {
    px: 1,
    display: "flex",
    justifyContent: "center",
    height: 36.5,
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    "& label": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      mx: 0,
      height: 36.5,
      "& > span > p": {
        lineHeight: 1,
        color: "text.primary",
      },
    },
  },
  actionButton: {
    minWidth: 120,
  },
};

export default function CustomerSetup({ onToggleProjectInfoModal }) {
  const { user } = useAuth();
  const { settings } = user.company;
  const [open, setOpen] = React.useState(false);
  const { values } = useFormikContext();
  const [storageEnabled, setStorageEnabled] = useStorageEnabled();

  const handleToggle = () => {
    if (!open) {
      onToggleProjectInfoModal();
    }
    setOpen(v => !v);
  };

  useEffect(() => {
    setStorageEnabled(values.storageEnabled);
  }, [values.storageEnabled]);

  return (
    <>
      <Paper>
        <Box p={2}>
          <Typography fontWeight={500}>{settings.filter_field2} Setup</Typography>
          <Grid container spacing={1} sx={{ pt: 1.5 }}>
            <Grid item xs={6} sm={3}>
              <Box sx={styles.checkbox}>
                <Checkbox name="storageEnabled" label="Storage" />
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box sx={styles.checkbox}>
                <Checkbox name="importedAssets" label="Imported Assets" />
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box sx={styles.checkbox}>
                <Checkbox name="active" label="Active" />
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box sx={styles.checkbox}>
                <Checkbox name="settings.display_in_store" label="Customer Portal" />
              </Box>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Button
                fullWidth
                sx={styles.button}
                variant="outlined"
                color="inherit"
                onClick={handleToggle}
              >
                {settings.filter_field2} Fields
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {open && (
        <CustomerFieldsModal
          storageEnabled={storageEnabled}
          originEnabled={!storageEnabled}
          destinationEnabled={!storageEnabled}
          ordersTableFieldsEnabled={false}
          reservationsTableFieldsEnabled={false}
          onClose={handleToggle}
        />
      )}
    </>
  );
}
