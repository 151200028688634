import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PaginationMui from "@mui/material/Pagination";
import Menu from "src/components/Menu";

const PAGINATION_PAGES = [24, 48, 72, 96];

const PaginationResults = ({ pagination, disabled, onChange, ...props }) => {
  const configuration = pagination.totalPages
    ? {
        disabled: disabled,
        count: pagination.totalPages,
        page: pagination.currentPage,
        onChange: (_, value) => onChange(value),
      }
    : { disabled: true, count: 3, page: 1 };

  return <PaginationMui {...configuration} {...props} />;
};

const PaginationWrap = ({ loading, onChange, children, metadata }) => {
  return (
    <>
      <Box mt={6} display="flex" justifyContent="center">
        <PaginationResults
          id="topPaginationOrders"
          onChange={(page) => onChange({ page })}
          disabled={loading}
          pagination={metadata}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={2}
      >
        <Typography fontWeight={500} color="textPrimary">
          {`Total count ${metadata.totalCount || 0}`}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Menu
          data-id="assetsPages"
          options={PAGINATION_PAGES}
          value={metadata.limit}
          onSelect={(limit) => onChange({ limit })}
        />
      </Box>

      {children}

      <Box mt={6} display="flex" justifyContent="center">
        <PaginationResults
          id="downPaginationAssets"
          onChange={(page) => onChange({ page })}
          disabled={loading}
          pagination={metadata}
        />
      </Box>
    </>
  );
};

export default PaginationWrap;
