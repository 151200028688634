import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckoutFullTaskFields from "../../../components/form/fields/checkout/CheckoutFullTaskFields";

export default function PackingField() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography fontWeight={500} fontSize={18}>
          Checkout
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CheckoutFullTaskFields isMobile name="notes.checkout_task" />
      </Grid>
    </Grid>
  );
}
