import React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import ImageInput from "../../components/form/ImageInput";
import Input from "../../components/form/Input";
import BarCodeInput from "../../components/barcode/BarCodeInput";
import labels from "../../constants/displayFields";
import Images from "./Images/Images";
import ImagesScrubber from "./Images/ImagesScrubber";
import ImagesOrders from "./Images/ImagesOrders";
import FieldsAttachments from "../../views/capture/FieldsAttachments";
import useAuth from "../../hooks/useAuth";
import useLabels from "../../hooks/useLabels";
import ImagesItem from "./Images/ImagesItem";
import ScannerInput from "../../components/form/ScannerInput";

const DATE_FORMAT = "MM/DD/yyyy hh:mm A";
const serverFormat = "YYYY-MM-DDTHH:mm:ss Z";

const formatDateTime = dateTime => {
  return moment(dateTime, serverFormat).format(DATE_FORMAT);
};

export default function FieldsIdentifiers({
  fullWidth,
  display,
  type,
  disabledForm,
  asset,
  createImage,
  handleUpdateAssetField,
  displayClient,
  displayProject,
}) {
  const md = fullWidth ? 12 : 6;
  const { user } = useAuth();
  const { settings } = user.company;
  const l = useLabels();
  const isItem = type === "item";

  const handleCreateImage = (file, { onSuccess, onFailure } = {}) => {
    createImage({
      variables: { assetId: asset.id, image: file },
      onSuccess,
      onFailure,
    });
  };

  return (
    <Card style={{ overflow: "visible" }}>
      <CardContent>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Identifiers
          </Typography>
        </Box>
        <Grid container alignItems="flex-start" spacing={1}>
          <Grid item md={md} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {type === "scrubber" && <ImagesScrubber asset={asset} />}{" "}
                {/* Image viewer for asset details modal */}
                {type === "capture" && <Images asset={asset} />}{" "}
                {/* Image viewer for capture page */}
                {type === "item" && <ImagesItem asset={asset} />}
                {type === "orders" && <ImagesOrders disabledForm={disabledForm} asset={asset} />}
              </Grid>
              {!isItem && !disabledForm && (
                <>
                  <Grid item xs={md}>
                    <ImageInput imageUrl={asset.photo} onUpload={handleCreateImage} />
                  </Grid>
                  <Grid item xs={md}>
                    <BarCodeInput
                      id={`barcodeInput-${asset.id}`}
                      barcode={asset.barcode}
                      onChange={handleUpdateAssetField("barcode")}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item md={md} xs={12}>
            <Grid container spacing={1}>
              {displayClient && (
                <Grid item xs={md}>
                  <TextField
                    fullWidth
                    size="small"
                    label={settings.filter_field1}
                    value={asset.client.name}
                    sx={{
                      fieldset: { borderColor: "#3C48A5" },
                      label: { color: "#3C48A5" },
                      input: { color: "#3C48A5" },
                    }}
                  />
                </Grid>
              )}
              {displayProject && (
                <Grid item xs={md}>
                  <TextField
                    fullWidth
                    size="small"
                    label={settings.filter_field2}
                    value={asset.project.name}
                    sx={{
                      fieldset: { borderColor: "#3C48A5" },
                      label: { color: "#3C48A5" },
                      input: { color: "#3C48A5" },
                    }}
                  />
                </Grid>
              )}
              {!isItem && display.item_id && (
                <Grid item xs={12} sm={md}>
                  <TextField
                    size="small"
                    label={labels.item_id}
                    disabled
                    value={asset.itemId}
                    fullWidth
                  />
                </Grid>
              )}
              {display.barcode_number && (
                <Grid item xs={12} sm={md}>
                  <ScannerInput
                    type="autoExpand"
                    name="barcode"
                    label={l.identifiers.barcode_number}
                    disabled={disabledForm}
                  />
                </Grid>
              )}
              {display.item_code && (
                <Grid item xs={12} sm={md}>
                  <Input
                    type="autoExpand"
                    name="itemCode"
                    label={labels.item_code}
                    disabled={disabledForm}
                  />
                </Grid>
              )}
              {display.serial_number && (
                <Grid item xs={12} sm={md}>
                  <ScannerInput
                    type="autoExpand"
                    name="serialNumber"
                    label={l.identifiers.serial_number}
                    disabled={disabledForm}
                  />
                </Grid>
              )}
              {display.capital_asset_number && (
                <Grid item xs={12} sm={md}>
                  <ScannerInput
                    type="autoExpand"
                    name="capitalAssetNumber"
                    label={l.identifiers.capital_asset_number}
                    disabled={disabledForm}
                  />
                </Grid>
              )}
              {display.tss_number && (
                <Grid item xs={12} sm={md}>
                  <Input
                    type="autoExpand"
                    name="tssNumber"
                    label={l.identifiers.tss_number}
                    disabled={disabledForm}
                  />
                </Grid>
              )}
              {display.billing_category && (
                <Grid item xs={12} sm={md}>
                  <Input
                    type="autoExpand"
                    name="billingCategory"
                    label={labels.billing_category}
                    disabled={disabledForm}
                  />
                </Grid>
              )}
              {!isItem && display.receiving_report_number && (
                <Grid item xs={12} sm={md}>
                  <Input
                    multiline
                    name="receivingReportNumber"
                    label={l.identifiers.receiving_report_number}
                    disabled={disabledForm}
                  />
                </Grid>
              )}
              {!isItem && display.created_at && (
                <Grid item xs={12} sm={md}>
                  <TextField
                    fullWidth
                    size="small"
                    label={l.identifiers.created_at}
                    disabled
                    value={formatDateTime(asset.createdAt)}
                  />
                </Grid>
              )}
              {!isItem && display.updated_at && (
                <Grid item xs={12} sm={md}>
                  <TextField
                    fullWidth
                    size="small"
                    label={l.identifiers.updated_at}
                    disabled
                    value={formatDateTime(asset.updatedAt)}
                  />
                </Grid>
              )}
              {!isItem && display.osd_notes && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    size="small"
                    label={labels.osd_notes}
                    disabled
                    value={asset.osdNotes || ""}
                    maxRows={4}
                  />
                </Grid>
              )}
              {!isItem && display.general_notes && (
                <Grid item xs={12}>
                  <Input
                    multiline
                    name="generalNotes"
                    label={labels.general_notes}
                    disabled={disabledForm}
                  />
                </Grid>
              )}
              {!isItem && display.attachments && (
                <Grid item xs={12}>
                  <FieldsAttachments asset={asset} disabled={disabledForm} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
