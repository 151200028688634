export function getProjectFieldSettingsVariables(billingType, settings) {
  const fieldSettings = settings || {};

  if (billingType === "Hundredweight") {
    fieldSettings.receiving_report_number = true;
    fieldSettings.item_id = true;
    fieldSettings.weight = true;
    fieldSettings.available_quantity = true;
    fieldSettings.warehouse_quantity = true;
    fieldSettings.rack_locations = true;
    fieldSettings.total_weight = true;
    fieldSettings.total_receiving_units = true;
  } else if (billingType === "By the Pallet") {
    fieldSettings.receiving_report_number = true;
    fieldSettings.item_id = true;
    fieldSettings.pallet_type = true;
    fieldSettings.available_quantity = true;
    fieldSettings.warehouse_quantity = true;
    fieldSettings.rack_locations = true;
    fieldSettings.total_receiving_units = true;
  } else if (["Square Foot", "Cubic Foot"].includes(billingType)) {
    fieldSettings.receiving_report_number = true;
    fieldSettings.item_id = true;
    fieldSettings.dimensions = true;
    fieldSettings.available_quantity = true;
    fieldSettings.warehouse_quantity = true;
    fieldSettings.rack_locations = true;
    fieldSettings.total_receiving_units = true;
  }
  return fieldSettings;
}

export function getProjectVariables(values) {
  const { __typename, ...address } = values.address || {};

  return {
    id: values.id,
    name: values.name,
    disabled: !values.active,
    storageEnabled: values.storageEnabled,
    settings: values.settings,
    fieldSettings: values.fieldSettings,
    customerOwner: values.customerOwner,
    customerNotes: values.customerNotes,
    contactName: values.contactName,
    contactEmail: values.contactEmail,
    contactPhoneNumber: values.contactPhoneNumber,
    customerEmailSendList: values.customerEmailSendList,
    osDEmailSendList: values.osDEmailSendList,
    billingEmailSendList: values.billingEmailSendList,
    approvedEmailSendList: values.approvedEmailSendList,
    billingType: values.billingType,
    billingMethod: values.billingMethod,
    billingDate: values.billingDate,
    cwtInRate: values.cwtInRate,
    cwtStorageRate: values.cwtStorageRate,
    cwtOutRate: values.cwtOutRate,
    normalPalletRate: values.normalPalletRate,
    oversizePalletRate: values.oversizePalletRate,
    minimumCharge: values.minimumCharge,
    squareFootRate: values.squareFootRate,
    cubicFootRate: values.cubicFootRate,
    importedAssets: values.importedAssets,
    address,
  };
}
