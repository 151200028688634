import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import useAuth from "../../../hooks/useAuth";
import {
  ASSET_AUDIT_LOGS_QUERY,
  ASSET_REPLAY_LOGS_QUERY,
} from "../../../constants/graphql/queries";
import {
  ADD_SHOPPING_INVENTORY_ITEM_MUTATION,
  CREATE_INVENTORY_TRANSACTION_MUTATION,
} from "../../../constants/graphql";
import useMutation from "../../../hooks/useMutation";
import useNotification from "../../../hooks/notification";
import { formRef } from "../../../views/capture/Form";
import useQueryParams from "../../../hooks/useQueryParams";
import useWarehouseLocations from "../../../hooks/warehouse/useWarehouseLocations";
import Fields, { getRestockTotal } from "./Fields";
import PalletButton from "./pallet/PalletButton";
import useInitialValues from "./hooks/useInitialValues";
import { getFormattedLocations, getOldRackLocations, getRackLocationsChanges } from "./utils";
import InventoryTransactionActions from "./InventoryTransactionActions";

const schema = Yup.object().shape({
  locations: Yup.array().of(
    Yup.object().shape({
      rack_location: Yup.string().required("Required"),
    })
  ),
});

export default function InventoryTransactionMultipleModal({
  open,
  onClose,
  onSuccess,
  title,
  asset,
}) {
  const { user } = useAuth();
  const { settings } = user.company;
  const { projectId } = useParams();
  const params = useQueryParams();
  const shipmentItemId = params.get("shipmentItemId");
  const [loading, setLoading] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const [createTransactionMutation] = useMutation(CREATE_INVENTORY_TRANSACTION_MUTATION);
  const [addInventoryItem] = useMutation(ADD_SHOPPING_INVENTORY_ITEM_MUTATION);
  const { notifySuccess, notifyError } = useNotification();
  const { warehouseLocations, loading: loadingWarehouseLocations } = useWarehouseLocations(
    asset.client.id
  );
  const initialStep = settings.pallet_breakdown_wizard && shipmentItemId ? 0 : -1;
  const [step, setStep] = useState(initialStep);

  const initialValues = useInitialValues(asset, warehouseLocations);

  const onSubmit = values => {
    const locations = getFormattedLocations(values, settings.warehouse_restock_modal);
    const oldRackLocations = getOldRackLocations(initialValues.locations);
    const rackLocationsChanges = getRackLocationsChanges(values.locations);
    const restockTotal = getRestockTotal(values.locations) - values.deletedQuantity;

    setLoading(true);

    const variables = {
      code: values.code,
      notes: values.notes || "",
      senderName: values.senderName || "",
      carrierName: values.carrierName || "",
      trackingNumber: values.trackingNumber || "",
      orderNumber: values.orderNumber || "",
      poNumber: values.poNumber || "",
      quantity: restockTotal,
      rackLocations: locations,
      rackLocationsChanges,
      oldRackLocations,
      id: values.id,
    };

    if (shipmentItemId) {
      variables.shipmentItemId = shipmentItemId;
    }

    const refetchQueries = [
      settings.asset_replay_logs
        ? { query: ASSET_REPLAY_LOGS_QUERY, variables: { assetId: values.id } }
        : {
            query: ASSET_AUDIT_LOGS_QUERY,
            variables: { assetId: values.id, action: "replenish_inventory" },
          },
    ];

    return createTransactionMutation({
      variables,
      refetchQueries,
      onSuccess: data => {
        setLoading(false);
        notifySuccess("Asset successfully updated!");
        onSuccess(data, values);
      },
      onFailure: errors => {
        notifyError(errors.submit);
        setLoading(false);
      },
    });
  };

  const restockAddToCart = values => {
    const restockTotal = getRestockTotal(values.locations);
    onSubmit(values).then(() => {
      if (restockTotal > 0) {
        addInventoryItem({
          variables: {
            assetId: values.id,
            quantity: restockTotal,
            projectId,
          },
        });
      }
    });
  };

  const handleToggleMultiSelect = () => {
    setMultiSelect(v => !v);
  };

  useEffect(() => {
    if (formRef?.current?.dirty) {
      const saveButton = document.querySelector("#saveAsset");
      if (saveButton) {
        saveButton.click();
      }
    }
  }, []);

  const openPalletBreakdown = useCallback(() => {
    setStep(0);
  }, [setStep]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={schema}
          enableReinitialize
        >
          <Box minHeight={340} display="flex" flexDirection="column" justifyContent="space-between">
            <Box display="flex" justifyContent="center" alignItems="center" p={1} mb={3}>
              <Typography component="span" color="text.secondary" variant="h2" align="center">
                {title}
              </Typography>
              <PalletButton step={step} setStep={setStep} warehouseLocations={warehouseLocations} />
            </Box>
            <IconButton
              onClick={onClose}
              size="small"
              sx={{ position: "absolute", right: 16, top: 16 }}
            >
              <CloseIcon />
            </IconButton>
            <Grid container spacing={1}>
              {asset && (
                <Fields
                  multiSelect={multiSelect}
                  asset={asset}
                  warehouseLocations={warehouseLocations}
                  loadingWarehouseLocations={loadingWarehouseLocations}
                  openPalletBreakdown={openPalletBreakdown}
                />
              )}
            </Grid>
            <Box pt={5}>
              <InventoryTransactionActions
                loading={loading}
                initialValues={initialValues}
                restockAddToCart={restockAddToCart}
                onToggleMultiSelect={handleToggleMultiSelect}
              />
            </Box>
          </Box>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
