import { useMemo } from "react";
import useTempLocationEnabled from "./useTempLocationEnabled";
import useTempLocation from "./useTempLocation";
import { getInitialLocation } from "../utils";

export default function useInitialValues(asset, warehouseLocations) {
  const isTempLocationEnabled = useTempLocationEnabled();
  const tempLocation = useTempLocation(warehouseLocations);

  const initialLocations = useMemo(
    () => [getInitialLocation(isTempLocationEnabled, tempLocation)],
    [isTempLocationEnabled, tempLocation]
  );

  const initialValues = useMemo(
    () => ({
      id: asset.id,
      code: asset.assetId,
      deletedQuantity: 0,
      locations: asset?.rackLocations.length ? asset.rackLocations : initialLocations,
    }),
    [asset, initialLocations]
  );

  return initialValues;
}
