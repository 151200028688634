import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Footer from "./Footer";
import Selected from "./Selected";
import AssetQuantityField from "../../../../views/inventory/requests/AssetQuantityField";

const styles = {
  button: {
    minWidth: 100,
  },
};

export default function Step3({ state, onNext, onBack }) {
  const [piecesPerBox, setPiecesPerBox] = useState(state.piecesPerBox || 1);

  const handleNext = () => {
    onNext({ done: true, piecesPerBox });
  };

  const handleNotApplicable = () => {
    onNext({ done: true, piecesPerBox: 1 });
  };

  return (
    <>
      <DialogContent>
        <Box textAlign="center" mb={3} minHeight={300} display="flex" alignItems="center">
          <Box width="100%">
            <Typography color="textPrimary" mb={4}>
              How many pieces are in each box?
            </Typography>
            <Box mt={4}>
              <AssetQuantityField
                quantity={piecesPerBox}
                onChange={setPiecesPerBox}
                size="small"
                quantityProps={{
                  label: "# of pieces per box",
                  style: { minWidth: 125 },
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              />
            </Box>
            <Button
              variant="text"
              color="primary"
              onClick={handleNotApplicable}
              sx={{ mt: 2, textTransform: "none" }}
            >
              Not applicable
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <Footer step={2}>
        <Selected palletCount={state.palletCount} boxesCount={state.boxesCount} />
        <Box display="flex" gap={1}>
          <Button variant="outlined" onClick={onBack} sx={styles.button}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!piecesPerBox}
            sx={styles.button}
          >
            Done
          </Button>
        </Box>
      </Footer>
    </>
  );
}
