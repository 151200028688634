import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";

import LocationImage from "../../../../components/TablePro/components/warehouse/LocationImage";
import MobileLocationImage from "../../../../components/TablePro/components/warehouse/MobileLocationImage";

export default function LocationInfo({ isMobileLocation, item }) {
  const formatDimensions = dimensionsArray => {
    if (!dimensionsArray || dimensionsArray.length === 0) return "N/A";
    const { width, depth, height } = dimensionsArray[0];
    return `${width} x ${depth} x ${height}`;
  };

  const ImageComponent = isMobileLocation ? MobileLocationImage : LocationImage;
  const images = isMobileLocation ? item.mobileLocationImages : item.warehouseLocationImages;

  return (
    <Paper sx={{ p: 2 }}>
      <Typography fontWeight={500} fontSize={20} mb={1.5}>
        Location Info
      </Typography>
      <Grid display="flex" alignItems="flex-start">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ImageComponent id={item.id} images={images} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  label="Location Name"
                  value={item?.locationName}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  label="Location Type"
                  value={item?.locationType}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  label="Zone"
                  value={item?.zone}
                />
              </Grid>
              {/*
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  label="Aisle (Row)"
                  value={item?.aisle}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  label="Rack (Bay)"
                  value={item?.rack}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  label="Shelf (Level)"
                  value={item?.shelf}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  label="Slot (Bin)"
                  value={item?.slot}
                />
              </Grid>
              */}
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  label='Dimensions W" x D" x H"'
                  value={formatDimensions(item?.dimensions)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  label="Square Footage"
                  value={item?.sqFtOfSlot}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
