import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";

import Views from "./Views";

export default function Toolkit({ view, refetch, changeView }) {
  return (
    <Box display="flex">
      <Views view={view} changeView={changeView} />
      <Box mr={1} />
      <Button sx={{ textTransform: "none" }} size="small" onClick={refetch}>
        <RefreshIcon color="action" />
        <Box mr={1} />
        <Typography fontWeight={500} color="text.secondary" variant="body2">
          Refresh
        </Typography>
      </Button>
    </Box>
  );
}
