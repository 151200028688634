import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Formik, Form, FieldArray } from "formik";
import { object, string, array } from "yup";
import { uniqueId } from "lodash";

import AdvancedSearchCriteria from "src/components/AdvancedSearch/AdvancedSearchCriteria";
import parseInitialValue from "src/utils/parseAdvancedSearchInitialValue";

const MAX_CRITERIA = 2;

const styles = {
  card: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 3,
    borderColor: theme => theme.palette.grey[400],
  },
  andText: {
    marginBottom: 2,
    color: theme => theme.palette.primary.main,
    fontWeight: "bold",
  },
  cardActions: {
    padding: 2,
    justifyContent: "space-between",
  },
  cancelButton: {
    textTransform: "none",
    marginLeft: 1.5,
    color: theme => theme.palette.text.secondary,
  },
  searchButton: {
    textTransform: "none",
    marginLeft: 1.5,
  },
};

const validationSchema = object().shape({
  criteria: array().of(
    object().shape({
      field: string().required("Field is required"),
      text: string().required("Text is required"),
      matchType: string().required("Match type is required"),
    })
  ),
});

const AdvancedSearch = ({ onCancel, onSearch, initialValue, className }) => {
  const initialValues = {
    criteria: parseInitialValue(initialValue),
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        const searchString = values.criteria
          .map(criterion => `${criterion.field} ${criterion.matchType} ${criterion.text}`)
          .join(" and ");
        onSearch(searchString);
      }}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <FieldArray name="criteria">
            {({ push, remove }) => (
              <Card variant="outlined" className={className} sx={styles.card}>
                <CardContent>
                  {values.criteria.map((criterion, index) => (
                    <Fragment key={criterion.id}>
                      {index > 0 && <Typography sx={styles.andText}>AND</Typography>}
                      <AdvancedSearchCriteria index={index} removeCriterion={remove} />
                    </Fragment>
                  ))}
                </CardContent>
                <CardActions sx={styles.cardActions}>
                  {values.criteria.length < MAX_CRITERIA ? (
                    <Button
                      color="primary"
                      onClick={() =>
                        push({
                          id: uniqueId("criteria_"),
                          field: "",
                          text: "",
                          matchType: "~",
                        })
                      }
                    >
                      Add Criteria
                    </Button>
                  ) : (
                    <Box />
                  )}
                  <Box>
                    <Button sx={styles.cancelButton} variant="outlined" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      sx={styles.searchButton}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      Search
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

AdvancedSearch.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  className: PropTypes.string,
};

export default AdvancedSearch;
