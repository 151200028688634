import React, { useState, useMemo } from "react";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import { useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";

import { pricer } from "../../../utils/money";
import { useShopping } from "../../../contexts/shopping";
import useMutation from "../../../hooks/useMutation";
import InventoryItemAddress from "../../../views/shopping/Cart/InventoryItemAddress";
import AssetQuantityField from "../../../views/shopping/ProjectAssets/AssetQuantityField";
import { getTimeOptions } from "../../../utils/cart";
import { UPDATE_SHOPPING_INVENTORY_ITEM_MUTATION } from "../../../constants/graphql/mutations";
import StartTimeTooltip from "./StartTimeTooltip";
import Dimensions from "../../inventory/requests/Dimensions";
import ItemId from "src/views/shopping/Orders/ItemId";
import ConfirmDeleteModal from "../../../components/images/ConfirmDeleteModal";
import useAdmin from "../../../hooks/useAdmin";

export function getInventoryFields(inventoryItem) {
  return {
    projectId: inventoryItem.projectId,
    inventoryItemId: inventoryItem.id,
    quantity: inventoryItem.quantity,
    deliveryDate: inventoryItem.deliveryDate,
    deliveryTime: inventoryItem.deliveryTime,
    building: inventoryItem.building || "",
    floor: inventoryItem.floor || "",
    room: inventoryItem.room || "",
    comment: inventoryItem.comment || "",
    location: inventoryItem.location || "",
    street: inventoryItem.street || "",
    city: inventoryItem.city || "",
    zip: inventoryItem.zip || "",
    poNumber: inventoryItem.poNumber || "",
    idsrNumber: inventoryItem.idsrNumber || "",
    eocNumber: inventoryItem.eocNumber || "",
  };
}

export function addInventoryFields(inventoryItem) {
  return {
    projectId: inventoryItem.projectId,
    inventoryItemId: inventoryItem.id,
    assetId: inventoryItem.assetId,
    quantity: inventoryItem.quantity,
    deliveryDate: inventoryItem.deliveryDate,
    deliveryTime: inventoryItem.deliveryTime,
    building: inventoryItem.building || "",
    floor: inventoryItem.floor || "",
    room: inventoryItem.room || "",
    comment: inventoryItem.comment || "",
    location: inventoryItem.location || "",
    street: inventoryItem.street || "",
    city: inventoryItem.city || "",
    zip: inventoryItem.zip || "",
    poNumber: inventoryItem.poNumber || "",
    idsrNumber: inventoryItem.idsrNumber || "",
    eocNumber: inventoryItem.eocNumber || "",
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  nestedCell: {
    paddingTop: 0,
    paddingBottom: 0,
    padding: 0,
    borderBottom: "unset",
  },
  nestedTable: {
    marginBottom: theme.spacing(2),
  },
}));

export function addHours(numOfHours, date = new Date()) {
  date.setHours(date.getHours() + numOfHours);
  return date;
}

export function getImage(asset) {
  return asset?.photo || asset?.images?.length
    ? asset?.images.find(v => v.default)?.url || asset?.images[0].url
    : null;
}

export default function CartItemFields({
  disabled,
  disabledQty,
  disabledAddress,
  price,
  display,
  inventoryItem,
  asset,
  submitCounter,
  onDelete,
}) {
  const classes = useStyles();
  const { projectId } = useParams();
  const [updateInventoryItem, { loading }] = useMutation(UPDATE_SHOPPING_INVENTORY_ITEM_MUTATION);
  const { setInventoryItem, removeInventoryItem } = useShopping();
  const isAdmin = useAdmin();
  const photo = getImage(asset);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const minDate = useMemo(() => {
    return new addHours(48).toISOString().split("T")[0];
  }, []);

  const dateInputProp = isAdmin ? {} : { min: minDate };

  const handleChange = values => {
    return updateInventoryItem({
      variables: getInventoryFields({
        ...inventoryItem,
        projectId,
        assetId: asset.id,
        ...values,
      }),
      onSuccess: ({ inventoryItem: updatedInventoryItem }) => {
        updatedInventoryItem
          ? setInventoryItem(updatedInventoryItem)
          : removeInventoryItem(inventoryItem.id);
      },
    });
  };

  const handleChangeField = field => {
    return e => handleChange({ ...inventoryItem, [field]: e.target.value });
  };

  return (
    <>
      <TableRow className={classes.root} id={`cartItem-${asset.id}`}>
        {display.ii_image && (
          <TableCell>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              overflow="hidden"
              width={90}
              height={60}
              borderRadius="4px"
              bgcolor="#f4f6f8"
              style={{
                backgroundImage: photo ? `url(${photo})` : undefined,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            />
          </TableCell>
        )}
        {display.ii_item_id && (
          <TableCell>
            <ItemId asset={asset} />
          </TableCell>
        )}
        {display.ii_barcode && <TableCell>{asset.barcode}</TableCell>}
        {display.ii_description && <TableCell>{asset.description}</TableCell>}
        {display.ii_model && <TableCell>{asset.model}</TableCell>}
        {display.ii_dimensions && (
          <TableCell>
            <Dimensions dimensions={asset.dimensions} />
          </TableCell>
        )}
        {display.ii_qtyRequested && (
          <TableCell>
            <Box minWidth={140}>
              <AssetQuantityField
                disabled={disabledQty || disabled}
                size="small"
                inventoryItem={inventoryItem}
                asset={asset}
              />
            </Box>
          </TableCell>
        )}
        {display.ii_building && (
          <TableCell>
            <TextField
              disabled={disabled}
              placeholder="Building"
              defaultValue={inventoryItem.building || ""}
              size="small"
              onBlur={handleChangeField("building")}
            />
          </TableCell>
        )}
        {display.ii_floor && (
          <TableCell>
            <TextField
              disabled={disabled}
              placeholder="Floor"
              defaultValue={inventoryItem.floor || ""}
              size="small"
              onBlur={handleChangeField("floor")}
            />
          </TableCell>
        )}
        {display.ii_room && (
          <TableCell>
            <TextField
              disabled={disabled}
              placeholder="Room"
              defaultValue={inventoryItem.room || ""}
              size="small"
              onBlur={handleChangeField("room")}
            />
          </TableCell>
        )}
        {display.ii_po_number && (
          <TableCell>
            <TextField
              disabled={disabled}
              placeholder="PO Number"
              defaultValue={inventoryItem.poNumber || ""}
              size="small"
              onBlur={handleChangeField("poNumber")}
            />
          </TableCell>
        )}
        {display.ii_idsr_number && (
          <TableCell>
            <TextField
              disabled={disabled}
              placeholder="IDSR #"
              defaultValue={inventoryItem.idsrNumber || ""}
              size="small"
              onBlur={handleChangeField("idsrNumber")}
            />
          </TableCell>
        )}
        {display.ii_eoc_number && (
          <TableCell>
            <TextField
              disabled={disabled}
              placeholder="EOC"
              defaultValue={inventoryItem.eocNumber || ""}
              size="small"
              onBlur={handleChangeField("eocNumber")}
            />
          </TableCell>
        )}
        {display.ii_deliveryDate && (
          <TableCell>
            <StartTimeTooltip>
              <TextField
                disabled={disabled}
                inputProps={dateInputProp}
                value={inventoryItem.deliveryDate || ""}
                type="date"
                size="small"
                onChange={handleChangeField("deliveryDate")}
              />
            </StartTimeTooltip>
          </TableCell>
        )}
        {display.ii_deliveryTime && (
          <TableCell>
            <StartTimeTooltip>
              <FormControl fullWidth variant="filled" size="small">
                <InputLabel />
                <Select
                  disabled={disabled}
                  size="small"
                  variant="outlined"
                  value={inventoryItem.deliveryTime || ""}
                  onChange={handleChangeField("deliveryTime")}
                >
                  {getTimeOptions(inventoryItem.deliveryDate === minDate).map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StartTimeTooltip>
          </TableCell>
        )}
        {display.sc_price && <TableCell>{pricer(price)}</TableCell>}
        <TableCell>
          {!(disabled || disabledQty) && (
            <>
              <Tooltip arrow title="Remove Item">
                <IconButton onClick={() => setOpenDeleteDialog(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {openDeleteDialog && (
                <ConfirmDeleteModal
                  title="Remove Item"
                  applyLabel="Remove"
                  descr="Are you sure you want to remove the selected item from your cart?"
                  onClose={() => setOpenDeleteDialog(false)}
                  onDelete={onDelete}
                />
              )}
            </>
          )}
        </TableCell>
      </TableRow>
      {display.ii_location && (
        <TableRow className={display.ii_notes ? classes.root : ""}>
          <TableCell colSpan="22">
            <Box mt={-2}>
              <InventoryItemAddress
                error={!inventoryItem.location && submitCounter > 0}
                disabled={disabled || disabledAddress}
                hideDropdown={inventoryItem.street || inventoryItem.city || inventoryItem.zip}
                inventoryItem={inventoryItem}
                onChangeField={handleChangeField}
                onChange={handleChange}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
      {display.ii_notes && (
        <TableRow>
          <TableCell colSpan="22">
            <Box mt={-2}>
              <TextField
                disabled={disabled}
                multiline
                rows={2}
                fullWidth
                label="Notes"
                placeholder="Notes"
                defaultValue={inventoryItem.comment || ""}
                size="small"
                onBlur={handleChangeField("comment")}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
