import React from "react";
import moment from "moment/moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {CalendarPicker} from "@mui/x-date-pickers/CalendarPicker";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import EventIcon from "@mui/icons-material/Event";
import FormHelperText from "@mui/material/FormHelperText";

import TimePicker from "./TimePicker";

const dateFormat = "yyyy-MM-DDTHH:mm:ss";

const getDateTime = value => {
  const dateTime = moment(value).format(dateFormat);
  const [date, time] = dateTime?.split("T") || [];
  return { dateTime, date, time };
};

export default function DateTimePicker({
  label,
  defaultValue,
  minDate,
  error,
  helperText,
  onChange,
}) {
  const [value, setValue] = React.useState(defaultValue ? moment(defaultValue) : "");

  const handleChange = dateValue => {
    dateValue.set("second", 0);
    setValue(dateValue);
    const { dateTime, date, time } = getDateTime(dateValue);
    onChange({ dateTime, date, time });
  };

  const handleChangeDay = newValue => {
    const newDateTime = value ? newValue : newValue.set("hour", 8);
    if (minDate) {
      if (minDate > newValue) {
        newValue.set("hour", moment(minDate).get("hour") + 1);
        newValue.set("minute", 0);
      }
    }
    handleChange(newDateTime);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <PopupState variant="popover" popupId="demoPopover">
        {popupState => (
          <div>
            <FormControl fullWidth variant="outlined">
              <InputLabel shrink>{label}</InputLabel>
              <OutlinedInput
                {...bindTrigger(popupState)}
                error={error}
                helperText={helperText}
                value={value ? value.format("MM/DD/YYYY hh:mm A") : " "}
                label={label}
                fullWidth
                size="small"
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton>
                      <EventIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {error && (
                <FormHelperText error>
                  {helperText}
                </FormHelperText>
              )}
            </FormControl>
            <Popover
              {...bindPopover(popupState)}
              disableAutoFocus
              sx={{ mt: 1 }}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Box display="flex">
                <CalendarPicker
                  minDate={minDate}
                  date={value || undefined}
                  onChange={handleChangeDay}
                />
                <TimePicker minDate={minDate} value={value} onChange={handleChange} />
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </LocalizationProvider>
  );
}
