import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import { usePermissions } from "../../../contexts/permissions";
import useIsOrderDisabled from "../../../hooks/useIsOrderDisabled";
import InventoryItemModal from "./InventoryItemModal";

export default function AddAsset({ values, refetch }) {
  const [open, setOpen] = useState(false);
  const { user } = usePermissions();
  const disabled = useIsOrderDisabled(values, user.company.settings.cut_off);

  const handleClose = () => {
    setOpen(false);
    if (refetch) {
      refetch();
    }
  };

  return (
    <>
      <Box mt={2}>
        <Button disabled={disabled} variant="outlined" onClick={() => setOpen(true)}>
          Add More Items<AddIcon color={disabled ? "disabled" : "primary"} fontSize="small" />
        </Button>
      </Box>
      {open && <InventoryItemModal onClose={handleClose} />}
    </>
  );
}
