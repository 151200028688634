import React, { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";

import useAuth from "../../../../hooks/useAuth";
import useMutation from "../../../../hooks/useMutation";
import { UPDATE_INVENTORY_REQUEST_MUTATION } from "../../../../constants/graphql/mutations";
import VaStatus from "../VaStatus";
import RequestRowAssets from "./RequestRowAssets";
import OrderActions from "./OrderActions";
import { isVaSubdomain } from "../../../../utils/inventoryRequests";

const serverFormat = "YYYY-MM-DD hh:mm A";

export function getWO({ workOrderNumber, revisionNumber }) {
  return revisionNumber ? `${workOrderNumber}.${revisionNumber}` : workOrderNumber;
}

export default function InventoryRequestRow({
  inventoryRequest,
  inventoryRequestId,
  reFetch,
  display,
}) {
  const { user } = useAuth();
  const [saveInventoryRequest] = useMutation(UPDATE_INVENTORY_REQUEST_MUTATION);
  const requestUser = inventoryRequest?.user;
  const [assetsOpened, setAssetsOpened] = useState(false);
  const toggleAssetsOpened = () => setAssetsOpened(!assetsOpened);
  const wizardMode = user.company?.settings?.wizard_mode;

  const isVA = isVaSubdomain();

  const deliveryTitle = inventoryRequest.multipleAddress
    ? "Multiple delivery"
    : inventoryRequest.addressLine;

  const accessLinkTitle = requestUser.accessLink?.id
    ? requestUser.accessLink.name
      ? `${requestUser.accessLink.name}`
      : "Access link"
    : null;

  const changeStatus = useCallback(
    key => checked => {
      const input = {
        vaStatus: {
          ...inventoryRequest?.vaStatus,
          [key]: checked,
        },
      };
      return saveInventoryRequest({
        variables: { id: inventoryRequest.id, input },
      });
    },
    [saveInventoryRequest, inventoryRequest, inventoryRequestId]
  );

  const handleAllChangeInventoryItems = values => {};

  return (
    <>
      <TableRow id={`inventoryRequest-${inventoryRequest.id}`} key={inventoryRequest.id}>
        {display.order_work_order_number && (
          <TableCell>
            {isVA ? "Task Order #" : "Order #"} {getWO(inventoryRequest)}
          </TableCell>
        )}
        {display.order_requested_at && (
          <TableCell>{moment(inventoryRequest.requestedAt, serverFormat).format("lll")}</TableCell>
        )}
        {display.order_requested_by && (
          <TableCell>
            {accessLinkTitle ? (
              <Link
                component={RouterLink}
                to={`/settings/links/${requestUser.accessLink.id}/edit`}
                variant="body2"
              >
                {inventoryRequest.email || accessLinkTitle}
              </Link>
            ) : inventoryRequest.email ? (
              inventoryRequest.email
            ) : (
              <Link component={RouterLink} to={`/user/${requestUser.id}/edit`} variant="body2">
                {requestUser.email}
              </Link>
            )}
          </TableCell>
        )}
        {display.order_start_date && <TableCell>{inventoryRequest.startDate}</TableCell>}
        {display.order_delivery_date && <TableCell>{inventoryRequest.deliveryDate}</TableCell>}
        {display.order_destination && (
          <TableCell>
            {wizardMode ? (
              <>
                {inventoryRequest.comment ? (
                  <Tooltip title={inventoryRequest.comment}>
                    <a href="src/views/inventory/requests/orders/RequestRow#">{deliveryTitle}</a>
                  </Tooltip>
                ) : (
                  deliveryTitle
                )}
              </>
            ) : (
              inventoryRequest.location?.split(",")[0]
            )}
          </TableCell>
        )}
        {display.order_pdf_report && (
          <TableCell>
            <a
              target="_blank"
              href={`/inventory_request_pdfs/${inventoryRequest.id}?token=${user.token}`}
            >
              Generate
            </a>
          </TableCell>
        )}
        {display.order_va_status && (
          <TableCell>
            <VaStatus
              inventoryRequest={inventoryRequest}
              changeStatus={changeStatus}
              reFetch={reFetch}
            />
          </TableCell>
        )}
        {display.order_actions && (
          <TableCell align="right">
            <OrderActions
              inventoryRequest={inventoryRequest}
              toggleAssets={toggleAssetsOpened}
              reFetch={reFetch}
              updateInventoryRequest={console.log}
              removeInventoryItem={console.log}
              changeAllInventoryItems={handleAllChangeInventoryItems}
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        {assetsOpened && (
          <RequestRowAssets
            assetsOpened={assetsOpened}
            inventoryRequest={inventoryRequest}
            reFetch={reFetch}
          />
        )}
      </TableRow>
    </>
  );
}
