import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import ProjectSettings from "../../views/settings/ProjectSettings";
import useAuth from "../../hooks/useAuth";

export default function CustomerFieldsModal({
  storageEnabled,
  originEnabled,
  destinationEnabled,
  ordersTableFieldsEnabled,
  reservationsTableFieldsEnabled,
  onClose,
}) {
  const { user } = useAuth();
  const { settings } = user.company;

  return (
    <Dialog open maxWidth="xl" onClose={onClose}>
      <Box sx={{ bgcolor: "#f4f6f8" }}>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box width={40} />
          <Typography fontSize={26} fontWeight={500}>
            {settings.filter_field2} Fields
          </Typography>
          <IconButton sx={{ mr: 2 }} color="textPrimary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex">
          <Box sx={{ overflowY: "hidden" }}>
            <ProjectSettings
              storageEnabled={storageEnabled}
              originEnabled={originEnabled}
              destinationEnabled={destinationEnabled}
              ordersTableFieldsEnabled={ordersTableFieldsEnabled}
              reservationsTableFieldsEnabled={reservationsTableFieldsEnabled}
              hideTitle={false}
              onClose={onClose}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
