import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import snakeCase from "lodash/snakeCase";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import useAuth from "../../hooks/useAuth";
import { useCustomFields } from "../../contexts/customFields";
import useAssetsTable from "../../hooks/useAssetsTable";
import { useAssetsCollection } from "../../contexts/assetsCollection";
import { useFullScreen } from "../../contexts/fullScreen";
import RemoveAssets from "../../components/AssetsTable/RemoveAssets";
import useNotification from "../../hooks/notification";
import useAssetsImport from "../../hooks/useAssetsImport";
import { useUndo } from "../../contexts/undo";
import Table from "../TablePro/Table/index";
import TableSettings from "../TablePro/TableSettings";
import TableSettingsLegacy from "../TablePro/TableSettingsLegacy";
import TableSearch from "../TablePro/TableSearch";
import useTableBulk from "../TablePro/extensions/useTableBulk";
import useAssetImageModal from "../TablePro/extensions/useAssetImageModal";
import AssetsTableActions from "./AssetsTableActions";
import AssetsTableActionsLegacy from "./AssetsTableActionsLegacy";
import ReceivingWizardModal from "../../components/capture/ReceivingWizard/ReceivingWizardModal";

import MoveLocationsModal from "../modals/MoveLocationsModal/MoveLocationsModal";
import SearchScanner from "../capture/SearchScanner";
import SelectProjectAttentionModal from "../capture/SelectProjectAttentionModal";
import Undo from "./Undo";
import { useClients } from "../../contexts/clients";

export default function AssetsTable({
  clientId,
  projectId,
  size,
  order,
  fields: tableFields,
  updateSettings,
  moreActions = true,
  fieldsProps: defaultFieldsProps,
}) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { undo } = useUndo();
  const { user } = useAuth();
  const [isAttentionOpen, setIsAttentionOpen] = useState(false);
  const { fullScreen, setFullScreen } = useFullScreen();
  const toggleFullScreen = () => setFullScreen(prev => !prev);
  const { notifyError } = useNotification();
  const isMobileMode = user.company.settings.capture_mode === "mobile";
  const { settings } = user.company;
  const {
    loading,
    loadingMutation,
    setLoadingMutation,
    collection,
    setCollection,
    handeRefetch,
    onLoadMore,
    totalIds,
    totalCount,
    metadata,
    setMetadata,
    createAsset,
    updateImage,
    removeImage,
    bulkCreateImages,
    search,
    setSearch,
    bulkRemoveAssets,
    limit,
  } = useAssetsCollection();
  const loadingTable = loading || loadingMutation;
  const { handleUpdate, handleBulkUpdate } = useAssetsTable();
  const [customFields, loadingFields] = useCustomFields();
  const [disabledScroll, setDisabledScroll] = useState(false);
  const { currentProject } = useClients();

  const fields = useMemo(() => {
    if (user.company.settings.capture_mode === "mobile") {
      return tableFields.map(field => ({
        ...field,
        visible: field.alwaysVisible ? true : field.visible,
      }));
    } else {
      return tableFields.map(field => ({
        ...field,
        visible: field.alwaysVisible ? true : field.visible,
        inactive: field.system ? false : !customFields[snakeCase(field.attrName || field.id)],
      }));
    }
  }, [clientId, projectId, tableFields, customFields]);

  const { selectedIds, unSelectBulk, props: bulkProps } = useTableBulk(totalIds);

  const { ImageModal, props: photoProps } = useAssetImageModal({
    collection,
    updateImage,
    removeImage,
    bulkCreateImages,
    id: "assetId",
  });

  const fieldsProps = useMemo(
    () => ({
      BULK_CHECKBOX: bulkProps,
      // photoCarousel: photoProps,
      ...defaultFieldsProps,
    }),
    [totalIds, selectedIds, defaultFieldsProps]
  );

  const handleToggle = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    if (params.get("receiving")) {
      handeRefetch();
      history.push({ pathname, search: "" });
    } else {
      params.set("step", "details");
      params.set("receiving", "true");
      history.push({ pathname, search: params.toString() });
    }
  };

  const handleCreateAsset = () => {
    if (!projectId) {
      setIsAttentionOpen(true);
      return;
    }

    if (currentProject?.storageEnabled) {
      handleToggle();
    } else {
      setDisabledScroll(true);
      createAsset({
        variables: { projectId: projectId, input: { projectId } },
        onSuccess: () => {
          setDisabledScroll(false);
        },
        onFailure: errors => {
          notifyError(errors);
        },
      });
    }
  };

  const [openMoveModal, setOpenMoveModal] = useState(false);
  const toggleMoveModal = () => setOpenMoveModal(v => !v);

  useEffect(() => {
    const handleKeyDown = event => {
      const isCmdOrCtrl = event.metaKey || event.ctrlKey;
      if (isCmdOrCtrl && event.keyCode === 90) {
        const item = undo();
        if (item) {
          handleBulkUpdate(item[0], false);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [undo]);

  useAssetsImport(user?.id, () => {
    handeRefetch();
  });

  return (
    <div>
      {user.company.settings.capture_mode === "mobile" && metadata.totalCount >= 0 && (
        <Box mt={-0.5} pb={2} lineHeight={1}>
          {metadata.totalCount} Assets
        </Box>
      )}
      <Box display="flex" flexDirection={{ xs: "column", lg: "row" }}>
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center">
            {settings.receiving_wizard ? (
              <Box minWidth={400} bgcolor="white" borderRadius={4}>
                <SearchScanner search={search} setSearch={setSearch} />
              </Box>
            ) : (
              <TableSearch
                placeholder="Search assets"
                disabled={loadingTable}
                onChange={setSearch}
                value={search}
              />
            )}
            <Box mr={1} />
            <Button variant="outlined" color="primary" onClick={handleCreateAsset}>
              Add New
            </Button>
            <SelectProjectAttentionModal
              open={isAttentionOpen}
              onClose={() => setIsAttentionOpen(false)}
            />
          </Box>
        </Box>
        <Box flexGrow={1} mt={{ xs: 2, lg: 0 }}>
          {settings.receiving_wizard && (
            <Button variant="outlined" color="primary" onClick={toggleMoveModal}>
              <Box mr={1}>
                <LocalShippingIcon />
              </Box>
              Inventory Move
            </Button>
          )}
          {openMoveModal && <MoveLocationsModal open={openMoveModal} onClose={toggleMoveModal} />}
        </Box>
        <Box flexGrow={1} mt={{ xs: 2, lg: 0 }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {isMobileMode ? (
              <>
                <Box mr={1}>
                  <RemoveAssets
                    selectedIds={selectedIds}
                    totalCount={totalCount}
                    onCompleteBulk={unSelectBulk}
                    setDisabledScroll={setDisabledScroll}
                    bulkRemoveAssets={bulkRemoveAssets}
                  />
                </Box>
                <TableSettings
                  type="scrubber"
                  fullScreen={fullScreen}
                  toggleFullScreen={toggleFullScreen}
                  refetch={handeRefetch}
                  limit={limit}
                  size={size}
                  selectedIds={selectedIds}
                  fields={fields}
                  onChange={updateSettings}
                />
                <Box mr={1} />
                <AssetsTableActions
                  moreActions={moreActions}
                  setLoading={setLoadingMutation}
                  setCollection={setCollection}
                  setMetadata={setMetadata}
                  setDisabledScroll={setDisabledScroll}
                  refetch={handeRefetch}
                  fullScreen={fullScreen}
                  toggleFullScreen={toggleFullScreen}
                  size={size}
                  onChange={updateSettings}
                  createAsset={createAsset}
                  enableSize
                  actions={<Undo />}
                />
              </>
            ) : (
              <>
                <AssetsTableActionsLegacy
                  moreActions={moreActions}
                  setLoading={setLoadingMutation}
                  setCollection={setCollection}
                  setMetadata={setMetadata}
                  selectedIds={selectedIds}
                  onCompleteBulk={unSelectBulk}
                  totalCount={totalCount}
                  setDisabledScroll={setDisabledScroll}
                  refetch={handeRefetch}
                />
                <Box mr={1} />
                <TableSettingsLegacy
                  fullScreen={fullScreen}
                  toggleFullScreen={toggleFullScreen}
                  refetch={handeRefetch}
                  limit={limit}
                  size={size}
                  selectedIds={selectedIds}
                  fields={fields}
                  onChange={updateSettings}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box mb={2} />
      <Card>
        <Table
          fullScreen={fullScreen}
          size={size}
          disabledScroll={disabledScroll}
          totalCount={totalCount}
          order={order}
          loading={loadingTable}
          loadingFields={loadingFields}
          fields={fields}
          fieldsProps={fieldsProps}
          entities={collection}
          onLoadMore={onLoadMore}
          onChangeEntity={handleUpdate}
          onChangeFields={updateSettings}
          onFillDown={handleBulkUpdate}
          onCopyPaste={handleBulkUpdate}
        />
      </Card>
      {ImageModal}
      {params.get("receiving") && <ReceivingWizardModal onToggle={handleToggle} />}
    </div>
  );
}
