import { useFormikContext } from "formik";
import { getInitialLocation } from "../utils";
import useAuth from "src/hooks/useAuth";

export default function useAddNewLocations(warehouseLocations) {
  const { user } = useAuth();
  const { settings } = user.company;
  const { values, setFieldValue } = useFormikContext();

  const isTempLocationEnabled = settings.temp_warehouse_location;
  const tempLocation = warehouseLocations.find(
    location => location.locationName === "Temp Location"
  );

  const addNewLocations = (palletCount, quantity) => {
    const newLocations = Array.from({ length: palletCount }, () =>
      getInitialLocation(isTempLocationEnabled, tempLocation, quantity)
    );

    if (
      values.locations.length === 1 &&
      values.locations[0].new === true &&
      values.locations[0].sub_qty === 0
    ) {
      setFieldValue("locations", newLocations);
    } else {
      setFieldValue("locations", [...values.locations, ...newLocations]);
    }
  };

  return { addNewLocations };
}
