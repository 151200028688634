import React from "react";
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from "../../../hooks/useAuth";
import {FAVORITE_REPORTS_QUERY} from "../../../constants/graphql";
import ReportsTable from "./ReportsTable";
import {ClientsProvider} from "../../../contexts/clients";

export default function Reports() {
  const { user } = useAuth();
  const { settings } = user.company;
  const { data: { favoriteReports = [] } = {}, loading } = useQuery(FAVORITE_REPORTS_QUERY);

  return (
    <Paper>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={500}>{settings.filter_field2} Reports</Typography>
        <Button variant="outlined" component={Link} to="/insights" sx={{ textTransform: "none" }}>
          All Templates
        </Button>
      </Box>
      {loading ? (
        <Box py={3} display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box p={2}>
          <ClientsProvider>
            <ReportsTable favoriteReports={favoriteReports} />
          </ClientsProvider>
        </Box>
      )}
    </Paper>
  );
}
