import React from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import TotalReceivingUnitsForm from "./TotalReceivingUnitsForm";

export default function TotalReceivingUnitsModal({ onClose }) {
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose} disableTransition>
      <DialogContent>
        <DialogContentText>
          <Box mt={3} mb={4}>
            <Typography color="text.secondary" variant="h2" align="center">
              Total Receiving Units
            </Typography>
          </Box>
          <Box mx={{ xs: -2, sm: 0 }}>
            <TotalReceivingUnitsForm onClose={onClose} />
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
