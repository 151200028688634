import { isVaSubdomain } from "../../utils/inventoryRequests";

function getWO({ workOrderNumber, revisionNumber }) {
  return revisionNumber ? `${workOrderNumber}.${revisionNumber}` : workOrderNumber;
}

export function getTitle(item) {
  const isVA = isVaSubdomain();

  return `${item.designer ? item.designer.split(" ")[0] : ""} ${
    isVA ? "Task Order #" : "Order #"
  } ${getWO(item)}`;
}
