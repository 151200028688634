import React from "react";
import { useFormikContext } from "formik";
import {
  isElectricalInfoEnabled,
  isMechanicalInfoEnabled,
  isOtherInfoEnabled,
  isPlumbingInfoEnabled,
} from "src/utils/form";
import Grid from "@mui/material/Grid";
import FieldsIdentifiers from "src/views/capture/FieldsIdentifiers";
import { SustainabilitySheetProvider } from "src/views/capture/SustainabilitySheetContext";
import FieldsDescriptive from "src/views/capture/FieldsDescriptive";
import FieldsStorage from "src/views/capture/FieldsStorage";
import FieldsOrigin from "src/views/capture/FieldsOrigin";
import FieldsDestination from "src/views/capture/FieldsDestination";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ElectricalInfoFields from "src/components/form/fields/ElectricalInfoFields";
import PlumbingInfoFields from "src/components/form/fields/PlumbingInfoFields";
import MechanicalInfoFields from "src/components/form/fields/MechanicalInfoFields";
import OtherInfoFields from "src/components/form/fields/OtherInfoFields";
import { useCustomFields as useCustomProjectFields } from "src/contexts/customFields";
import useMutation from "src/hooks/useMutation";
import { ADD_ASSET_IMAGE_MUTATION } from "src/constants/graphql";
import { getRestockTotal } from "src/components/modals/InventoryTransactionMultipleModal/Fields";
import { UPDATE_SHIPMENT_ITEMS_MUTATION } from "src/constants/graphql/mutations";
import { GET_SHIPMENT_QUERY } from "src/constants/graphql/queries";
import useQueryParams from "src/hooks/useQueryParams";

export default function FormFields({
  type,
  disabledForm,
  colorsOptions = [],
  manufacturersOptions = [],
  asset,
  updateAsset,
  setErrors,
  shipmentItem,
}) {
  const params = useQueryParams();
  const shipmentId = params.get("id");
  const shipmentItemId = params.get("shipmentItemId");
  const [createImage] = useMutation(ADD_ASSET_IMAGE_MUTATION);
  const [display] = useCustomProjectFields();
  const { setFieldValue } = useFormikContext();
  const isElectricalInfoSectionEnabled = isElectricalInfoEnabled(display);
  const isPlumbingInfoSectionEnabled = isPlumbingInfoEnabled(display);
  const isMechanicalInfoSectionEnabled = isMechanicalInfoEnabled(display);
  const isOtherInfoSectionEnabled = isOtherInfoEnabled(display);
  const [updateShipmentItem] = useMutation(UPDATE_SHIPMENT_ITEMS_MUTATION, {
    refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id: shipmentId } }],
  });

  const handleUpdateAssetField = field => (value, { onSuccess, onFailure } = {}) => {
    if (field === "barcode") {
      setFieldValue(field, value);
    }
    updateAsset({
      variables: { id: asset.id, input: { [field]: value } },
      onSuccess,
      onFailure: errors => {
        setErrors(errors);
        onFailure && onFailure(errors);
      },
    });
  };

  const handleRestockInventory = (data, values) => {
    const restockTotal = getRestockTotal(values.locations);
    const quantity = shipmentItem.quantity + restockTotal;
    setFieldValue("availableQuantity", data.asset.availableQuantity);
    setFieldValue("shipmentItemQuantity", quantity);
    updateShipmentItem({
      variables: {
        id: shipmentItemId,
        quantity,
      },
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FieldsIdentifiers
            fullWidth
            display={display}
            type={type}
            disabledForm={disabledForm}
            asset={asset}
            createImage={createImage}
            handleUpdateAssetField={handleUpdateAssetField}
          />
        </Grid>
        <Grid item xs={12}>
          <SustainabilitySheetProvider>
            <FieldsDescriptive
              fullWidth
              display={display}
              type={type}
              asset={asset}
              disabledForm={disabledForm}
              colorsOptions={colorsOptions}
              manufacturersOptions={manufacturersOptions}
            />
          </SustainabilitySheetProvider>
        </Grid>
        <Grid item xs={12}>
          <FieldsStorage
            fullWidth
            display={display}
            type={type}
            disabledForm={disabledForm}
            asset={asset}
            colorsOptions={colorsOptions}
            manufacturersOptions={manufacturersOptions}
            onRestockInventory={handleRestockInventory}
            enableShipmentItemQuantity
          />
        </Grid>
        <Grid item xs={12}>
          <FieldsOrigin
            fullWidth
            display={display}
            asset={asset}
            disabledForm={disabledForm}
            colorsOptions={colorsOptions}
            manufacturersOptions={manufacturersOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldsDestination
            display={display}
            type={type}
            asset={asset}
            disabledForm={disabledForm}
            colorsOptions={colorsOptions}
            manufacturersOptions={manufacturersOptions}
          />
        </Grid>
        {isElectricalInfoSectionEnabled && (
          <Grid item xs={12}>
            <Card sx={{ overflow: "visible" }}>
              <CardContent>
                <ElectricalInfoFields fullWidth display={display} disabledForm={disabledForm} />
              </CardContent>
            </Card>
          </Grid>
        )}
        {isPlumbingInfoSectionEnabled && (
          <Grid item xs={12}>
            <Card sx={{ overflow: "visible" }}>
              <CardContent>
                <PlumbingInfoFields fullWidth display={display} disabledForm={disabledForm} />
              </CardContent>
            </Card>
          </Grid>
        )}
        {isMechanicalInfoSectionEnabled && (
          <Grid item xs={12}>
            <Card sx={{ overflow: "visible" }}>
              <CardContent>
                <MechanicalInfoFields fullWidth display={display} disabledForm={disabledForm} />
              </CardContent>
            </Card>
          </Grid>
        )}
        {isOtherInfoSectionEnabled && (
          <Grid item xs={12}>
            <Card sx={{ overflow: "visible" }}>
              <CardContent>
                <OtherInfoFields fullWidth display={display} disabledForm={disabledForm} />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
}
