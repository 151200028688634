import React, { useState } from "react";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import IconButton from "@mui/material/IconButton";
import PalletCountAssistant from "./PalletCountAssistant";
import useAddNewLocations from "../hooks/useAddNewLocations";

export default function PalletButton({ step, setStep, warehouseLocations }) {
  const [state, setState] = useState({});
  const { addNewLocations } = useAddNewLocations(warehouseLocations);

  const handleNext = ({ done, ...data } = {}) => {
    const newState = { ...state, ...data };
    setState(newState);

    if (done) {
      const { palletCount, boxesCount, piecesPerBox } = newState;
      const quantity = boxesCount * piecesPerBox;
      addNewLocations(palletCount, quantity);
      handleClose();
    } else {
      setStep(prev => prev + 1);
    }
  };

  const handleChange = values => {
    setState(prev => ({ ...prev, ...values }));
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
  };

  const handleClose = () => {
    setState({});
    setStep(-1);
  };

  const handleOpenAssistant = () => setStep(0);

  return (
    <>
      <IconButton
        sx={{ ml: 1 }}
        onClick={handleOpenAssistant}
        aria-label="Open pallet count assistant"
      >
        <BuildCircleOutlinedIcon sx={{ fontSize: 36 }} />
      </IconButton>
      {step >= 0 && (
        <PalletCountAssistant
          state={state}
          step={step}
          onClose={handleClose}
          onNext={handleNext}
          onBack={handleBack}
          onChange={handleChange}
        />
      )}
    </>
  );
}
