export const basicAdvancedSearch = [
  {
    label: "Available Quantity",
    value: "available_quantity"
  },
  {
    label: "Barcode Number",
    value: "barcode"
  },
  {
    label: "Category",
    value: "category"
  },
  {
    label: "Color",
    value: "color"
  },
  {
    label: "Condition",
    value: "condition"
  },
  {
    label: "Created On",
    value: "created_at"
  },
  {
    label: "Description",
    value: "description"
  },
  {
    label: "Description Comments",
    value: "description_comments"
  },
  {
    label: "Dimensions",
    value: "dimensions"
  },
  {
    label: "Disposition",
    value: "disposition"
  },
  {
    label: "Finish",
    value: "finish"
  },
  {
    label: "Manufacturer",
    value: "manufacturer"
  },
  {
    label: "Minimum Quantity",
    value: "minimum_quantity"
  },
  {
    label: "Model",
    value: "model"
  },
  {
    label: "Origin Building",
    value: "existing_building"
  },
  {
    label: "Origin Comments",
    value: "origin_comments"
  },
  {
    label: "Origin Cubby Number",
    value: "origin_cubby_number"
  },
  {
    label: "Origin Employee Name",
    value: "origin_employee_name"
  },
  {
    label: "Origin Floor",
    value: "existing_floor"
  },
  {
    label: "Origin Quantity",
    value: "quantity"
  },
  {
    label: "Origin Room Name",
    value: "existing_room"
  },
  {
    label: "Origin Room Number",
    value: "origin_room_number"
  },
  {
    label: "Subcategory",
    value: "subcategory"
  },
  {
    label: "Unit Cost",
    value: "unit_cost"
  },
  {
    label: "Warranty",
    value: "warranty"
  },
  {
    label: "Weight",
    value: "weight"
  }
];

export const medicalAdvancedSearch = [
  {
    label: "Amps / Watts",
    value: "amps_watts"
  },
  {
    label: "ASE",
    value: "ase"
  },
  {
    label: "Bench=(B) Floor=(F) Hood=(H) Wall=W BSC=(BC)",
    value: "bench_floor_hood_wall_bsc"
  },
  {
    label: "BMS",
    value: "bms"
  },
  {
    label: "BTU (Output)",
    value: "btu"
  },
  {
    label: "CFM",
    value: "cfm"
  },
  {
    label: "Data Port (Qty)",
    value: "data_port_qty"
  },
  {
    label: "Dedicated Power",
    value: "dedicated_power"
  },
  {
    label: "Department Name",
    value: "department_name"
  },
  {
    label: "Destination Building",
    value: "new_building"
  },
  {
    label: "Destination Comments",
    value: "destination_comments"
  },
  {
    label: "Destination Employee Name",
    value: "destination_employee_name"
  },
  {
    label: "Destination Floor",
    value: "new_floor"
  },
  {
    label: "Destination Quantity",
    value: "destination_quantity"
  },
  {
    label: "Destination Room Name",
    value: "new_room"
  },
  {
    label: "Destination Room Number",
    value: "destination_room_number"
  },
  {
    label: "DI",
    value: "di"
  },
  {
    label: "EXH Ceiling w/ Blast Gate",
    value: "exh_ceiling_blast_gate"
  },
  {
    label: "EXH Header",
    value: "exh_header"
  },
  {
    label: "EXH Other",
    value: "exh_other"
  },
  {
    label: "EXH SNORKEL ARM (Mechanical)",
    value: "exh_snorkel_arm_mechanical"
  },
  {
    label: "EXH SNORKEL ARM (Plumbing)",
    value: "exh_snorkel_arm_plumbing"
  },
  {
    label: "Exhaust",
    value: "exhaust"
  },
  {
    label: "Floor Drain",
    value: "floor_drain"
  },
  {
    label: "Floor Sink",
    value: "floor_sink"
  },
  {
    label: "HERTZ",
    value: "hertz"
  },
  {
    label: "HSE CO2",
    value: "hse_co"
  },
  {
    label: "HSE VAC",
    value: "hse_vac"
  },
  {
    label: "ICW",
    value: "icw"
  },
  {
    label: "IHW",
    value: "ihw"
  },
  {
    label: "Install responsibility",
    value: "install_responsibility"
  },
  {
    label: "Lab Name",
    value: "lab_name"
  },
  {
    label: "List EXH Type",
    value: "list_exh_type"
  },
  {
    label: "Local Gas Tanks",
    value: "local_gas_tanks"
  },
  {
    label: "Local VAC (Pump)",
    value: "local_vac_pump"
  },
  {
    label: "Move Notes",
    value: "move_notes"
  },
  {
    label: "Nema Config",
    value: "nema_config"
  },
  {
    label: "Normal Power",
    value: "normal_power"
  },
  {
    label: "Other Utility",
    value: "other_utility"
  },
  {
    label: "PCW (Return)",
    value: "pcw_return"
  },
  {
    label: "PCW (Supply)",
    value: "pcw_supply"
  },
  {
    label: "Phase",
    value: "phase"
  },
  {
    label: "Plug Connection",
    value: "plug_connection"
  },
  {
    label: "Plug Quantity",
    value: "plug_quantity"
  },
  {
    label: "PO Number",
    value: "po_number"
  },
  {
    label: "Price",
    value: "price"
  },
  {
    label: "Expected to Receive Quantity",
    value: "quantity_ordered"
  },
  {
    label: "RO",
    value: "ro"
  },
  {
    label: "Siesmic Anchor REQ'D",
    value: "siesmic_anchor_required"
  },
  {
    label: "Solve Waste Coll",
    value: "solve_waste_coll"
  },
  {
    label: "Standby Power",
    value: "standby_power"
  },
  {
    label: "Standby Power (SB)",
    value: "standby_power_sb"
  },
  {
    label: "Storage Location(s)",
    value: "rack_locations"
  },
  {
    label: "TSS Number",
    value: "tss_number"
  },
  {
    label: "UPS",
    value: "ups"
  },
  {
    label: "VAC PUMP VENT (Mechanical)",
    value: "vac_pump_vent_mechanical"
  },
  {
    label: "VAC PUMP VENT (Plumbing)",
    value: "vac_pump_vent_plumbing"
  },
  {
    label: "Vendor REQ'D (Yes or No)",
    value: "vendor_required"
  },
  {
    label: "Volts",
    value: "volts"
  }
];
