export const introspectionQueryResultData = {
  __schema: {
    types: [
      {
        kind: "UNION",
        name: "WarehouseLocationType",
        possibleTypes: [
          { name: "WarehouseLocation" },
          { name: "MobileWarehouseLocation" }
        ]
      }
    ]
  }
}; 