import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import WidgetCard from "src/views/warehouse/WarehouseHeader/WidgetCard";

export default function WarehouseName({ warehouse, changeWarehouse }) {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(elementRef.current.offsetWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box ref={elementRef} height="100%">
      <WidgetCard
        value={
          <Typography
            fontSize={{ xs: 16, sm: 18, md: 24 }}
            fontWeight={600}
            sx={{
              width: { xs: `${width - 20}px`, md: `${width - 110}px` },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "inline-block",
            }}
          >
            {warehouse.name}
          </Typography>
        }
        label={
          <Box sx={{ cursor: "pointer", color: "#1976d2" }} onClick={() => changeWarehouse()}>
            {warehouse.warehouseId}
          </Box>
        }
        icon={
          <Box display="inline-block" bgcolor="#e3f5f8" p={3} borderRadius="50%">
            <WarehouseOutlinedIcon htmlColor="#007aff" />
          </Box>
        }
      />
    </Box>
  );
}
