import React from "react";
import { Formik, Form } from "formik";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import ScannerInput from "../form/ScannerInput";

export default function SearchScanner({ value, setSearch }) {
  return (
    <Formik
      enableReinitialize
      initialValues={{ search: value }}
      onSubmit={values => setSearch(values.search)}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <ScannerInput
            name="search"
            label="Search"
            onDetected={setSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {values.search && (
                    <IconButton
                      onClick={() => {
                        setSearch("");
                        setFieldValue("search", "");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Form>
      )}
    </Formik>
  );
}
