import React from "react";
import { useFormikContext } from "formik";
import Box from "@mui/material/Box";
import FormFields from "./FormFields";
import { useAssets } from "src/contexts/assets";
import LoadingProvider from "./LoadingProvider";
import FormActions from "./FormActions";

export default function FormPage({
  currentAsset,
  shipmentItem,
  type,
  removing,
  toggleRemoveItem,
  shipmentId,
}) {
  const { setErrors, errors } = useFormikContext();
  const { loadingMutation, updateAsset, createImage } = useAssets();

  return (
    <LoadingProvider>
      <form
        id="assetForm"
        className="d-flex flex-column flex-grow-1 justify-content-between justify-content-md-start"
        style={{ position: "relative" }}
      >
        <FormFields
          type={type}
          asset={currentAsset}
          loading={loadingMutation}
          updateAsset={updateAsset}
          createImage={createImage}
          setErrors={setErrors}
          errors={errors}
          shipmentItem={shipmentItem}
        />
        <Box pt={10} />
        <FormActions
          shipmentId={shipmentId}
          removing={removing}
          toggleRemoveItem={toggleRemoveItem}
        />
      </form>
    </LoadingProvider>
  );
}
