import React, { createContext, useCallback, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useLocation, useHistory } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

const OrdersContext = createContext(null);

export const OrdersProvider = ({ queryKey, children, query, limit = 20 }) => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const wizardMode = user.company.settings.wizard_mode;
  const searchParams = new URLSearchParams(location.search);

  const type = useMemo(() => {
    if (location.pathname.includes("reserved") || location.pathname.includes("reservations")) {
      return "reservations";
    }
    if (location.pathname.includes("outstanding_orders")) {
      return "outstanding_orders";
    }
    return "orders";
  }, [location.pathname]);

  const search = searchParams.get("q") || "";
  const page = parseInt(searchParams.get("page")) || 1;
  const sortParam = searchParams.get("sort");
  const [sortField, sortDirection] = sortParam ? sortParam.split(":") : [null, null];

  const sortBy = useMemo(() => {
    if (sortField && sortDirection) {
      return [{ field: sortField, sortDirection }];
    }
    if (location.pathname.includes("outstanding_orders")) {
      return wizardMode
        ? { field: "start_date", sortDirection: "asc" }
        : { field: "delivery_date", sortDirection: "asc" };
    }
    return { field: "work_order_number", sortDirection: "desc" };
  }, [wizardMode, location.pathname, sortField, sortDirection]);

  const { data: { [queryKey]: { collection = [], metadata = {} } = {} } = {}, ...rest } = useQuery(
    query,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: { type, search, limit, page, sortBy },
    }
  );

  const sort = useMemo(() => ({ column: sortField, order: sortDirection }), [
    sortField,
    sortDirection,
  ]);

  const onChangePage = useCallback(
    page => {
      const searchParams = new URLSearchParams(location.search);
      if (page > 1) {
        searchParams.set("page", page);
      } else {
        searchParams.delete("page");
      }
      history.push({ search: searchParams.toString() });
    },
    [history, location.search]
  );

  const onSort = useCallback(
    column => {
      const newSearchParams = new URLSearchParams(location.search);
      const newSort =
        sort?.column === column
          ? { field: column, sortDirection: sort.order === "asc" ? "desc" : "asc" }
          : { field: column, sortDirection: "asc" };

      newSearchParams.set("sort", `${newSort.field}:${newSort.sortDirection}`);
      history.push({ search: newSearchParams.toString() });
    },
    [history, location.search, sort]
  );

  const value = {
    ...rest,
    collection,
    metadata,
    sort,
    onChangePage,
    onSort,
  };

  return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
};

export const useOrders = () => {
  const context = React.useContext(OrdersContext);
  if (!context) {
    throw new Error("useOrders must be used within a OrdersProvider");
  }
  return context;
};
