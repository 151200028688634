import React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";

const SwitchCameraButton = ({ onClick }) => (
  <ButtonBase
    data-id="switch-camera"
    onClick={onClick}
    style={{ position: "absolute", left: 15, top: 15, zIndex: 99 }}
  >
    <FlipCameraIosIcon sx={{ color: "white" }} fontSize="large" />
  </ButtonBase>
);

export default SwitchCameraButton;
