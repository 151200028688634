import React, { useCallback, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import groupBy from "lodash/groupBy";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import isEqual from "lodash/isEqual";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "@apollo/react-hooks";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import SingleDeliveryFields from "../../shopping/Cart/SingleDeliveryFields";
import { useCustomFields } from "../../../contexts/customFields";
import { validationSchema } from "../../shopping/Cart/CartForm";
import {
  UPDATE_INVENTORY_ITEM_MUTATION,
  UPDATE_INVENTORY_REQUEST_MUTATION,
} from "../../../constants/graphql/mutations";
import useMutation from "../../../hooks/useMutation";
import useNotification from "../../../hooks/notification";
import { usePermissions } from "../../../contexts/permissions";
import { getLocationNotes } from "../../../views/shopping/Cart/CartFormWizard";
import AddAsset from "../../../views/inventory/requests/AddAsset";
import LocationNotes from "../../../views/shopping/Cart/WizardCheckout/LocationNotes";
import { isStartDateGreaterEndDate } from "../../shopping/Cart/WizardCheckout/Step2";
import { isVaSubdomain } from "../../../utils/inventoryRequests";
import { getInventoryFields } from "../../../views/inventory/requests/CartItemFields";
import CartItems from "./CartItems";
import { INVENTORY_REQUEST_QUERY } from "../../../constants/graphql/queries";
import Attachments from "./Attachments";
import Header from "../header/Header";
import Status from "../header/Status";
import useAdmin from "../../../hooks/useAdmin";

export default function InventoryRequestModal({
  id,
  updateInventoryRequest,
  changeAllInventoryItems,
  removeInventoryItem,
  updateInventoryItem,
  onClose,
}) {
  const { user } = usePermissions();
  const [submitCounter, setSubmitCounter] = useState(0);
  const { notifySuccess, notifyError } = useNotification();
  const [display] = useCustomFields();
  const [updateItem] = useMutation(UPDATE_INVENTORY_ITEM_MUTATION);
  const [saveInventoryRequest] = useMutation(UPDATE_INVENTORY_REQUEST_MUTATION);
  const emailIsRequired = !!user.accessLink?.id;
  const isAdmin = useAdmin();

  const {
    loading,
    data: { inventoryRequest = {} } = {},
    refetch,
  } = useQuery(INVENTORY_REQUEST_QUERY, { variables: { id } });

  const locationNotes = Array.isArray(inventoryRequest.locationNotes)
    ? inventoryRequest.locationNotes
    : [];
  const wizardMode = user.company?.settings?.wizard_mode;
  const initialValues = useMemo(
    () => ({
      poNumber: inventoryRequest.poNumber,
      email: inventoryRequest.email,
      ccEmails: inventoryRequest.ccEmails,
      requirements: inventoryRequest.requirements,
      comment: inventoryRequest.comment || "",
      designer: inventoryRequest.designer,
      startDate: inventoryRequest.startDate,
      startTime: inventoryRequest.startTime,
      endDate: inventoryRequest.endDate,
      endTime: inventoryRequest.endTime,
      contactName: inventoryRequest.contactName,
      contactPhoneNumber: inventoryRequest.contactPhoneNumber,
      resources: inventoryRequest.resources,
      street: inventoryRequest.street || "",
      city: inventoryRequest.city || "",
      zipCode: inventoryRequest.zipCode || "",
      location: inventoryRequest.location || "",
      deliveryDate: inventoryRequest.deliveryDate || "",
      disposition: inventoryRequest.disposition || "",
      members: inventoryRequest.members || [],
      locationNotes: Object.fromEntries(locationNotes.map(v => [v.location, v.note])),
      locationOrder: Object.fromEntries(locationNotes.map(v => [v.location, v.position])),
      inventoryItems: inventoryRequest.inventoryItems,
    }),
    [inventoryRequest, locationNotes]
  );

  // Uses cutoff window from company settings to disable comments, location notes
  const startDate = new Date(`${initialValues.startDate}T${initialValues.startTime}`);
  const now = new Date();
  const fieldDisabled = isAdmin
    ? undefined
    : user.company.settings.cut_off && user.company.settings.cut_off !== "0"
    ? now.setHours(now.getHours() + parseInt(user.company.settings.cut_off, 10)) > startDate
    : false;

  const handleUpdateInventoryRequest = useCallback(
    async ({ inventoryItems, ...values }) => {
      if (wizardMode && !inventoryItems.every(v => v.location)) {
        setSubmitCounter(v => v + 1);
        return notifyError("Location required");
      }

      if (isStartDateGreaterEndDate(values)) {
        return notifyError("End date must be after start date");
      }

      const { locationNotes, locationOrder = {}, ...input } = values;
      const locations = Object.entries(groupBy(inventoryItems, "location")).map(
        ([v]) => v.split(",")[0]
      );
      if (inventoryItems.length === 0) {
        input.locationNotes = Object.entries(locationNotes).map(([location, note]) => ({
          location,
          note,
        }));
      } else if (locationNotes) {
        locations.forEach(value => {
          if (!locationNotes[value]) {
            locationNotes[value] = "";
          }
        });
        input.locationNotes = getLocationNotes(locationNotes, locationOrder).filter(v =>
          locations.includes(v.location)
        );
      }

      await Promise.all(
        inventoryItems
          .filter((v, index) => !isEqual(v, inventoryRequest.inventoryItems[index]))
          .map(inventoryItem => {
            const variables = {
              id: inventoryItem.id,
              ...getInventoryFields({
                ...inventoryItem,
                projectId: inventoryItem.project.id,
                assetId: inventoryItem.asset.id,
              }),
            };
            return updateItem({ variables });
          })
      );

      saveInventoryRequest({
        variables: { id: inventoryRequest.id, input },
        onSuccess: ({ inventoryRequest: updatedInventoryRequest }) => {
          updateInventoryRequest(inventoryRequest.id, updatedInventoryRequest);
          notifySuccess("Order successfully updated");
        },
      });
    },
    [
      saveInventoryRequest,
      inventoryRequest,
      updateInventoryRequest,
      notifySuccess,
      notifyError,
      isAdmin,
      initialValues,
    ]
  );

  const changeStatus = useCallback(
    key => checked => {
      const input = {
        vaStatus: {
          ...inventoryRequest?.vaStatus,
          [key]: checked,
        },
      };
      saveInventoryRequest({
        variables: { id: inventoryRequest.id, input },
        onSuccess: () => {
          refetch();
        },
      });
    },
    [refetch, inventoryRequest]
  );

  const isVA = isVaSubdomain(); // Check if it's a VA subdomain

  const title = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      width="100%"
    >
      <Typography variant="h2" color="textPrimary">
        {isVA ? "Edit Task Order" : "Edit Order"}
      </Typography>
      <IconButton onClick={onClose} size="small" sx={{ position: "absolute", right: 0, top: 0 }}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

  if (loading) {
    return (
      <Dialog open fullWidth maxWidth="lg" onClose={onClose} aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {[...Array(14)].map((_, i) => (
              <Grid item xs={12} md={6} key={i}>
                <Skeleton variant="rectangular" height={40} />
              </Grid>
            ))}
            {[...Array(3)].map((_, i) => (
              <Grid item xs={12} key={`skeleton-${i}`}>
                <Skeleton variant="rectangular" height={120} />
              </Grid>
            ))}
          </Grid>
          <Box mt={2} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open fullWidth maxWidth="lg" onClose={onClose} aria-labelledby="dialog-title">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema({ email: emailIsRequired })}
        onSubmit={handleUpdateInventoryRequest}
      >
        {({ isSubmitting, submitForm, values }) => (
          <Form>
            <DialogTitle id="dialog-title">{title}</DialogTitle>
            <DialogContent>
              <Box mb={2}>
                <Header item={inventoryRequest} />
              </Box>
              <Box mb={3}>
                <Status
                  loading={false}
                  inventoryRequest={inventoryRequest}
                  changeStatus={changeStatus}
                />
              </Box>
              <DialogContentText>
                <SingleDeliveryFields
                  email={emailIsRequired}
                  workOrderNumber={inventoryRequest.workOrderNumber}
                  user={inventoryRequest.user}
                  revisionNumber={inventoryRequest.revisionNumber}
                  hideAddressDropdown={
                    inventoryRequest.street || inventoryRequest.city || inventoryRequest.zipCode
                  }
                  multipleDelivery={false}
                  setMultipleDelivery={() => null}
                  changeAllInventoryItems={changeAllInventoryItems}
                  commentDisabled={fieldDisabled} // Disable comment field if within cutoff window
                >
                  <Box>
                    <Box my={1.5}>
                      {wizardMode && values.inventoryItems.length === 0 && (
                        <LocationNotes list={[]} />
                      )}
                      {values.inventoryItems.length > 0 && (
                        <CartItems
                          submitCounter={submitCounter}
                          multipleDelivery={false}
                          display={display}
                          items={values.inventoryItems}
                          removeInventoryItem={removeInventoryItem}
                          updateInventoryItem={updateInventoryItem}
                          reFetch={refetch}
                          locationNotesDisabled={fieldDisabled} // Disable location notes if within cutoff window
                        />
                      )}
                      <Attachments inventoryRequestId={id} />
                      <AddAsset values={initialValues} refetch={refetch} />
                    </Box>
                  </Box>
                </SingleDeliveryFields>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Box display="flex" justifyContent="flex-end" width="100%" p={2} pr={3}>
                <Box display="flex" gap={1}>
                  <Button variant="outlined" onClick={onClose} color="primary">
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    disabled={isSubmitting}
                    startIcon={isSubmitting && <CircularProgress size={22} />}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
