import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function CaptureActionsLegacy({
  disabled,
  onSave,
  onCreate,
  onDuplicate,
  onRemove,
  onTransfer,
  projectId,
  disableSave,
}) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          {onRemove && (
            <Grid item xs={onTransfer ? 3 : 4}>
              <Button
                disabled={disabled || !onRemove}
                id="RemoveAsset"
                data-id="remove-asset"
                fullWidth
                variant="outlined"
                color="error"
                onClick={onRemove}
              >
                Remove
              </Button>
            </Grid>
          )}
          {onTransfer && (
            <Grid item xs={3}>
              <Button
                disabled={disabled || !onTransfer}
                id="RemoveAsset"
                data-id="remove-asset"
                fullWidth
                variant="outlined"
                onClick={onTransfer}
              >
                Transfer
              </Button>
            </Grid>
          )}
          {onSave && (
            <Grid item xs={onTransfer ? 3 : 4}>
              <Button
                disabled={disabled || !onSave || disableSave}
                id="saveAsset"
                data-id="save-asset"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSave}
              >
                Save
              </Button>
            </Grid>
          )}
          {onCreate && (
            <Grid item xs={onTransfer ? 3 : 4}>
              <Button
                disabled={disabled || !projectId || !onCreate}
                id="addNewAsset"
                data-id="add-new-asset"
                fullWidth
                variant="outlined"
                onClick={onCreate}
              >
                Add New
              </Button>
            </Grid>
          )}
          {onDuplicate && (
            <Grid item xs={4}>
              <Button
                disabled={disabled || !onDuplicate}
                id="duplicateAsset"
                data-id="duplicate-asset"
                fullWidth
                variant="outlined"
                onClick={onDuplicate}
              >
                Duplicate
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
