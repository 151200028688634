import React from "react";
import moment from "moment";
import orderBy from "lodash/orderBy";
import {useQuery} from "@apollo/react-hooks";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

import {ASSET_AUDIT_LOGS_QUERY} from "../../constants/graphql/queries";
import useAuth from "../../hooks/useAuth";
import labels from "../../constants/displayFields";
import {isVaSubdomain} from "../../utils/inventoryRequests"; // Import isVaSubdomain

const DATE_FORMAT = "MM/DD/yyyy hh:mm A";
const serverFormat = "yyyy-MM-DDThh:mm:ss Z";

export default function AssetAuditLogs({ type = "asset", assetId, defaultTab }) {
  const { user } = useAuth();
  const { settings } = user.company;
  const [selected, setSelected] = React.useState(defaultTab);
  const { data: { assetAuditLogs = [] } = {}, loading } = useQuery(ASSET_AUDIT_LOGS_QUERY, {
    variables: { assetId, action: selected },
    fetchPolicy: "network-only",
  });

  const isVA = isVaSubdomain(); // Check if it's a VA subdomain

  const handleChange = event => {
    setSelected(event.target.value);
  };

  return (
    <>
      <Typography fontWeight={500} fontSize={18}>
        Activity Log
      </Typography>
      <Box display="flex" my={2}>
        <FormControl size="small">
          {type === "kit" ? (
            <Select variant="outlined" value={selected} onChange={handleChange}>
              <MenuItem value="deplenish_inventory">Deplenish Records</MenuItem>
              <MenuItem value="reserved_inventory">Reserved Inventory</MenuItem>
            </Select>
          ) : (
            <Select variant="outlined" value={selected} onChange={handleChange}>
              <MenuItem value="replenish_inventory">Replenish Records</MenuItem>
              {/* Received */}
              <MenuItem value="deplenish_inventory">Deplenish Records</MenuItem>
              {/* Deployed */}
              <MenuItem value="reserved_inventory">Reserved Inventory</MenuItem>
              {/* Reserved */}
            </Select>
          )}
        </FormControl>
        {loading && (
          <Box ml={1}>
            <CircularProgress size={35} />
          </Box>
        )}
      </Box>
      <Box sx={{ overflowX: "auto" }}>
        {assetAuditLogs.length === 0 ? (
          <Box display="flex" justifyContent="center">
            Empty
          </Box>
        ) : selected === "replenish_inventory" ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="center">Logged By</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Received&nbsp;From</TableCell>
                  <TableCell align="center">Receiving&nbsp;Notes</TableCell>
                  <TableCell align="center">{labels.rack_locations}</TableCell>
                  {settings.restock_additional_fields && (
                    <>
                      <TableCell align="center">Sender&nbsp;Name</TableCell>
                      <TableCell align="center">Carrier&nbsp;Name</TableCell>
                      <TableCell align="center">Tracking&nbsp;Number</TableCell>
                      <TableCell align="center">Order&nbsp;Number</TableCell>
                      <TableCell align="center">PO#</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(
                  assetAuditLogs,
                  v => moment(v.performedAt, serverFormat).format("x"),
                  "desc"
                ).map((row, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {moment(row.performedAt, serverFormat).format(DATE_FORMAT)}
                    </TableCell>
                    <TableCell align="center">{row.performer?.email}</TableCell>
                    <TableCell align="center">{row.details.quantity}</TableCell>
                    <TableCell align="center">{row.details.po_number}</TableCell>
                    <TableCell align="center">{row.details.notes}</TableCell>
                    <TableCell align="center">
                      {row.details.rack_locations
                        ? row.details.rack_locations
                            .map(v => `${v.rack_location} (${v.sub_qty})`)
                            .join(", ")
                        : "N/A"}
                    </TableCell>
                    {settings.restock_additional_fields && (
                      <>
                        <TableCell align="center">{row.details.sender_name}</TableCell>
                        <TableCell align="center">{row.details.carrier_name}</TableCell>
                        <TableCell align="center">{row.details.tracking_number}</TableCell>
                        <TableCell align="center">{row.details.order_number}</TableCell>
                        <TableCell align="center">{row.details.po_number}</TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : selected === "deplenish_inventory" ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="center">Logged By</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Deployed To</TableCell>
                  <TableCell align="center">{isVA ? "Task Order #" : "Order #"}</TableCell>
                  <TableCell align="center">Storage Location(s)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(
                  assetAuditLogs,
                  v => moment(v.performedAt, serverFormat).format("x"),
                  "desc"
                ).map((row, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {moment(row.performedAt, serverFormat).format(DATE_FORMAT)}
                    </TableCell>
                    <TableCell align="center">{row.performer?.email}</TableCell>
                    <TableCell align="center">{row.details.quantity}</TableCell>
                    <TableCell align="center">{row.object.object.location}</TableCell>
                    <TableCell align="center">{row.details.work_order_number}</TableCell>
                    <TableCell align="center">
                      {row.details.rack_locations
                        ? row.details.rack_locations
                            .map(v => `${v.rack_location} (${v.sub_qty})`)
                            .join(", ")
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Reservation #</TableCell>
                  <TableCell align="center">Reserved For</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">PO#</TableCell>
                  <TableCell align="center">Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(assetAuditLogs, v => v.details.work_order_number, "desc").map(
                  (row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.details.work_order_number}
                      </TableCell>
                      <TableCell align="center">{row.details.reserved_for}</TableCell>
                      <TableCell align="center">{row.details.quantity}</TableCell>
                      <TableCell align="center">{row.details.po_number}</TableCell>
                      <TableCell align="center">{row.details.notes}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
}