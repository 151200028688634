import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WarehouseList from "src/views/warehouse/WarehouseList";

export const SelectLocationContext = React.createContext(null);

const DisableTransitionComponent = ({ children }) => children;

export default function WarehouseModal({ open, onClose, onSelect }) {
  const selectLocation = value => {
    onSelect({
      warehouseId: value.warehouseId,
      locationName: value.locationName,
      __typename: value.__typename,
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      TransitionComponent={DisableTransitionComponent}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: { transition: "none !important" },
        },
      }}
      PaperProps={{
        sx: {
          minHeight: "90vh",
        },
      }}
    >
      <DialogTitle id="dialog-title" sx={{ background: "#f4f6f8" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box width={50} />
          <Typography variant="h2" color="textPrimary" align="center">
            Warehouse Map
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minHeight: "90vh", background: "#f4f6f8" }}>
        <SelectLocationContext.Provider value={selectLocation}>
          <WarehouseList modal />
        </SelectLocationContext.Provider>
      </DialogContent>
    </Dialog>
  );
}
