import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PublishIcon from "@mui/icons-material/Publish";
import LinearProgress from "@mui/material/LinearProgress";

import useNotification from "../hooks/notification";

export default function UploadButton({ onUpload, disabled, name, accept = "image/*", ...props }) {
  const { notifyError } = useNotification();
  const [loading, setLoading] = useState(false);

  const handleUpload = event => {
    setLoading(true);
    onUpload(event.target.files[0], {
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <input
        name={name}
        disabled={disabled || loading}
        accept={accept}
        id={`contained-button-file-${name}`}
        onChange={handleUpload}
        type="file"
        style={{ display: "none" }}
      />
      <label htmlFor={`contained-button-file-${name}`}>
        <Button
          sx={{ height: 40, mb: 1, width: "100%" }}
          variant="contained"
          disabled={disabled}
          fullWidth
          component="span"
          {...props}
          color="primary"
        >
          <PublishIcon fontSize="large" />
        </Button>
      </label>
      <Box>{loading && <LinearProgress />}</Box>
    </>
  );
}
