import React from "react";
import { Form, Formik } from "formik";
import sumBy from "lodash/sumBy";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import CalculateItemWeightTable from "./CalculateItemWeightTable";
import { getGroupWeight } from "./utils";

export default function CalculateItemWeight({ shipment, shipmentItems }) {
  const history = useHistory();
  const totalWeight = shipment.totalWeight;
  const totalQuantity = sumBy(shipmentItems, v => v.quantity);
  const initialValues = shipmentItems.map(v => ({
    ...v,
    groupWeight: v.weight
      ? v.weight * v.quantity
      : getGroupWeight(totalWeight, totalQuantity, v.quantity),
  }));

  const handleClose = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("itemWeight");
    history.push({ pathname, search: params.toString() });
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Calculate Item Weight
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
          <Form>
            <CalculateItemWeightTable
              shipmentItems={shipmentItems}
              totalWeight={totalWeight}
              totalQuantity={totalQuantity}
            />
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
