import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Footer from "./Footer";
import Selected from "./Selected";

export default function Step0({ state, onNext }) {
  const handleNext = () => {
    onNext();
  };

  return (
    <>
      <DialogContent>
        <Box minHeight={300} display="flex" alignItems="center">
          <Box width={320} margin="0 auto">
            <Box
              my={1}
              mb={5}
              alt="Warehouse worker counting pallets"
              component="img"
              borderRadius={2}
              width="100%"
              maxWidth={360}
              src="/pallet-count-assistant.png"
            />
            <Typography align="center" sx={{ mb: 4, fontWeight: 500 }}>
              Use this tool to quickly calculate the total quantity of items across multiple
              pallets.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <Footer step={0}>
        <Selected palletCount={state.palletCount} boxesCount={state.boxesCount} />
        <Button variant="contained" color="primary" onClick={handleNext} sx={{ minWidth: 100 }}>
          Start
        </Button>
      </Footer>
    </>
  );
}
