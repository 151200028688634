import React from "react";
import TextField from "@material-ui/core/TextField";

const formatLocation = ({ mobile_warehouse_location, rack_location, sub_qty }) => {
  const location = mobile_warehouse_location
    ? `${mobile_warehouse_location} in ${rack_location}`
    : rack_location;
  return `${location} (${sub_qty})`;
};

export default function RackLocationsInput({
  disabled,
  rackLocations = [],
  toggleModal,
  onKeyDown,
}) {
  const formattedLocations = rackLocations.map(formatLocation).join("\n");

  return (
    <TextField
      fullWidth
      multiline
      disabled={disabled}
      size="small"
      variant="outlined"
      label="Storage Location(s)"
      value={formattedLocations}
      onClick={toggleModal}
      onKeyDown={onKeyDown}
      InputProps={{
        readOnly: true,
      }}
    />
  );
}
