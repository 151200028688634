import React from "react";
import {Link, useParams} from "react-router-dom";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GenerateReport from "../../../views/favoriteReport/GenerateReport";
import {useClients} from "../../../contexts/clients";

export default function ReportsTable({ favoriteReports }) {
  const { projectId, clientId } = useParams();
  const { currentProject, currentClient } = useClients();

  const reports =
    projectId || clientId
      ? favoriteReports.filter(report =>
          report.notifications.some(notification =>
            clientId && notification?.client?.id === clientId
              ? true
              : notification?.project?.id === projectId
          )
        )
      : favoriteReports;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Report Template Name</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map(row => (
            <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.payload.name}
              </TableCell>
              <TableCell align="right">
                <GenerateReport
                  entity={row}
                  selectedProjectId={currentProject?.id}
                  selectedClientId={currentClient?.id}
                />
                <IconButton component={Link} to={`/projects/null/templates/${row.id}`}>
                  <EditOutlinedIcon color="action" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
