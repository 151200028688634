import React from "react";
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import {useProjectInventory} from "../../../contexts/projectInventory";
import Dimensions from "./Dimensions";
import AssetDetailsModal from "./asset-details/AssetDetailsModal";

export default function AssetRowNew({ inventoryItem, reFetch }) {
  const { inventoryAssets } = useProjectInventory();
  const asset = inventoryAssets[inventoryItem.asset.id];

  return asset ? (
    <TableRow key={asset.id} id={`inventoryItem-${inventoryItem.id}`}>
      <TableCell>
        {asset?.photo ? (
          <img style={{ maxWidth: 100 }} src={asset.photo} alt="asset image" />
        ) : (
          "No image"
        )}
        <Box mt={1}>
          <div style={{ fontSize: "12px", maxWidth: 100 }}>{asset.description}</div>
        </Box>
      </TableCell>
      <TableCell>
        <AssetDetailsModal asset={asset} reFetch={reFetch} />
      </TableCell>
      <TableCell>{asset.model}</TableCell>
      <TableCell>
        <Dimensions dimensions={asset.dimensions} />
      </TableCell>
      <TableCell>{inventoryItem.quantity}</TableCell>
    </TableRow>
  ) : null;
}
