import React from "react";
import omit from "lodash/omit";
import { useFormikContext } from "formik";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import { formatAssetInput } from "../../utils/asset";
import { useAssets } from "../../contexts/assets";
import useNotification from "../../hooks/notification";
import useAuth from "../../hooks/useAuth";
import CaptureActionsLegacy from "../../components/capture/CaptureActionsLegacy";
import CaptureActionsMobile from "../../components/capture/CaptureActionsMobile";
import CaptureSkeleton from "../../components/CaptureSkeleton";
import FormFields from "./FormFields";

const styles = {
  stickyActions: {
    width: "100%",
    position: "sticky",
    bottom: "10px",
    left: 0,
    zIndex: 99,
    padding: 0,
  },
};

export default function FormPage({ type, displayClient, displayProject }) {
  const { user } = useAuth();
  const formProps = useFormikContext();
  const { notifyError } = useNotification();

  const {
    projectId,
    loading,
    loadingMutation,
    currentAsset,
    updateAsset,
    createAsset,
    createImage,
  } = useAssets();

  const handleSubmitAction = ({
    variables,
    setSubmitting,
    setStatus,
    setErrors,
    submitAction,
    handleSubmit,
  }) => {
    handleSubmit();
    setSubmitting(true);

    return submitAction({
      variables: { ...variables, input: formatAssetInput(variables.input) },
      onSuccess: () => {
        setSubmitting(false);
        setStatus({ success: true });
      },
      onFailure: errors => {
        setStatus({ success: false });
        setErrors(errors);
        notifyError(errors);
        setSubmitting(false);
      },
    });
  };

  const submitUpdateAsset = ({ values, ...props }) => () => {
    const variables = { id: currentAsset?.id, input: values };

    return handleSubmitAction({ ...props, variables, submitAction: updateAsset });
  };

  const submitCreateAsset = ({ values, ...props }) => async () => {
    if (values.id) {
      await submitUpdateAsset(formProps)({ values, ...props });
    }
    const variables = { projectId, input: {} };
    handleSubmitAction({ ...props, variables, submitAction: createAsset });
  };

  const submitDuplicateAsset = ({ values, ...props }) => async () => {
    await submitUpdateAsset(formProps)({ values, ...props });
    const excludeProps = ["assetId", "barcode"];
    const variables = {
      projectId: currentAsset.project.id,
      input: { ...omit(values, excludeProps) },
    };
    handleSubmitAction({ ...props, variables, submitAction: createAsset });
  };

  return (
    <form
      id="assetForm"
      className="d-flex flex-column flex-grow-1 justify-content-between justify-content-md-start"
      style={{ position: "relative" }}
    >
      {loading && (
        <Box py={1}>
          <LinearProgress className="mt-2" />
        </Box>
      )}
      {currentAsset?.id ? (
        <FormFields
          type={type}
          asset={currentAsset}
          loading={loadingMutation}
          displayClient={displayClient}
          displayProject={displayProject}
          updateAsset={updateAsset}
          createImage={createImage}
          setErrors={formProps.setErrors}
          errors={formProps.errors}
        />
      ) : (
        loading && <CaptureSkeleton />
      )}
      <Box mb={2} />
      <Box sx={styles.stickyActions}>
        {user.company.settings.capture_mode === "mobile" ? (
          <CaptureActionsMobile
            disabled={formProps.isSubmitting}
            onSave={submitUpdateAsset(formProps)}
            disableSave={!currentAsset?.id}
            onCreate={submitCreateAsset(formProps)}
            onDuplicate={submitDuplicateAsset(formProps)}
            projectId={projectId}
          />
        ) : (
          <CaptureActionsLegacy
            disabled={formProps.isSubmitting}
            onSave={submitUpdateAsset(formProps)}
            disableSave={!currentAsset?.id}
            onCreate={submitCreateAsset(formProps)}
            onDuplicate={submitDuplicateAsset(formProps)}
            projectId={projectId}
          />
        )}
      </Box>
    </form>
  );
}
