import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StorageLocationAutocomplete from "../../../views/capture/RackLocations/StorageLocationAutocomplete/StorageLocationAutocomplete";

export default function SelectNewLocationModal({ onClose, onSubmit, warehouseLocations = [] }) {
  const [selectedLocation, setSelectedLocation] = useState({
    warehouse: "",
    rack_location: [],
  });

  const handleSubmit = () => {
    onSubmit(selectedLocation);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography fontSize={20} fontWeight={500} color="text.primary" align="center">
          Select New Location
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <StorageLocationAutocomplete
            name="location"
            value={selectedLocation}
            warehouseLocations={warehouseLocations}
            onChange={values => {
              setSelectedLocation(values);
            }}
            disabled={false}
            loading={false}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2, justifyContent: "flex-end" }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!selectedLocation?.warehouse}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
