import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";

const ArrowBack = () => "<";

const FullscreenModal = ({
  maxWidth,
  onBack,
  onClose,
  showClose,
  title,
  actions,
  id,
  bodyClassName,
  disableTransition,
  children,
  disableFullScreen,
  ...rest
}) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

  const [uuid] = React.useState(id);

  return (
    <Dialog
      {...rest}
      maxWidth={false}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      fullScreen={disableFullScreen ? false : fullScreen}
      aria-labelledby={uuid}
      transitionDuration={disableTransition ? 0 : undefined}
    >
      <DialogTitle id={uuid}>
        <Grid container alignItems="center" wrap="nowrap">
          <Box sx={{ minHeight: "64px" }} />
          <Grid item sx={{ p: "8px" }}>
            {onBack && (
              <IconButton onClick={() => onBack()} sx={{ position: "absolute", left: 16, top: 16 }}>
                <ArrowBack titleAccess="Back" />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" color="textPrimary">
              {title}
            </Typography>
          </Grid>
          <Grid item sx={{ p: "8px" }}>
            {showClose && onClose && (
              <IconButton onClick={() => onClose()}>
                <CloseIcon titleAccess="Close" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box>{children}</Box>
      </DialogContent>
      {actions && <DialogActions className="m-0 p-sm-3">{actions}</DialogActions>}
    </Dialog>
  );
};

FullscreenModal.propTypes = {
  /** If true, the Dialog is open */
  open: PropTypes.bool,
  /** The Dialog's title */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** The instructions for the dialog */
  actions: PropTypes.node,
  /** Function called when the Dialog closes () => {} */
  onClose: PropTypes.func,
  /** Override to hide the close button */
  showClose: PropTypes.bool,
  /** Function called when the Back button is pressed () => {} */
  onBack: PropTypes.func,
  /** Max width for the fullscreen modal on lgUp screens */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** If true, the modal fade transition will be removed */
  disableTransition: PropTypes.bool,
  /** Id to link the dialog title to the dialog's aria-labelledby attribute (will be randomly generated if not provided) */
  id: PropTypes.string,
  /** ClassName for the body scrollbar element */
  bodyClassName: PropTypes.string,
  children: PropTypes.node,
};

FullscreenModal.defaultProps = {
  open: true,
  showClose: true,
  disableTransition: false,
};

export default FullscreenModal;
