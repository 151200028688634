import React from "react";
import {useHistory} from "react-router-dom";
import Button from "@mui/material/Button";

export default function NextButton({ disabled, step }) {
  const history = useHistory();

  const handleNext = () => {
    history.push(`/shopping/cart/${step}`);
  };

  return (
    <Button
      disabled={disabled}
      size="large"
      color="primary"
      variant="contained"
      onClick={handleNext}
    >
      Next
    </Button>
  );
}
