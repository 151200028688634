import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

const styles = {
  submitButton: {
    mr: "1px",
    height: 36,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  dropdownButton: {
    px: 0,
    height: 36,
    minWidth: 40,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

export default function ActionsButton({ loading, onClick }) {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        disabled={loading}
        type="button"
        sx={styles.submitButton}
        variant="contained"
        color="primary"
        startIcon={loading && <CircularProgress size={22} />}
        onClick={() => onClick({ draft: false })}
      >
        Start
      </Button>
      <Box variant="contained" aria-label="split button">
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <React.Fragment>
              <Button
                variant="contained"
                color="primary"
                {...bindTrigger(popupState)}
                sx={styles.dropdownButton}
              >
                <KeyboardArrowDownIcon
                  sx={{ transform: popupState.open ? undefined : "rotate(180deg)" }}
                />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    onClick({ draft: true });
                    popupState.close();
                  }}
                >
                  Create Draft
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </Box>
    </Box>
  );
}
