import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Selected({ palletCount, boxesCount }) {
  return (
    <Box>
      {palletCount && <Typography>{palletCount} pallets</Typography>}
      {boxesCount && <Typography>{boxesCount} boxes/units per pallet</Typography>}
    </Box>
  );
}
