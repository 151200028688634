import React, { useCallback, useState } from "react";
import omit from "lodash/omit";
import { useFormikContext } from "formik";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { formatAssetInput } from "../../../../utils/asset";
import useNotification from "../../../../hooks/notification";
import CaptureActionsScrubber from "../../../../components/capture/CaptureActionsScrubber";
import FormFields from "../../../../views/capture/FormFields";
import TransferModal from "../../../../views/asset/TransferModal";
import { useAssetsCollection } from "../../../../contexts/assetsCollection";
import { useProjectInventory } from "../../../../contexts/projectInventory";

const styles = {
  stickyContainer: {
    width: "100%",
    position: "sticky",
    bottom: "10px",
    left: 0,
    zIndex: 99,
    padding: "0",
    backgroundColor: "transparent",
  },
};

export default function FormPage({
  type,
  currentAsset,
  displayClient,
  displayProject,
  onCloseModal,
  handeRefetch,
}) {
  const formProps = useFormikContext();
  const { notifyError, notifySuccess } = useNotification();
  const [openTransfer, setOpenTransfer] = useState(false);

  const {
    projectId,
    loading,
    loadingMutation,
    updateAsset,
    createAsset,
    createImage,
    removeAsset,
  } = useAssetsCollection();

  const { updateInventoryAsset } = useProjectInventory();

  const handleSubmitAction = ({
    variables,
    setSubmitting,
    setStatus,
    setErrors,
    submitAction,
    handleSubmit,
  }) => {
    handleSubmit();
    setSubmitting(true);

    return submitAction({
      variables: { ...variables, input: formatAssetInput(variables.input) },
      onSuccess: ({ asset }) => {
        updateInventoryAsset(asset.id, asset);
        setSubmitting(false);
        setStatus({ success: true });
      },
      onFailure: errors => {
        setStatus({ success: false });
        setErrors(errors);
        notifyError(errors);
        setSubmitting(false);
      },
    });
  };

  const submitUpdateAsset = ({ values, ...props }) => async () => {
    const variables = { id: currentAsset?.id, input: values };
    handleSubmitAction({ ...props, variables, submitAction: updateAsset });
  };

  const submitCreateAsset = ({ values, ...props }) => async () => {
    await submitUpdateAsset(formProps)({ values, ...props });
    const variables = { projectId, input: {} };
    return handleSubmitAction({ ...props, variables, submitAction: createAsset });
  };

  const submitDuplicateAsset = ({ values, ...props }) => async () => {
    await submitUpdateAsset(formProps)({ values, ...props });
    const excludeProps = ["assetId", "barcode"];
    const variables = {
      projectId: currentAsset.project.id,
      input: { ...omit(values, excludeProps) },
    };
    return handleSubmitAction({ ...props, variables, submitAction: createAsset });
  };

  const submitRemoveAsset = () => {
    return removeAsset({
      variables: { id: currentAsset?.id },
      onSuccess: ({ asset }) => {
        notifySuccess("Asset was removed");
      },
    });
  };

  const toggleTransfer = useCallback(() => {
    setOpenTransfer(v => !v);
  }, [setOpenTransfer]);

  return (
    <form
      id="assetForm"
      className="d-flex flex-column flex-grow-1 justify-content-between justify-content-md-start"
      style={{ position: "relative" }}
    >
      {loading && (
        <Box py={1}>
          <LinearProgress className="mt-2" />
        </Box>
      )}
      <FormFields
        type={type}
        asset={currentAsset}
        displayClient={displayClient}
        displayProject={displayProject}
        loading={loadingMutation}
        updateAsset={updateAsset}
        createImage={createImage}
        setErrors={formProps.setErrors}
        errors={formProps.errors}
      />
      <Box mb={2} />
      <Container maxWidth={false} sx={styles.stickyContainer}>
        <CaptureActionsScrubber
          disabled={formProps.isSubmitting}
          onSave={submitUpdateAsset(formProps)}
          disableSave={!currentAsset?.id}
          onCreate={submitCreateAsset(formProps)}
          onDuplicate={submitDuplicateAsset(formProps)}
          projectId={projectId}
          onCloseModal={onCloseModal}
          onRemove={submitRemoveAsset}
          onTransfer={toggleTransfer}
          handeRefetch={handeRefetch}
        />
        {openTransfer && (
          <TransferModal
            asset={currentAsset}
            handeRefetch={handeRefetch}
            onClose={toggleTransfer}
          />
        )}
      </Container>
    </form>
  );
}
