import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

export default function SubmitShipmentModal({ onClose, onSubmit, onSaveDraft }) {
  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth disablePortal>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width={34} />
          <Typography fontSize={20} fontWeight={500} color="text.primary" align="center">
            Submit Shipment
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography align="center">
          Is this shipment complete, or would you like to save it as a draft? Other parties are only
          notified when you submit a complete shipment.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2, justifyContent: "flex-end", gap: 1 }}>
        <Button onClick={onSaveDraft} variant="outlined">
          Save as Draft
        </Button>
        <Button onClick={onSubmit} variant="contained" color="primary">
          Submit Shipment
        </Button>
      </DialogActions>
    </Dialog>
  );
}
