import React from "react";
import { useFormikContext } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function ConfirmQuantitiesModal({ onSubmit, onClose, onCloseAddLocation }) {
  const { values } = useFormikContext();
  const unitsPerPallet = values.totalUnits / values.numberOfLocations;

  const handleSubmit = () => {
    onSubmit(values);
    onCloseAddLocation();
  };

  const formatNumber = num => {
    return new Intl.NumberFormat().format(num);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box position="relative">
          <Typography fontSize={20} fontWeight={500} color="text.primary" align="center">
            Confirm Quantities
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{ position: "absolute", right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Typography align="center">
            You entered {formatNumber(values.numberOfLocations)} pallets and{" "}
            {formatNumber(values.totalUnits)} units total.
            <br />
            That's {formatNumber(unitsPerPallet)} units per pallet.
            <br />
            <br />
            Is this correct?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
