import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

export default function PalletCountAssistant({ step, state, onClose, onNext, onBack, onChange }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxWidth: 600,
        },
      }}
    >
      <DialogTitle sx={{ p: 3 }}>
        <Typography
          align="center"
          color="textSecondary"
          sx={{ px: 2, fontWeight: 500, fontSize: 22 }}
        >
          Pallet Count Assistant
        </Typography>
        <IconButton
          onClick={onClose}
          size="small"
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {step === 0 && <Step0 onNext={onNext} state={state} />}
      {step === 1 && <Step1 onNext={onNext} onBack={onBack} onChange={onChange} state={state} />}
      {step === 2 && <Step2 onNext={onNext} onBack={onBack} onChange={onChange} state={state} />}
      {step === 3 && <Step3 onNext={onNext} onBack={onBack} onChange={onChange} state={state} />}
    </Dialog>
  );
}
