import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {INVENTORY_REQUESTS_QUERY} from "../../../constants/graphql";
import ReceivingWizardModal from "../../../components/capture/ReceivingWizard/ReceivingWizardModal";
import useQueryParams from "../../../hooks/useQueryParams";
import OutboundTable from "./OutboundTable";

export default function Outbound() {
  const params = useQueryParams();
  const history = useHistory();
  const { projectId } = useParams();
  const [search, setSearch] = useState("");
  const { loading, data: { inventoryRequests = [] } = {}, refetch } = useQuery(
    INVENTORY_REQUESTS_QUERY,
    {
      variables: {
        projectId: parseInt(projectId),
      },
    }
  );
  const list =
    inventoryRequests?.collection?.filter(item => item.workOrderNumber?.includes(search)) || [];

  const handleToggle = shipment => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    if (params.get("receiving")) {
      history.push({ pathname, search: "" });
    } else {
      params.set("step", "shipment");
      params.set("receiving", "true");
      params.set("editShipment", "true");
      params.set("shipmentId", shipment.shipmentId);
      params.set("id", shipment.id);
      history.push({ pathname, search: params.toString() });
    }
  };

  return (
    <Paper>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={500}>Outbound Orders</Typography>
        <TextField
          value={search}
          onChange={e => setSearch(e.target.value)}
          size="small"
          placeholder="Search"
          InputProps={{ endAdornment: <SearchIcon color="action" /> }}
        />
      </Box>
      {loading ? (
        <Box py={3} display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box p={2}>
          <OutboundTable list={list} refetch={refetch} onEdit={handleToggle} />
        </Box>
      )}
      {params.get("receiving") && <ReceivingWizardModal onToggle={handleToggle} />}
    </Paper>
  );
}
