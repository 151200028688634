import React from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export default function SearchInput({ value, onChange, setSearch, onFocus, onBlur }) {
  const handleClear = () => {
    onChange("");
    setSearch("");
  };

  return (
    <TextField
      value={value || ""}
      onChange={v => onChange(v.target.value)}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder="Search"
      inputProps={{
        "data-id": "search-input",
      }}
      size="small"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value && (
              <IconButton onClick={handleClear}>
                <CloseIcon />
              </IconButton>
            )}
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
