import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import { usePermissions } from "src/contexts/permissions";

const GuestGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const { can } = usePermissions();

  if (isAuthenticated) {
    const isGuest = user.role === "guest";

    if (can("pages.dashboard")) {
      return <Redirect to="/dashboard" />;
    }

    if (can("pages.shopping") && !isGuest) {
      return <Redirect to="/shopping" />;
    }
  }

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
