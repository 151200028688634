import React from "react";
import sumBy from "lodash/sumBy";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useQuery } from "@apollo/react-hooks";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import LinearProgress from "@mui/material/LinearProgress";

import AssetDetailsModal from "../../../../views/inventory/requests/asset-details/AssetDetailsModal";
import Dimensions from "../../../../views/inventory/requests/Dimensions";
import { ASSETS_COLLECTION_QUERY } from "../../../../constants/graphql/queries";
import { ProjectInventoryProvider } from "../../../../contexts/projectInventory";
import { useWarehouseContext } from "../../../../views/warehouse/Warehouse";

export default function LocationSnapshot({ entity, isMobileLocation }) {
  const warehouseId = entity.warehouseId;
  const { refetchWarehouse } = useWarehouseContext();

  const { data: { assetsCollection: { collection = [] } = {} } = {}, loading, refetch } = useQuery(
    ASSETS_COLLECTION_QUERY,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        warehouseId,
        [isMobileLocation
          ? "mobileWarehouseLocationName"
          : "warehouseLocationName"]: entity.locationName,
      },
    }
  );

  const getSubQty = locations => {
    return sumBy(
      locations.filter(v => {
        if (typeof v.rack_location === "string") {
          return v.warehouse === warehouseId && v.rack_location === entity.locationName;
        } else {
          return v.warehouse === warehouseId && v.rack_location?.includes(entity.locationName);
        }
      }),
      v => v.sub_qty
    );
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography fontWeight={500} fontSize={20} mb={0.5}>
          Location Snapshot
        </Typography>
      </Box>
      {loading ? (
        <Box mt={2}>
          <LinearProgress />
        </Box>
      ) : collection.length === 0 ? (
        <Box
          mt={1}
          p={3}
          bgcolor="#f4f6f8"
          borderRadius="4px"
          display="flex"
          justifyContent="center"
        >
          Empty
        </Box>
      ) : (
        <ProjectInventoryProvider>
          <Table aria-label="Location Snapshot Table">
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Item ID</TableCell>
                <TableCell>Description</TableCell>
                {collection.some(asset => asset.model) && <TableCell>Model</TableCell>}
                {collection.some(asset => asset.dimensions && asset.dimensions.length > 0) && (
                  <TableCell align="center">
                    Dimensions
                    <br />W x D x H
                  </TableCell>
                )}
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collection.map(asset => (
                <TableRow key={asset.id} id={`snapshot-${asset.id}`}>
                  <TableCell>
                    {asset?.photo ? (
                      <img style={{ maxWidth: 100 }} src={asset.photo} alt="asset image" />
                    ) : (
                      "No image"
                    )}
                  </TableCell>
                  <TableCell>
                    <AssetDetailsModal asset={asset} reFetch={refetch} onClose={refetchWarehouse} />
                  </TableCell>
                  <TableCell>{asset.description}</TableCell>

                  {collection.some(asset => asset.model) ? (
                    asset.model ? (
                      <TableCell>{asset.model}</TableCell>
                    ) : (
                      <TableCell />
                    )
                  ) : null}

                  {collection.some(asset => asset.dimensions && asset.dimensions.length > 0) ? (
                    asset.dimensions && asset.dimensions.length > 0 ? (
                      <TableCell>
                        <Dimensions dimensions={asset.dimensions} />
                      </TableCell>
                    ) : (
                      <TableCell />
                    )
                  ) : null}

                  <TableCell>{getSubQty(asset.rackLocations)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ProjectInventoryProvider>
      )}
    </Paper>
  );
}
