import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Close from "@mui/icons-material/Close";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Backdrop from "@mui/material/Backdrop";

const styles = {
  dialog: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxHeight: "none",
      margin: "auto"
    },
    "& .MuiDialog-paperScrollBody": {
      maxHeight: "none",
      margin: "auto"
    },
    "& .MuiDialog-scrollBody": {
      padding: "48px 12px",
      display: "flex",
      boxSizing: "border-box",
      alignItems: "flex-start"
    }
  },
  gridItem: {
    paddingTop: "8px",
    paddingLeft: "8px"
  },
  gridItemRight: {
    paddingTop: "8px",
    paddingRight: "8px"
  },
  title: {
    p: 0
  },
  titleTypography: {
    px: 3,
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  dialogContent: {
    pb: 3,
    pt: 1,
    px: { xs: 2, sm: 3 }
  },
  instructions: {
    p: 3
  }
};

const DisableTransitionComponent = ({ children }) => children;

const ActionModal = ({
  maxWidth,
  onBack,
  onClose,
  showClose,
  title,
  instructions,
  id,
  disableTransition,
  disableBackdropClose,
  children,
  ...rest
}) => {
  const uuid = id || "modal";

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" && disableBackdropClose) {
      return;
    }
    if (onClose) {
      onClose(event, reason);
    }
  };

  return (
    <Dialog
      {...rest}
      maxWidth={false}
      scroll="body"
      onClose={handleClose}
      PaperProps={{ style: { maxHeight: "none !important" } }}
      sx={{
        ...styles.dialog,
        "& .MuiDialog-paper": {
          ...styles.dialog["& .MuiDialog-paper"],
          maxWidth: maxWidth
        }
      }}
      aria-labelledby={uuid}
      TransitionComponent={DisableTransitionComponent}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { sx: { transition: "none !important" } },
      }}
    >
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid item sx={styles.gridItem}>
          {onBack && (
            <IconButton onClick={() => onBack()}>
              <ArrowBack titleAccess="Back" />
            </IconButton>
          )}
        </Grid>
        <Grid item sx={styles.gridItemRight}>
          {showClose && onClose && (
            <Tooltip title="Close">
              <IconButton data-id="close-modal" onClick={() => onClose()}>
                <Close />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <DialogTitle id={uuid} disableTypography sx={styles.title}>
        <Box mb={3}>
          <Typography
            component="span"
            color="text.secondary"
            variant="h2"
            align="center"
            sx={styles.titleTypography}
          >
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        {instructions && (
          <Typography variant="body1" color="text.secondary" align="center" sx={styles.instructions}>
            {instructions}
          </Typography>
        )}
        <div>{children}</div>
      </DialogContent>
    </Dialog>
  );
};

ActionModal.defaultProps = {
  open: true,
  showClose: true,
  disableTransition: false,
  disableBackdropClose: false,
};

export default ActionModal;
