import React, { useMemo, useCallback } from "react";
import InputField from "../InputField";

export default function ReceivingReportNumberInput({ value = "", setValue, ...props }) {
  const displayValue = useMemo(() => value.replaceAll(", ", "\n"), [value]);

  const handleSetValue = useCallback(
    newValue => {
      setValue(newValue.replaceAll("\n", ", ").trim());
    },
    [setValue]
  );

  return <InputField {...props} value={displayValue} setValue={handleSetValue} />;
}
