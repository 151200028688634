import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

import SearchInput from "./SearchInput";
import AdvancedSearch from "../AdvancedSearch";
import AdvancedSearchButton from "src/components/AdvancedSearch/AdvancedSearchButton";
import AssetItemSkeleton from "./aseet-list/AssetItemSkeleton";
import useAuth from "../../hooks/useAuth";
import SearchScanner from "./SearchScanner";

const styles = {
  card: {
    overflow: "visible"
  },
  searchContainer: {
    position: "relative"
  },
  advancedSearchButton: {
    left: 4,
    width: "calc(100% - 8px)"
  }
};

const AssetsWrap = ({ height, loading, search, setSearch, children, skeletonCheckbox }) => {
  const [value, setValue] = useState(search);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [showAdvancedSearchButton, setShowAdvancedSearchButton] = useState(false);
  const { user } = useAuth();
  const { settings } = user.company;

  useEffect(() => {
    setValue(search);
  }, [search]);

  const handleSubmit = event => {
    event.preventDefault();
    setSearch(value);
  };

  const handleAdvancedSearchSubmit = value => {
    setValue(value);
    setSearch(value);
    setShowAdvancedSearch(false);
  };

  return (
    <Card sx={styles.card} style={{ height }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={styles.searchContainer}>
            {settings.receiving_wizard ? (
              <SearchScanner value={value} search={search} setSearch={setSearch} />
            ) : (
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <SearchInput
                    value={value}
                    onChange={setValue}
                    setSearch={setSearch}
                    onFocus={() => setShowAdvancedSearchButton(true)}
                    // a small delay before the button is hidden, ensuring that the click event has time to process first
                    onBlur={() => setTimeout(() => setShowAdvancedSearchButton(false), 200)}
                  />
                </FormControl>
              </form>
            )}
            {showAdvancedSearchButton && (
              <AdvancedSearchButton
                sx={styles.advancedSearchButton}
                onClick={() => {
                  setShowAdvancedSearch(true);
                }}
              />
            )}
            {showAdvancedSearch && (
              <AdvancedSearch
                initialValue={value}
                onSearch={handleAdvancedSearchSubmit}
                onCancel={() => setShowAdvancedSearch(false)}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {loading
              ? [...Array(5)].map((_, index) => (
                  <AssetItemSkeleton key={index} checkbox={skeletonCheckbox} />
                ))
              : children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AssetsWrap;
