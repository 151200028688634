import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import moment from "moment/moment";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import { NEW_MESSAGE_SUBSCRIPTION, NOTIFICATIONS_QUERY } from "../../../../constants/graphql";
import useAuth from "../../../../hooks/useAuth";
import useNotification from "../../../../hooks/notification";
import { serverFormat } from "../../../../utils/dates";
import { useBanner } from "../../../../contexts/banner";

const styles = {
  badge: unread => ({
    "& .MuiBadge-badge": {
      height: 12,
      width: 12,
      borderRadius: 10,
      marginTop: "10px",
      marginRight: "10px",
      display: unread ? "block" : "none",
    },
  }),

  popover: {
    "& .MuiPopover-paper": {
      width: 320,
    },
  },
};

const openWindow = link => {
  const openedWindow = window.open(link, "_blank");
  if (!openedWindow) {
    alert("Unable to open. Please allow popups for this website.");
  }
};

export default function Notifications() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [unread, setUnread] = useState(false);
  const [messages, setMessages] = useState([]);
  const { isAuthenticated, user } = useAuth();
  const { success } = useNotification();
  const { banner, closeBanner } = useBanner();
  const { data: { notifications = [] } = {}, refetch } = useQuery(NOTIFICATIONS_QUERY, {
    variables: { limit: 4 },
  });

  const handleOpen = () => {
    setOpen(true);
    setUnread(false);
  };

  const handleClose = () => setOpen(false);

  useSubscription(NEW_MESSAGE_SUBSCRIPTION, {
    variables: { userId: user?.id },
    onSubscriptionData: ({ subscriptionData }) => {
      refetch();
      setMessages([subscriptionData.data.newMessage, ...messages]);
      if (banner.open) {
        switch (banner.type) {
          case "report": {
            closeBanner();
            success({ hide: 10000, message: "Your report has been successfully generated!" });
            openWindow(subscriptionData.data.newMessage.link);
            break;
          }
          case "export-excel": {
            closeBanner();
            success({ hide: 5000, message: "Your Excel report has been successfully generated!" });
            openWindow(subscriptionData.data.newMessage.link);
            break;
          }
          case "download_images": {
            closeBanner();
            success({ hide: 5000, message: "Your image file has been successfully generated!" });
            openWindow(subscriptionData.data.newMessage.link);
            break;
          }
          case "upload-template": {
            closeBanner();
            success({ hide: 5000, message: "Your template has been successfully uploaded!" });
            openWindow(subscriptionData.data.newMessage.link);
            break;
          }
          default: {
            break;
          }
        }
      } else {
        success({ hide: 10000, message: "New message received" });
      }
      setUnread(true);
    },
    skip: !isAuthenticated,
  });

  return (
    <>
      <Tooltip title="Notifications">
        <Badge id="notificationBadge" color="error" variant="dot" sx={styles.badge(unread)}>
          <IconButton id="notificationIcon" color="inherit" ref={ref} onClick={handleOpen}>
            <NotificationsNoneOutlinedIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        id="notificationsList"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={styles.popover}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography fontWeight={500} color="textPrimary">
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography fontWeight={500} fontSize={14} color="textPrimary">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification, index) => (
                <ListItem
                  id={`notificationItem-${index}`}
                  divider
                  key={notification.id}
                  button
                  component="a"
                  href={notification.link}
                  target="_blank"
                >
                  <ListItemText
                    primary={notification.title}
                    primaryTypographyProps={{ variant: "subtitle2", color: "textPrimary" }}
                    secondary={moment(notification.createdAt, serverFormat).format("lll")}
                  />
                </ListItem>
              ))}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button component={RouterLink} size="small" to="/notifications">
                See All
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}
