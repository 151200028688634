import React, { useCallback } from "react";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import { useField } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import Select from "../../../components/form/Select";
import Input from "../../../components/form/Input";

export const initialValue = {
  category: "Crew",
  resource: "Mover",
  quantity: "",
};

const fieldName = "resources";

const list = [
  "Material,Blue Tape Roll - Painters Tape",
  "Material,Cleaning Kits",
  "Material,Computer Bag",
  "Material,Corner Boards 72",
  "Material,Corrugate Wall Protection",
  "Material,Crate Rental - 14 days",
  "Material,Duct Tape",
  "Material,Fuel Surcharge",
  "Material,HARDWARE / EZ ANCHOR",
  "Material,HARDWARE / L BRACKET",
  "Material,Labels - per 100",
  "Material,Materials not in stock",
  "Material,Monitor Covers",
  "Material,O I Cartons Purchased",
  "Material,RAM BOARD 38 X 100in",
  "Material,Speedpack - purchase",
  "Material,Speedpack - usage",
  "Material,Stretch Wrap",
  "Material,Tape Plastic Per roll",
  "Material,Vacuum",
  "Material,Broom w/ Dust Pan",
  "Vehicle,Straight Truck",
  "Crew,Driver",
  "Crew,Installer",
  "Crew,Carpenter",
  "Crew,Mover",
  "Crew,IT Specialist",
  "Crew,Packer",
  "Crew,Project Manager",
  "Crew,Supervisor",
  "Crew,Warehouse Labor",
  "Crew,Inventory Specialist",
  "Crew,Electrician",
  "Crew,Plumber",
  "Crew,Painter",
  "Crew,Flooring Installer",
  "Crew,Audio/Visual Technician",
  "Equipment,Bookcart",
  "Equipment,Dollies",
  "Equipment,Dock Plates",
  "Equipment,EWaste",
  "Equipment,Flat Carts",
  "Equipment,Hardware",
  "Equipment,Installer Tools",
  "Equipment,Ladders",
  "Equipment,Machine Cart",
  "Equipment,Masonite",
  "Equipment,Monitor Covers",
  "Equipment,Pads Cloth",
  "Equipment,Pallet Jack",
  "Equipment,Panel Cart",
  "Equipment,Speed pack",
  "Equipment,Safe Jacks",
  "Equipment,Shred Bins",
  "Equipment,Straps",
  "Equipment,Tub Cart",
  "Material,1.5 CU FT CARTON",
  "Material,3.0 CU FT CARTON",
  "Material,4.5 CU FT CARTON",
  "Material,6.0 CU FT CARTON",
  "Material,Anti-static Strechwrap Roll",
  "Material,Big Red/Kick Back Dolly",
  "Material,BUBBLE WRAP 24 x 250 ANTISTATIC",
  "Material,Bubble Wrap 24 x 250 ANTISTATIC large",
  "Material,Bubble Wrap 24 x 250 large",
  "Material,Bubble Wrap 24 x 250 Roll",
  "Material,BUBBLE WRAP 24 x 750 ANTISTATIC long",
  "Material,BUBBLE WRAP 48 x 250 ANTISTATIC",
  "Material,Bubble Wrap 48 x 250 ANTISTATIC large",
  "Material,Bubble Wrap 48 x 250 large",
  "Material,BUBBLE WRAP 48 x 250 ROLL",
  "Material,BUBBLE WRAP 48 x 750 ANTISTATIC long",
  "Material,Bubble Wrap Anti Static 250ft Roll",
  "Material,Computer  Phone Services",
  "Material,Corner Boards 48",
  "Material,CORRUGATED BOARD 48inch X 96inch",
  "Material,DISH PACKS",
  "Material,Dollies",
  "Material,Flat Cart",
  "Material,Fuel Surcharge 31+ miles",
  "Material,Fuel Surcharge 51+ miles",
  "Material,Fuel Surcharge 76+ miles",
  "Material,FULL MATTRESS CARTON S",
  "Material,HARDWARE / LOCK BOLT S CONCRETE",
  "Material,HARDWARE / TOGGLE BOLT S",
  "Material,Blankets",
  "Material,KING MATTRESS CARTON S",
  "Material,KING SPLIT MATTRESS CARTON S",
  "Material,LAMP CARTON S",
  "Material,Lifting Platform",
  "Material,MICROWAVE CARTON S",
  "Material,MIRROR CARTONS  SMALL",
  "Material,MIRROR CARTONS LARGE",
  "Material,Packing Peanuts 14lb bag",
  "Material,Pallet rental - weekly",
  "Material,PALLET S",
  "Material,Paper Pad 3 ply",
  "Material,Paper Pad 3ply Blue Lined",
  "Material,Paper Pads",
  "Material,RECORD STORAGE CARTON",
  "Material,Slat Pallet",
  "Material,Sofa Box",
  "Material,Storage Charges",
  "Material,Stretch Wrap Antistatic",
  "Material,Wardrobe 24in stand up",
  "Vehicle,Low Profile Bobtail",
  "Vehicle,Pack Van",
  "Vehicle,Passenger van per trip",
  "Vehicle,Van with Lift Gate",
].map(v => v.split(","));

const categoryOptions = sortBy(
  uniqBy(
    list.filter(v => v[0]).map(([value]) => [value, value]),
    v => v[0]
  ),
  v => v[0]
);

const getResource = value =>
  sortBy(
    list
      .filter(v => v[0])
      .filter(v => v[0] === value)
      .map(([, v]) => [v, v]),
    v => v[0]
  );

const ResourcesField = () => {
  const [field, , helpers] = useField({ name: fieldName });
  const fields = field.value || [];

  const handleAddNew = useCallback(() => {
    helpers.setValue(fields.concat(initialValue));
  }, [helpers, fields]);

  const handleRemove = useCallback(
    i => helpers.setValue(fields.filter((v, index) => i !== index)),
    [helpers, fields]
  );

  return (
    <>
      {fields.map((v, index) => (
        <Box key={index}>
          <Box display="flex" alignItems="center">
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Select
                  size="small"
                  type="text"
                  name={`${fieldName}.${index}.category`}
                  label="Category"
                  options={categoryOptions}
                />
              </Grid>
              <Grid item xs={5}>
                <Select
                  key={fields[index].category}
                  size="small"
                  type="text"
                  name={`${fieldName}.${index}.resource`}
                  label="Resource"
                  options={getResource(fields[index].category)}
                />
              </Grid>
              <Grid item xs={2}>
                <Input name={`${fieldName}.${index}.quantity`} label="Quantity" />
              </Grid>
            </Grid>
            {fields.length > 1 && (
              <Box p={1} mr={-1} clone>
                <IconButton
                  aria-label="delete"
                  variant="outlined"
                  onClick={() => handleRemove(index)}
                >
                  <DeleteIcon color="action" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      ))}
      {fields.length > 0 && <Box mb={1} />}
      <Button
        id={`addNew-${fieldName.split(".").join("-")}`}
        variant="outlined"
        onClick={handleAddNew}
      >
        Add {fields.length ? "New" : "Resource"} <AddIcon fontSize="small" />
      </Button>
    </>
  );
};

export default React.memo(ResourcesField);
