import React, {useState} from "react";
import {useFormikContext} from "formik";
import TextField from "@mui/material/TextField";

import TotalReceivingUnitsModal from "./TotalReceivingUnitsModal";
import getTotalReceivingUnitsLabel from "./getTotalReceivingUnitsLabel";

export default function TotalReceivingUnits() {
  const [open, setOpen] = useState(false);
  const { values } = useFormikContext();
  const { errors, submitCount, getFieldMeta, getFieldProps } = useFormikContext();
  const meta = getFieldMeta("totalPieces");
  const errorMessage = meta.error || errors.totalPieces;
  const error = (meta.touched || submitCount > 0) && errorMessage;

  const handleToggle = () => {
    setOpen(v => !v);
  };

  return (
    <>
      <TextField
        fullWidth
        error={!!error}
        helperText={error}
        size="small"
        label="Total Receiving Units"
        value={getTotalReceivingUnitsLabel(values.totalPieces || [])}
        onClick={handleToggle}
      />
      {open && <TotalReceivingUnitsModal onClose={handleToggle} />}
    </>
  );
}
