import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormikContext } from "formik";

import Autocomplete from "src/components/form/Autocomplete";
import Input from "src/components/form/Input";
import useAuth from "src/hooks/useAuth";
import {
  basicAdvancedSearch,
  medicalAdvancedSearch,
} from "src/constants/advancedSearchFieldOptions";

const styles = {
  container: {
    marginBottom: 1.5,
  },
  field: {
    marginBottom: 1.5,
  },
  toggleLabel: {
    marginBottom: 1,
  },
  toggleGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  toggleButton: {
    textTransform: "none",
    color: theme => theme.palette.text.primary,
    borderColor: theme => theme.palette.grey[400],
    "&.Mui-selected": {
      color: theme => theme.palette.common.white,
      backgroundColor: theme => theme.palette.primary.main,
      borderColor: theme => theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme => theme.palette.primary.dark,
        borderColor: theme => theme.palette.primary.dark,
      },
    },
  },
};

const AdvancedSearchCriteria = ({ index, removeCriterion }) => {
  const { setFieldValue, values } = useFormikContext();
  const { user } = useAuth();
  const { settings } = user.company;

  const handleToggleChange = (event, newMatchType) => {
    if (newMatchType !== null) {
      setFieldValue(`criteria[${index}].matchType`, newMatchType);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.field}>
        <Input name={`criteria[${index}].text`} label="Search" />
      </Box>
      <Box sx={styles.field}>
        <Autocomplete
          name={`criteria[${index}].field`}
          options={
            settings.medical_fields
              ? [...basicAdvancedSearch, ...medicalAdvancedSearch].sort((a, b) =>
                  a.label.localeCompare(b.label)
                )
              : basicAdvancedSearch
          }
          label="Search Field"
          placeholder="Select a Field"
        />
      </Box>
      <Typography sx={styles.toggleLabel} fontWeight={600} color="textSecondary" variant="body2">
        Match Type
      </Typography>
      <Box sx={styles.toggleGroup}>
        <ToggleButtonGroup
          size="small"
          value={values.criteria[index].matchType}
          exclusive
          onChange={handleToggleChange}
        >
          <ToggleButton value="~" sx={styles.toggleButton}>
            Partial
          </ToggleButton>
          <ToggleButton value="=" sx={styles.toggleButton}>
            Exact
          </ToggleButton>
        </ToggleButtonGroup>
        {values.criteria.length > 1 && (
          <IconButton size="small" onClick={() => removeCriterion(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

AdvancedSearchCriteria.propTypes = {
  index: PropTypes.number.isRequired,
  removeCriterion: PropTypes.func.isRequired,
};

export default AdvancedSearchCriteria;
